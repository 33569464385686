import { LogoutControls } from 'util/Auth/Logout.jsx';
import React, { useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { compose } from 'recompose';

import { withApollo } from '@apollo/client/react/hoc';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';

// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.jsx';

import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx';
import { withTranslation } from 'react-i18next';
import { GET_SIDEBAR_VIEW } from './graphql.jsx';

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
const SidebarWrapper = (props) => {
  const { className, user, headerLinks, links } = props;
  const sidebarWrapper = useRef(null);

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
      }
    };
  });

  return (
    <div className={className} ref={sidebarWrapper}>
      {user}
      {headerLinks}
      {links}
    </div>
  );
};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: true,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
    };
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const { classes, color, t } = this.props;

    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop['state']] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          ' ' +
          cx({
            [' ' + classes.collapseActive]: this.getCollapseInitialState(prop.views),
          });
        const itemText =
          classes.itemText +
          ' ' +
          cx({
            [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          ' ' +
          cx({
            [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
          });
        const itemIcon = classes.itemIcon;
        const caret = classes.caret;
        const collapseItemMini = classes.collapseItemMini;
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined },
            )}
          >
            <NavLink
              to={'#'}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === 'string' ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>{prop.mini}</span>
              )}
              <ListItemText
                primary={t(prop.name)}
                secondary={
                  <b
                    className={caret + ' ' + (this.state[prop.state] ? classes.caretActive : '')}
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined },
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        ' ' +
        cx({
          [' ' + classes[color]]: this.activeRoute(prop.path),
        });
      const collapseItemMini = classes.collapseItemMini;
      const navLinkClasses =
        classes.itemLink +
        ' ' +
        cx({
          [' ' + classes[color]]: this.activeRoute(prop.path),
        });
      const itemText =
        classes.itemText +
        ' ' +
        cx({
          [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        ' ' +
        cx({
          [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
        });
      const itemIcon = classes.itemIcon;
      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined },
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined },
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === 'string' ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>{prop.mini}</span>
            )}
            <ListItemText
              primary={t(prop.name)}
              disableTypography={true}
              className={cx(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined },
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };
  render() {
    const { classes, logo, image, logoText, routes, bgColor, t, data, loading } = this.props;

    if (loading || !data) return null;
    const { isLoggedIn, loggedInUser } = data;
    const { userName, profilePic } = loggedInUser;
    const homeUrl = `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ''
    }`;
    const itemText =
      classes.itemText +
      ' ' +
      cx({
        [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      });
    const collapseItemText =
      classes.collapseItemText +
      ' ' +
      cx({
        [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
      });
    const userWrapperClass =
      classes.user +
      ' ' +
      cx({
        [classes.whiteAfter]: bgColor === 'white',
      });
    const caret = classes.caret;
    const collapseItemMini = classes.collapseItemMini;
    const photo = classes.photo;
    var user = isLoggedIn ? (
      <div className={userWrapperClass}>
        {profilePic && (
          <div className={photo}>
            <img src={profilePic} className={classes.avatarImg} alt='...' />
          </div>
        )}
        <List className={classes.list}>
          <ListItem className={classes.item + ' ' + classes.userItem}>
            <NavLink
              to={'#'}
              className={classes.itemLink + ' ' + classes.userCollapseButton}
              onClick={() => this.openCollapse('openAvatar')}
            >
              {userName && (
                <ListItemText
                  primary={userName}
                  secondary={
                    <b
                      className={
                        caret +
                        ' ' +
                        classes.userCaret +
                        ' ' +
                        (this.state.openAvatar ? classes.caretActive : '')
                      }
                    />
                  }
                  disableTypography={true}
                  className={itemText + ' ' + classes.userItemText}
                />
              )}
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to='/gallery/profile'
                    className={classes.itemLink + ' ' + classes.userCollapseLinks}
                  >
                    <span className={collapseItemMini}>{t('profile.collapseText')}</span>
                    <ListItemText
                      primary={t('profile.label')}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to='/gallery/myartists'
                    className={classes.itemLink + ' ' + classes.userCollapseLinks}
                  >
                    <span className={collapseItemMini}>{t('myartists.collapseText')}</span>
                    <ListItemText
                      primary={t('myartists.label')}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to='/gallery/myreleases'
                    className={classes.itemLink + ' ' + classes.userCollapseLinks}
                  >
                    <span className={collapseItemMini}>{t('myreleases.collapseText')}</span>
                    <ListItemText
                      primary={t('myreleases.label')}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to='/gallery/mysales'
                    className={classes.itemLink + ' ' + classes.userCollapseLinks}
                  >
                    <span className={collapseItemMini}>{t('mysales.collapseText')}</span>
                    <ListItemText
                      primary={t('mysales.label')}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>

                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to='/gallery/purchased'
                    className={classes.itemLink + ' ' + classes.userCollapseLinks}
                  >
                    <span className={collapseItemMini}>{t('purchased.collapseText')}</span>
                    <ListItemText
                      primary={t('purchased.label')}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to='#'
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.stopFirebase(this.props.data.loggedInUser.gid);
                      this.props.logout();
                      this.props.history.push('/');
                    }}
                    className={classes.itemLink + ' ' + classes.userCollapseLinks}
                  >
                    <span className={collapseItemMini}>{t('logout.collapseText')}</span>
                    <ListItemText
                      primary={t('logout.label')}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    ) : (
      <div className={userWrapperClass}>
        <List className={classes.list}>
          <ListItem className={classes.item + ' ' + classes.userItem}>
            <NavLink
              to={'#'}
              className={classes.itemLink + ' ' + classes.userCollapseButton}
              onClick={() => this.openCollapse('openAvatar')}
            >
              {userName && (
                <ListItemText
                  primary={userName}
                  secondary={
                    <b
                      className={
                        caret +
                        ' ' +
                        classes.userCaret +
                        ' ' +
                        (this.state.openAvatar ? classes.caretActive : '')
                      }
                    />
                  }
                  disableTypography={true}
                  className={itemText + ' ' + classes.userItemText}
                />
              )}
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={classes.list + ' ' + classes.collapseList}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to='/auth/login'
                    className={classes.itemLink + ' ' + classes.userCollapseLinks}
                  >
                    <span className={collapseItemMini}>{t('login.collapseText')}</span>
                    <ListItemText
                      primary={`${t('login.label')} / ${t('register.label')}`}
                      disableTypography={true}
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
    var links = <List className={classes.list}>{this.createLinks(routes)}</List>;

    const logoNormal =
      classes.logoNormal +
      ' ' +
      cx({
        [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive,
      });
    const logoMini = classes.logoMini;
    const logoClasses =
      classes.logo +
      ' ' +
      cx({
        [classes.whiteAfter]: bgColor === 'white',
      });
    var brand = (
      <div className={logoClasses}>
        <a href={homeUrl} className={logoMini}>
          <img src={logo} alt='' className={classes.img} />
        </a>
        <a href={homeUrl} className={logoNormal}>
          {logoText}
        </a>
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      ' ' +
      cx({
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      ' ' +
      cx({
        [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
      });
    return (
      <div ref='mainPanel'>
        <Hidden mdUp implementation='css'>
          <Drawer
            variant='temporary'
            anchor={'right'}
            open={this.props.open}
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<AdminNavbarLinks />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation='css'>
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={'left'}
            variant='permanent'
            open
            classes={{
              paper: drawerPaper + ' ' + classes[bgColor + 'Background'],
            }}
          >
            {brand}
            <SidebarWrapper className={sidebarWrapper} user={user} links={links} />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue',
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  color: PropTypes.oneOf(['white', 'red', 'orange', 'green', 'blue', 'purple', 'rose']),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

const SideBarView = (props) => {
  const { loading, data } = useQuery(GET_SIDEBAR_VIEW);
  if (loading) return null;
  return <Sidebar data={data} {...props} />;
};

export default compose(
  withTranslation(),
  withStyles(sidebarStyle),
  withApollo,
  LogoutControls,
)(SideBarView);
