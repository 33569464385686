import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import withStyles from '@material-ui/core/styles/withStyles';

// import Formsy from 'formsy-react'

// core components
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import Button from 'components/CustomButtons/Button.jsx';
import FormsyDropdown from 'components/FormsyDropdown/FormsyDropdown.jsx';
import CustomInput from 'components/FormsyInput/FormsyInput.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GetCountry from 'country-currency-map-2';
import Formsy from 'formsy-react';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { compose } from 'recompose';
import { countryToFlag } from 'util.js';

import { GET_PAYOUT_VIEW, REQUEST_PAYOUT, COUNTRY_BANKS } from './graphql.jsx';

class Payout extends React.Component {
  state = {
    payoutCountry: '',
    payoutBank: '',
    banks: [],
  };

  onChange = async (vals) => {
    const { client } = this.props;
    let bankAccessor = 'code';
    const {
      data: { countryBanks },
    } = await client.query({
      query: COUNTRY_BANKS,
      variables: { countryCode: vals.payoutCountryCode },
    });
    const banks = countryBanks.map((b) => ({
      name: b.name,
      value: b[bankAccessor],
    }));
    this.setState({ ...vals, banks });
  };

  render() {
    const {
      classes,
      data: { getPublicSupportedCountries },
      payout,
      t,
      i18n,
    } = this.props;

    const { payoutCountry, payoutBank, banks } = this.state;

    const countries = getPublicSupportedCountries.map((c) => ({
      name: (
        <React.Fragment>
          <span>
            {countryToFlag(c.code)} {i18nIsoCountries.getName(c.code, i18n.languages[0])}
          </span>
        </React.Fragment>
      ),
      value: c.code,
    }));
    return (
      <GridContainer>
        <GridContainer>
          <GridContainer justify='center' alignItems='center'>
            <GridItem xs={10}>
              <Formsy
                onValidSubmit={(d) => {
                  const payload = {
                    ...d,
                    payoutCurrency: GetCountry.getCurrencyAbbreviation(
                      GetCountry.getCountryByAbbreviation(d.payoutCountryCode),
                    ),
                  };

                  payout({ variables: payload });
                }}
                onChange={this.onChange}
                ref='payoutForm'
              >
                <FormsyDropdown
                  labelText={t('common.form.payoutCountry.text')}
                  id='payoutCountryCode'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  dropdownList={countries}
                  value={payoutCountry}
                  validations='isExisty'
                  validationError={t('common.form.payoutCountry.validation')}
                  required
                />
                <FormsyDropdown
                  labelText={t('common.form.bank.text')}
                  id='payoutBank'
                  formControlProps={{
                    fullWidth: true,
                  }}
                  dropdownList={banks}
                  value={payoutBank}
                  validations='isExisty'
                  validationError={t('common.form.bank.validation')}
                  required
                />
                <CustomInput
                  id='accountNumber'
                  labelText={t('common.form.accNo.text')}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    placeholder: `${t('common.form.accNo.text')} ...`,
                  }}
                  required
                />
                <CustomInput
                  id='confirmAccountNumber'
                  labelText={t('common.form.confirmAccNo.text')}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    placeholder: `${t('common.form.confirmAccNo.text')} ...`,
                  }}
                  validations='equalsField:accountNumber'
                  validationError={t('common.form.confirmAccNo.validation')}
                  required
                />
                <CustomInput
                  id='password'
                  labelText={t('common.form.password.text')}
                  formControlProps={{
                    fullWidth: true,
                    className: classes.customFormControlClasses,
                  }}
                  inputProps={{
                    type: 'password',

                    placeholder: `${t('common.form.password.text')} ...`,
                  }}
                  validations='minLength:8'
                  validationError='Must be a minimum of 8 characters'
                  required
                />
                <Button color='warning' type='submit'>
                  {t('common.form.requestPayout.text')}
                </Button>
              </Formsy>
            </GridItem>
          </GridContainer>
        </GridContainer>
      </GridContainer>
    );
  }
}

const PayoutView = (props) => {
  const { loading, data, client } = useQuery(GET_PAYOUT_VIEW);
  const [payout] = useMutation(REQUEST_PAYOUT, {
    refetchQueries: ['Notifications', 'UserTransactions'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      NotificationManager.success('You will be notified when it is complete', 'Payout Requested!');
      props.history.push('/');
    },
  });

  if (loading) return null;

  return <Payout {...props} data={data} client={client} payout={payout} />;
};

export default compose(withTranslation(), withStyles(dashboardStyle))(PayoutView);
