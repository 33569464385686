import React from 'react';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { warningColor } from 'assets/jss/material-dashboard-pro-react.jsx';
import customCircularProgressStyle from 'assets/jss/material-dashboard-pro-react/components/customCircularProgressStyle.jsx';

function CustomCircularProgress({ ...props }) {
  const { classes } = props;
  return (
    <Loader
      type='Circles'
      className={classes.root}
      height={50}
      width={50}
      color={warningColor[0]}
    />
  );
}

export default withStyles(customCircularProgressStyle)(CustomCircularProgress);
