import React, { useState } from 'react';
import { Typography, Menu, MenuItem, Button } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { orderBy } from 'lodash';
import * as moment from 'moment';
import { filter } from 'lodash';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import { useStyles as chartStyles } from '../../../assets/jss/material-dashboard-pro-react/views/topChartsDetailsStyle';
import TopFirst from './TopFirst';
import { GET_CHART_PERIODS } from './graphql';
import ArtistChartTable from './ArtistChartTable';
import TrackChartTable from './TrackChartTable';
import AlbumChartTable from './AlbumChartTable';
import PeriodFormater from './PeriodFormater';

const TopChartsDetails = () => {
  const { t } = useTranslation();
  const classes = chartStyles();
  const params = useParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [validChartData, setValidChartData] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const [topFirst, setTopFirst] = useState(null);
  const chartLimit = 10;

  const { chartType, chartPeriod } = params;

  const selectWeek = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseWeekDropdown = (event) => {
    setAnchorEl(null);
    setCurrentDate(validChartData[event.currentTarget.value].name);
  };

  const { loading: chartPeriodLoading } = useQuery(GET_CHART_PERIODS, {
    variables: {
      frequency: chartPeriod,
    },
    onCompleted: (data) => {
      if (chartPeriod === 'weekly') {
        const validData = filter(data.getChartPeriods, (el) =>
          moment(el.name, moment.ISO_8601).isValid(),
        );
        const sortedPeriod = orderBy(validData, ['name'], ['desc']);
        setValidChartData(sortedPeriod);
        setCurrentDate(sortedPeriod[0].name);
      } else if (chartPeriod === 'monthly') {
        const sortedPeriod = orderBy(data.getChartPeriods, ['name'], ['desc']);
        setValidChartData(sortedPeriod);
        setCurrentDate(sortedPeriod[0].name);
      }
    },
  });

  const top = (value) => {
    setTopFirst(value);
  };

  let chartTitle;
  let ChartTable;
  switch (chartType) {
    case 'artist':
      chartTitle = t('chartsdetail.header.artist');
      ChartTable = (
        <ArtistChartTable
          t={t}
          chartPeriod={chartPeriod}
          timePeriod={currentDate}
          chartLimit={chartLimit}
          top={top}
        />
      );
      break;
    case 'albums':
      chartTitle = t('chartsdetail.header.album');
      ChartTable = (
        <AlbumChartTable
          t={t}
          chartPeriod={chartPeriod}
          timePeriod={currentDate}
          chartLimit={chartLimit}
          top={top}
        />
      );
      break;
    case 'tracks':
      chartTitle = t('chartsdetail.header.tracks');
      ChartTable = (
        <TrackChartTable
          t={t}
          chartPeriod={chartPeriod}
          timePeriod={currentDate}
          chartLimit={chartLimit}
          top={top}
        />
      );
      break;
    default:
      break;
  }

  return (
    <GridContainer>
      <GridItem sm={12} md={12}>
        <Typography component='h4' variant='h3' align='center' className={classes.headerTitle}>
          {chartTitle}
        </Typography>
      </GridItem>
      <TopFirst first={topFirst} />
      <GridContainer>
        <GridItem className={classes.weekItem}>
          <Button onClick={selectWeek} endIcon={<ArrowDropDown />}>
            <PeriodFormater value={currentDate} chartPeriod={chartPeriod} />
          </Button>
          {!chartPeriodLoading && (
            <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}>
              {validChartData.map((week, index) => (
                <MenuItem onClick={onCloseWeekDropdown} value={index} key={index}>
                  <PeriodFormater value={week.name} chartPeriod={chartPeriod} />
                </MenuItem>
              ))}
            </Menu>
          )}
        </GridItem>
      </GridContainer>
      {ChartTable}
    </GridContainer>
  );
};

export default TopChartsDetails;
