import { EnhanceWithCartControls } from 'util/Cart/Cart.jsx';
import { EnhanceWithPlayerControls } from 'util/Player/Player.jsx';

import React from 'react';

import { useQuery } from '@apollo/client';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import Album from '@material-ui/icons/Album';
import Lock from '@material-ui/icons/Lock';
import PlayArrow from '@material-ui/icons/PlayArrow';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
// import Info from "@material-ui/icons/Info";
import RemoveShoppingCart from '@material-ui/icons/RemoveShoppingCart';
import Repeat from '@material-ui/icons/Repeat';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import cx from 'classnames';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Share from 'components/Share/Share.jsx';
import ReactCountryFlag from 'react-country-flag';
import Currency from 'react-currency-formatter';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { compose } from 'recompose';

import { PAID_RELEASE_VIEW } from './graphql.jsx';

function PaidRelease({ ...props }) {
  const {
    rel,
    classes,
    data: { shoppingCart, isLoggedIn, localeCurrency },
    t,
    i18n,
    gallery,
  } = props;

  const trackCount = rel.media.reduce((acc, m) => acc + m.trackCount, 0);

  const cartContainsRelease = (cart, rel) => cart.find((r) => r.gid === rel.gid);

  return (
    <GridItem xs={12} sm={12} md={3}>
      <Card product className={cx(classes.cardHover, gallery ? classes.cardSize : '')}>
        <CardHeader image className={classes.cardHeaderHover}>
          <NavLink to={`/gallery/release/${rel.gid}`}>
            <img className={classes.cardImage} src={rel.media[0].artwork} alt='...' />
          </NavLink>
        </CardHeader>
        <CardBody>
          <GridItem className={classes.cardHoverUnder}>
            {isLoggedIn && (
              <Tooltip
                id='tooltip-top'
                title={
                  cartContainsRelease(shoppingCart, rel)
                    ? t('common.controls.removeCart.text')
                    : t('common.controls.addCart.text')
                }
                placement='bottom'
                classes={{ tooltip: classes.tooltip }}
              >
                <Button onClick={() => props.toggleToShoppingCart(rel)} color='warning' justIcon>
                  {cartContainsRelease(shoppingCart, rel) ? (
                    <RemoveShoppingCart className={classes.underChartIcons} />
                  ) : (
                    <AddShoppingCart className={classes.underChartIcons} />
                  )}
                </Button>
              </Tooltip>
            )}
            <Tooltip
              id='tooltip-top'
              title={t('common.controls.play.text')}
              placement='bottom'
              classes={{ tooltip: classes.tooltip }}
            >
              <Button onClick={() => props.addReleaseToNewPlaylist(rel)} color='warning' justIcon>
                <PlayArrow className={classes.underChartIcons} />
              </Button>
            </Tooltip>
            {isLoggedIn && (
              <Tooltip
                id='tooltip-top'
                title={t('common.controls.addToPlaylist.text')}
                placement='bottom'
                classes={{ tooltip: classes.tooltip }}
              >
                <Button color='warning' onClick={() => props.appendReleaseToPlaylist(rel)} justIcon>
                  <PlaylistAdd className={classes.underChartIcons} />
                </Button>
              </Tooltip>
            )}
            {!isLoggedIn && (
              <Tooltip
                id='tooltip-top'
                title={t('common.controls.loginOrBuy.text')}
                placement='bottom'
                classes={{ tooltip: classes.tooltip }}
              >
                <NavLink to={'/auth/login'}>
                  <Button color='warning' justIcon>
                    <Lock className={classes.underChartIcons} />
                  </Button>
                </NavLink>
              </Tooltip>
            )}
            {/* <Tooltip
              id="tooltip-top"
              title={t("common.controls.moreInfo.text")}
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
            >
              <NavLink to={`/gallery/release/${rel.gid}`}>
                <Button color="warning" justIcon>
                  <Info className={classes.underChartIcons} />
                </Button>
              </NavLink>
            </Tooltip> */}
            <Share rel={rel} />
          </GridItem>
          <h4 className={classes.cardProductTitle}>
            <NavLink to={`/gallery/release/${rel.gid}`}>{rel.name}</NavLink>
          </h4>
          <h6 className={classes.cardProductDesciprion}>
            {t('common.track.textWithCount', { count: trackCount })}
          </h6>
          <p className={classes.cardProductDesciprion}>
            <NavLink to={`/gallery/artist/${rel.artists[0].gid}`}>{rel.artists[0].name}</NavLink>
          </p>
          <p className={classes.cardProductDesciprion}>
            <NavLink to={`/gallery/genre/${rel.genre}`}>{rel.genre}</NavLink>
          </p>
        </CardBody>
        <CardFooter product>
          <div className={classes.price}>
            <Currency
              quantity={rel.priceCategory.value}
              currency={localeCurrency.currency}
              locale={i18n.languages[0]}
            />
          </div>
          <div>
            <Repeat color='secondary' className={classes.icon} />
            {rel.playCount}
          </div>
          <div className={`${classes.stats} ${classes.productStats}`}>
            <Album />
            <NavLink to={`/gallery/country/${rel.country.id}`}>
              <ReactCountryFlag code={rel.country.code} svg />
            </NavLink>
          </div>
        </CardFooter>
      </Card>
    </GridItem>
  );
}

const PaidReleaseView = (props) => {
  const { loading, data } = useQuery(PAID_RELEASE_VIEW, {
    errorPolicy: 'all',
  });
  if (loading) return null;
  return <PaidRelease {...props} data={data} />;
};

export default compose(
  withTranslation(),
  EnhanceWithPlayerControls,
  EnhanceWithCartControls,
  withStyles(dashboardStyle),
)(PaidReleaseView);
