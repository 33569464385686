import { EnhancePlayer } from 'util/Player/Player.jsx';
import React from 'react';

import { useQuery } from '@apollo/client';

import ReactJkMusicPlayer from 'components/Player';

import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import cx from 'classnames';

import 'react-jinke-music-player/assets/index.css';

import { RenderNothingOnNoData } from 'hoc/index.jsx';
import footerStyle from 'assets/jss/material-dashboard-pro-react/components/footerStyle';
import { CountUp } from 'countup.js';
import { GET_TRACK_PUBLIC_URL, GET_PLAYLIST_DATA } from './graphql.jsx';

const STREAMED_TRACK_DURATION = 30;

class Player extends React.Component {
  state = {
    playing: false,
  };

  componentDidMount() {
    this.countUp = new CountUp('countup', STREAMED_TRACK_DURATION, {
      duration: STREAMED_TRACK_DURATION,
    });
  }

  render() {
    const { classes, data, loading, client } = this.props;

    if (loading || !data) return null;

    const { playlist, loggedInUser, clearPriorAudioLists } = data;

    const startCounter = (audioInfo) => {
      if (!this.countUp.error) {
        if (!this.state.playing) {
          this.countUp.start(() => {
            this.props.trackStreamed(
              loggedInUser.email ? loggedInUser.email : null,
              audioInfo.gid,
              audioInfo.releaseGid,
              STREAMED_TRACK_DURATION,
            );
          });
          this.setState({ playing: true });
        } else {
          this.countUp.pauseResume();
        }
      } else {
        console.error(this.countUp.error);
      }
    };

    const pauseCounter = () => {
      if (!this.countUp.error) {
        this.countUp.pauseResume();
      } else {
        console.error(this.countUp.error);
      }
    };

    const resetCounter = () => {
      this.countUp.reset();
    };

    const params = {
      //audio lists model
      audioLists: [],

      //default play index of the audio player  [type `number` default `0`]
      defaultPlayIndex: 0,

      //if you want dynamic change current play audio you can change it [type `number` default `0`]
      // playIndex: 0,

      //color of the music player theme    [ type `string: 'light' or 'dark'  ` default 'dark' ]
      theme: 'dark',

      // Specifies movement boundaries. Accepted values:
      // - `parent` restricts movement within the node's offsetParent
      //    (nearest node with position relative or absolute), or
      // - a selector, restricts movement within the targeted node
      // - An object with `left, top, right, and bottom` properties.
      //   These indicate how far in each direction the draggable
      //   can be moved.
      bounds: 'body',

      // Replace a new playlist with the first loaded playlist
      // instead of adding it at the end of it.
      // [type `boolean`, default `false`]
      clearPriorAudioLists: clearPriorAudioLists,

      // Play your new play list right after your new play list is loaded turn false.
      // [type `boolean`, default `false`]
      autoPlayInitLoadPlayList: true,

      //Whether to load audio immediately after the page loads.  [type `Boolean | String`, default `false`]
      //"auto|metadata|none" "true| false"
      preload: false,

      //Whether the player's background displays frosted glass effect  [type `Boolean`, default `false`]
      glassBg: false,

      //The next time you access the player, do you keep the last state  [type `Boolean` default `false`]
      remember: false,

      //The Audio Can be deleted  [type `Boolean`, default `true`]
      remove: true,

      //audio controller initial position    [ type `Object` default '{top:0,left:0}' ]
      defaultPosition: {
        bottom: 0,
        left: 500,
      },

      // play mode text config of the audio player
      playModeText: {
        order: 'In order',
        orderLoop: 'Repeat all',
        singleLoop: 'Repeat Song',
        shufflePlay: 'Shuffle',
      },

      //audio controller open text  [ type `String | ReactNode` default 'open']
      openText: 'Open',

      //audio controller close text  [ type `String | ReactNode` default 'close']
      closeText: 'Close',

      //audio theme switch checkedText  [ type `String | ReactNode` default '-']
      checkedText: 'B',

      //audio theme switch unCheckedText [ type `String | ReactNode` default '-']
      unCheckedText: 'G',

      // audio list panel show text of the playlist has no songs [ type `String` | ReactNode  default 'no music']
      notContentText: 'Empty',

      panelTitle: 'Playlist',

      defaultPlayMode: 'order',

      //audio mode        mini | full          [type `String`  default `mini`]
      mode: 'full',

      /**
       * [ type `Boolean` default 'false' ]
       * The default audioPlay handle function will be played again after each pause, If you only want to trigger it once, you can set 'true'
       */
      once: false,

      //Whether the audio is played after loading is completed. [type `Boolean` default 'true']
      autoPlay: true,

      //Whether you can switch between two modes, full => mini  or mini => full   [type 'Boolean' default 'true']
      toggleMode: true,

      //audio cover is show of the "mini" mode [type `Boolean` default 'true']
      showMiniModeCover: true,

      //audio playing progress is show of the "mini"  mode
      showMiniProcessBar: false,

      //audio controller is can be drag of the "mini" mode     [type `Boolean` default `true`]
      drag: true,

      //drag the audio progress bar [type `Boolean` default `true`]
      seeked: true,

      //audio controller title [type `String | ReactNode`  default <FaHeadphones/>]
      // controllerTitle: <FaHeadphones />,

      //Displays the audio load progress bar.  [type `Boolean` default `true`]
      showProgressLoadBar: true,

      //play button display of the audio player panel   [type `Boolean` default `true`]
      showPlay: true,

      //reload button display of the audio player panel   [type `Boolean` default `true`]
      showReload: true,

      //download button display of the audio player panel   [type `Boolean` default `true`]
      showDownload: false,

      //loop button display of the audio player panel   [type `Boolean` default `true`]
      showPlayMode: true,

      //theme toggle switch  display of the audio player panel   [type `Boolean` default `true`]
      showThemeSwitch: true,

      //lyric display of the audio player panel   [type `Boolean` default `false`]
      showLyric: false,

      //destroy player button display  [type `Boolean` default `false`]
      showDestroy: true,

      //Extensible custom content       [type 'Array' default '[]' ]
      extendsContent: [],

      //default volume of the audio player [type `Number` default `1` range `0-1`]
      defaultVolume: 1,

      //playModeText show time [type `Number(ms)` default `700`]
      playModeShowTime: 600,

      //Whether to try playing the next audio when the current audio playback fails [type `Boolean` default `true`]
      loadAudioErrorPlayNext: true,

      // Auto hide the cover photo if no cover photo is available [type `Boolean` default `false`]
      autoHiddenCover: true,

      // Play and pause audio through blank space [type `Boolean` default `false`]
      spaceBar: true,

      /**
       * @description Customer audio title [type `String | Function` default `${name} - ${singer}`]
       * @example
       * audioTitle: (audioInfo) => 'title'
       */

      // audioTitle: 'xxxx',

      //audio play handle
      onAudioPlay(audioInfo) {
        resetCounter();
        startCounter(audioInfo);
      },

      //audio pause handle
      onAudioPause(audioInfo) {
        pauseCounter(audioInfo);
      },

      //When the user has moved/jumped to a new location in audio
      onAudioSeeked() {
        pauseCounter();
      },

      //The single song is ended handle
      onAudioEnded() {
        // console.log('audio ended', currentPlayId, audioLists, audioInfo)
      },

      //audio load abort
      onAudioAbort(currentPlayId, audioLists, audioInfo) {
        console.log('audio abort', currentPlayId, audioLists, audioInfo);
      },

      //audio reload handle
      onAudioReload() {
        // console.log('audio reload:', audioInfo)
      },

      //audio load failed error handle
      onAudioLoadError() {
        // console.error(
        //   'audio load error',
        //   errMsg,
        //   currentPlayId,
        //   audioLists,
        //   audioInfo
        // )
      },

      onAudioListsChange() {
        // console.log('[currentPlayId] audio lists change:', currentPlayId)
        // console.log('[audioLists] audio lists change:', audioLists)
        // console.log('[audioInfo] audio lists change:', audioInfo)
      },

      onAudioPlayTrackChange() {
        // console.log(
        //   'audio play track change:',
        //   currentPlayId,
        //   audioLists,
        //   audioInfo
        // )
      },

      onPlayModeChange() {
        // console.log('play mode change:', playMode)
      },

      onModeChange() {
        // console.log('mode change:', mode)
      },

      onAudioListsPanelChange() {
        // console.log('audio lists panel visible:', panelVisible)
      },

      onAudioListsDragEnd() {
        // console.log('audio lists drag end:', fromIndex, endIndex)
      },

      // custom music player root node
      getContainer() {
        return document.getElementById('player-container');
      },

      /**
       * @description get origin audio element instance , you can use it do everything
       * @example
       * audio.playbackRate = 1.5  // set play back rate
       * audio.crossOrigin = 'xxx' // config cross origin
       */
      // getAudioInstance(audio) {
      //   console.log('audio instance', audio)
      // },

      onBeforeDestroy() {
        // console.log('currentPlayId: ', currentPlayId)
        // console.log('audioLists: ', audioLists)
        // console.log('audioInfo: ', audioInfo)
      },

      onDestroyed() {
        // console.log('onDestroyed:', currentPlayId, audioLists, audioInfo)
        //remove counter span
      },
    };

    var container = cx({
      [classes.container]: false,
      [classes.containerFluid]: true,
    });

    const mappedList = playlist.map(({ id, gid, name, singer, cover, releaseGid }) => {
      return {
        id,
        gid,
        name,
        singer,
        cover,
        releaseGid,
        musicSrc: async () => {
          const { data } = await client.query({
            query: GET_TRACK_PUBLIC_URL,
            variables: { gid },
            fetchPolicy: 'no-cache',
          });
          return data.trackPublicUrl;
        },
      };
    });

    return (
      <div>
        <span id='countup' />
        <ReactJkMusicPlayer
          className={container}
          miniActive={this.props.miniActive}
          {...{
            ...params,
            audioLists: [...mappedList],
          }}
        />
      </div>
    );
  }
}

const PlayerResults = compose(
  withStyles(footerStyle),
  RenderNothingOnNoData(
    (props) => props.data && props.data.playlist && props.data.playlist.length === 0,
  ),
  EnhancePlayer,
)(Player);

const PlayerView = (props) => {
  const { loading, data } = useQuery(GET_PLAYLIST_DATA);
  if (loading) return null;
  return <PlayerResults data={data} {...props} />;
};

export default PlayerView;
