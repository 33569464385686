import { GENRE_LIST } from 'util/GenreList.jsx';
import React from 'react';

import { useQuery, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput2 from 'components/CustomInput/CustomInput.jsx';
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress.jsx';
import GlobalErrorFallback from 'components/Error/GlobalErrorComponent';
import FormsyDateInput from 'components/FormsyDateInput/FormsyDateInput.jsx';
import FormsyDropdown from 'components/FormsyDropdown/FormsyDropdown.jsx';
import CustomInput from 'components/FormsyInput/FormsyInput.jsx';
import FormsyFileUpload from 'components/FormsyUpload/FormsyFileUpload.jsx';
import FormsyPictureUpload from 'components/FormsyUpload/FormsyPictureUpload.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Formsy from 'formsy-react';
import jsmediatags from 'jsmediatags';
import _ from 'lodash';
import Currency from 'react-currency-formatter';
import { NotificationManager } from 'react-notifications';
import { compose } from 'recompose';

// core components

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone, { formatBytes, formatDuration } from 'react-dropzone-uploader';

import { withTranslation } from 'react-i18next';
import { UPDATE_RELEASE, GET_RELEASE_BY_GID } from './graphql.jsx';

class EditRelease extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploadSize: 0,
      progressbarColor: 'primary',
    };
  }

  parseTags = (t) => {
    const { tags } = t;
    const { title, artist, album, track, picture } = tags;
    return { title, artist, album, track, picture };
  };

  handleChangeStatus = (fileObj, status) => {
    if (status === 'done') {
      new jsmediatags.Reader(fileObj.file)
        .setTagsToRead(['title', 'artist', 'album', 'track', 'picture'])
        .read({
          onSuccess: (tags) => {
            let tracks = [...this.state.files, { fileObj, tags: this.parseTags(tags) }].sort(
              (a, b) => a.tags.track > b.tags.track,
            );
            let uploadSize = this.state.uploadSize + fileObj.meta.size;
            this.setState({
              files: tracks,
              uploadSize,
              progressbarColor: uploadSize > this.props.uploadLimitSize ? 'danger' : 'primary',
            });
          },
          onError: (error) => {
            Sentry.withScope((scope) => {
              scope.setExtra('error', error);
              scope.setExtra('name', fileObj.meta.name);
              Sentry.captureException(new Error('Could not read tags'));
            });

            this.setState({
              files: [...this.state.files, { fileObj, tags: {} }],
            });
          },
        });
    } else if (status === 'removed') {
      let uploadSize = this.state.uploadSize - fileObj.meta.size;
      this.setState({
        files: this.state.files.filter(
          (f) => f.fileObj.meta.uploadedDate !== fileObj.meta.uploadedDate,
        ),
        uploadSize,
        progressbarColor: uploadSize > this.props.uploadLimitSize ? 'danger' : 'primary',
      });
    }
  };

  render() {
    const {
      data: { releasePrices, releaseByGid, myArtists, localeCurrency, loggedInUser },
      t,
      i18n,
    } = this.props;

    const { gid, artwork, name, genre, artists, priceCategory, day, month, year, media } =
      releaseByGid;
    const trackCount = media.flatMap((m) => m.tracks).length;
    return (
      <GridContainer justifyContent='center'>
        <GridItem xs={6}>
          <span>{formatBytes(loggedInUser.uploadLimitUsed + this.state.uploadSize)}</span>
        </GridItem>
        <GridItem xs={6}>
          <span>{formatBytes(loggedInUser.uploadLimitSize)}</span>
        </GridItem>
        <GridItem xs={12}>
          <CustomLinearProgress
            color={this.state.progressbarColor}
            variant='buffer'
            value={(loggedInUser.uploadLimitUsed * 100) / loggedInUser.uploadLimitSize}
            valueBuffer={
              ((loggedInUser.uploadLimitUsed + this.state.uploadSize) * 100) /
              loggedInUser.uploadLimitSize
            }
          />
        </GridItem>
        <GridItem xs={12}>
          <GridItem xs={12}>
            <Dropzone
              autoUpload={false}
              getUploadParams={null}
              onChangeStatus={this.handleChangeStatus}
              inputContent={t('common.input.file.add')}
              inputWithFilesContent={t('common.input.file.more')}
              accept='audio/*,video/*'
            />
          </GridItem>
          <GlobalErrorFallback>
            <GridItem xs={12}>
              <Formsy
                onValidSubmit={(d) => {
                  const { date } = d;
                  delete d.date;
                  const day = date.getDate();
                  const month = date.getMonth();
                  const year = date.getFullYear();
                  const tracks = d.tracks.map((tr) => ({
                    ...tr,
                    track: parseInt(tr.track),
                  }));
                  const payload = {
                    ...d,
                    day,
                    month,
                    year,
                    gid,
                    tracks,
                    albumArt: d.albumArt && d.albumArt.file ? d.albumArt.file : null,
                  };
                  this.props.updateRelease({ variables: payload });
                }}
                ref='uploadForm'
              >
                <GridContainer>
                  <GridContainer>
                    <GridItem xs={12} md={2}>
                      <GridContainer>
                        <GridItem xs={12}>
                          <FormsyPictureUpload id='albumArt' value={{ imagePreviewUrl: artwork }} />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} md={10}>
                      <GridContainer>
                        <GridItem xs={12} md={3}>
                          <CustomInput
                            labelText={t('common.form.album.text')}
                            id='album'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={name}
                            required
                          />
                        </GridItem>
                        <GridItem xs={12} md={2}>
                          <FormsyDropdown
                            labelText={t('common.form.genre.text')}
                            id='genre'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            dropdownList={_.sortBy(GENRE_LIST, (g) => [g.name])}
                            value={genre}
                            required
                          />
                        </GridItem>
                        <GridItem xs={12} md={2}>
                          <FormsyDateInput
                            labelText={t('common.form.releaseDate.text')}
                            id='date'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            value={new Date(year, month, day)}
                            required
                          />
                        </GridItem>
                        <GridItem xs={12} md={2}>
                          <FormsyDropdown
                            labelText={t('common.form.price.text')}
                            id='price'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            dropdownList={_.sortBy(
                              _.map(releasePrices, (p) => ({
                                name: (
                                  <Currency
                                    quantity={p.value}
                                    currency={localeCurrency.currency}
                                    locale={i18n.languages[0]}
                                  />
                                ),
                                value: p.tier,
                              })),
                              [(p) => p.value],
                            )}
                            value={priceCategory.tier}
                            required
                          />
                        </GridItem>
                        <GridItem xs={12} md={2}>
                          <FormsyDropdown
                            labelText={t('common.form.albumArtist.text')}
                            id='albumArtist'
                            formControlProps={{
                              fullWidth: true,
                            }}
                            dropdownList={myArtists.reduce(
                              (acc, i) => [...acc, { name: i.name, value: i.gid }],
                              [
                                {
                                  navButton: true,
                                  text: 'Add New Artist',
                                  to: '/gallery/myartists/new',
                                },
                                { divider: true },
                              ],
                            )}
                            value={artists[0].gid}
                            required
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12}>
                      {media.flatMap((m) =>
                        m.tracks.map((tr, i) => (
                          <GridContainer key={i}>
                            <GridItem xs={12} md={2}>
                              <CustomInput
                                labelText={t('common.form.trackNumber.text')}
                                id={`tracks[${i}].track`}
                                formControlProps={{
                                  fullWidth: false,
                                }}
                                value={tr.position}
                                required
                              />
                            </GridItem>
                            <GridItem xs={12} md={3}>
                              <CustomInput
                                labelText={t('common.form.title.text')}
                                id={`tracks[${i}].title`}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                value={tr.name}
                                required
                              />
                            </GridItem>
                            <GridItem xs={12} md={3}>
                              <CustomInput
                                labelText={t('common.form.artist.text')}
                                id={`tracks[${i}].artist`}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                value={tr.artists}
                                required
                              />
                            </GridItem>
                            <GridItem xs={5} md={1}>
                              <CustomInput2
                                labelText={t('common.form.duration.text')}
                                id={`tracks[${i}].displayduration`}
                                formControlProps={{
                                  fullWidth: false,
                                }}
                                inputProps={{
                                  disabled: true,
                                  defaultValue: formatDuration(tr.duration),
                                }}
                              />
                            </GridItem>
                            <GridItem xs={5} md={1}>
                              <CustomInput2
                                labelText={t('common.form.size.text')}
                                id={`tracks[${i}].displaysize`}
                                formControlProps={{
                                  fullWidth: false,
                                }}
                                inputProps={{
                                  disabled: true,
                                  defaultValue: formatBytes(tr.size),
                                }}
                              />
                            </GridItem>
                            <GridItem md={2}>
                              <CustomInput
                                labelText={t('common.form.trackStart.text')}
                                id={`tracks[${i}].sampleOffset`}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                                validations={{
                                  matchRegexp: /^\d{2}:\d{2}$/,
                                }}
                                validationError={t('common.form.trackStart.validation')}
                                value={tr.sampleOffset}
                              />
                            </GridItem>
                            <GridItem md={1}>
                              <CustomInput
                                id={`tracks[${i}].duration`}
                                formControlProps={{
                                  fullWidth: false,
                                }}
                                inputProps={{
                                  disabled: true,
                                  type: 'hidden',
                                }}
                                value={tr.duration}
                                required
                              />
                            </GridItem>
                            <GridItem md={1}>
                              <CustomInput
                                id={`tracks[${i}].gid`}
                                formControlProps={{
                                  fullWidth: false,
                                }}
                                inputProps={{
                                  disabled: true,
                                  type: 'hidden',
                                }}
                                value={tr.gid}
                                required
                              />
                            </GridItem>
                          </GridContainer>
                        )),
                      )}
                      {this.state.files.map((f, i) => (
                        <GridContainer key={i}>
                          <GridItem xs={12} md={2}>
                            <CustomInput
                              labelText={t('common.form.trackNumber.text')}
                              id={`tracks[${i + trackCount}].track`}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              value={f.tags.track}
                              required
                            />
                          </GridItem>
                          <GridItem xs={12} md={3}>
                            <CustomInput
                              labelText={t('common.form.title.text')}
                              id={`tracks[${i + trackCount}].title`}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={f.tags.title}
                              required
                            />
                          </GridItem>
                          <GridItem xs={12} md={3}>
                            <CustomInput
                              labelText={t('common.form.artist.text')}
                              id={`tracks[${i + trackCount}].artist`}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={f.tags.artist}
                              required
                            />
                          </GridItem>
                          <GridItem xs={5} md={1}>
                            <CustomInput2
                              labelText={t('common.form.duration.text')}
                              id={`tracks[${i + trackCount}].displayduration`}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                disabled: true,
                                defaultValue: formatDuration(f.fileObj.meta.duration),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={5} md={1}>
                            <CustomInput2
                              labelText={t('common.form.size.text')}
                              id={`tracks[${i + trackCount}].displaysize`}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                disabled: true,
                                defaultValue: formatBytes(f.fileObj.meta.size),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={5} md={2}>
                            <CustomInput
                              labelText={t('common.form.trackStart.text')}
                              id={`tracks[${i + trackCount}].sampleOffset`}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              validations={{
                                matchRegexp: /^\d{2}:\d{2}$/,
                              }}
                              validationError={t('common.form.trackStart.validation')}
                            />
                          </GridItem>
                          <GridItem md={1}>
                            <CustomInput
                              id={`tracks[${i + trackCount}].duration`}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                disabled: true,
                                type: 'hidden',
                              }}
                              value={f.fileObj.meta.duration}
                              required
                            />
                          </GridItem>
                          <GridItem md={1}>
                            <FormsyFileUpload
                              labelText='file'
                              id={`tracks[${i + trackCount}].file`}
                              value={f.fileObj.file}
                              required
                            />
                          </GridItem>
                        </GridContainer>
                      ))}
                    </GridItem>
                  </GridContainer>
                </GridContainer>
                <div>
                  <Button color='info' onClick={() => this.refs.uploadForm.reset()}>
                    {t('common.form.reset.text')}
                  </Button>
                  <Button type='submit' color='warning'>
                    {t('common.form.saveChanges.text')}
                  </Button>
                </div>
              </Formsy>
            </GridItem>
          </GlobalErrorFallback>
        </GridItem>
      </GridContainer>
    );
  }
}

const EditReleaseView = (props) => {
  const { loading, data } = useQuery(GET_RELEASE_BY_GID, {
    variables: { gid: props.match.params.gid },
  });
  const [updateRelease] = useMutation(UPDATE_RELEASE, {
    refetchQueries: ['GalleryReleases', 'UploadView', 'GetRelease'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      NotificationManager.success('You will be notified when it is complete!', 'Release Uploading');
      props.history.push('/gallery/myreleases');
    },
  });

  if (loading) return null;

  return <EditRelease {...props} data={data} updateRelease={updateRelease} />;
};

export default compose(withTranslation())(EditReleaseView);
