import { gql } from '@apollo/client';

const MOST_PLAYED_TRACKS = gql`
  query MostPlayedTracks($frequency: ChartPeriodFrequency!, $limit: Int!) {
    mostPlayedTracks(frequency: $frequency, limit: $limit) {
      edges {
        node {
          name
          artwork(height: 500, width: 500)
          id
        }
      }
    }
  }
`;
const MOST_PLAYED_ARTISTS = gql`
  query MostPlayedArtists($frequency: ChartPeriodFrequency!, $limit: Int!) {
    mostPlayedArtists(frequency: $frequency, limit: $limit) {
      edges {
        node {
          name
          artwork(height: 500, width: 500)
          id
        }
      }
    }
  }
`;

const MOST_PLAYED_RELEASES = gql`
  query MostPlayedReleases($frequency: ChartPeriodFrequency!, $limit: Int!) {
    mostPlayedReleases(frequency: $frequency, limit: $limit) {
      edges {
        node {
          artwork(height: 500, width: 500)
          name
          id
        }
      }
    }
  }
`;

export { MOST_PLAYED_TRACKS, MOST_PLAYED_ARTISTS, MOST_PLAYED_RELEASES };
