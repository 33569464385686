import gql from 'graphql-tag';

const PAID_RELEASE_VIEW = gql`
  query PaidReleaseView {
    shoppingCart {
      id
      gid
      name
      artists {
        id
        name
      }
      artwork(height: 80, width: 80)
      priceCategory {
        id
        tier
        value
      }
    }
    localeCurrency {
      currency
    }
    isLoggedIn
  }
`;

const FREE_RELEASE_VIEW = gql`
  {
    localeCurrency {
      currency
    }
  }
`;

export { PAID_RELEASE_VIEW, FREE_RELEASE_VIEW };
