import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx';
import cx from 'classnames';
import { withFormsy } from 'formsy-react';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { withTranslation } from 'react-i18next';
import { compose, withProps } from 'recompose';
import { countryToFlag } from 'util.js';

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    inputProps,
    inputRootCustomClasses,
    success,
    value: valueProp,
    i18n,
    t,
    white,
  } = props;

  const error = props.isFormSubmitted && !props.isValid;

  var labelClasses = cx({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  });

  var formControlClasses = classes.selectFormControl;
  if (formControlProps !== undefined) {
    formControlClasses += ' ' + formControlProps.className;
  }

  if (inputProps !== undefined) {
    formControlClasses =
      formControlClasses +
      ' ' +
      cx({
        [classes.inputWithAdornment]:
          (inputProps.startAdornment !== undefined || inputProps.endAdornment !== undefined) &&
          labelText === undefined,
      });
  }
  if (inputProps !== undefined) {
    labelClasses =
      labelClasses +
      ' ' +
      cx({
        [classes.labelWithAdornment]: inputProps.endAdornment !== undefined,
      });
  }

  var helpTextClasses = cx({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });

  const inputClasses = cx({
    [classes.input]: true,
    // [classes.whiteInput]: white,
  });

  const marginTop = cx({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });

  const underlineClasses = cx({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });

  const { languages } = i18n;

  const countries = i18nIsoCountries.getNames(languages[0]);

  const countriesList = Object.entries(countries).reduce(
    (acc, curr) => {
      return [
        ...acc,
        {
          code: curr[0],
          label: i18nIsoCountries.getName(curr[0], languages[0]),
        },
      ];
    },
    [{ code: '', label: '' }],
  );

  const value = props.value !== 'undefined' ? props.value : valueProp;
  const errorMessage = props.errorMessage;

  return (
    <Autocomplete
      id={id}
      options={countriesList}
      classes={{
        option: classes.selectMenuItem,
        root: classes.selectMenuItem,
        focused: classes.selectMenuItemSelected,
      }}
      {...formControlProps}
      value={value}
      autoHighlight
      autoComplete
      noOptionsText={t('common.input.autocomplete.noOptions')}
      onChange={(e, v) => props.setValue(v)}
      getOptionSelected={(o, v) => o.code === v.code}
      getOptionLabel={(option) => option.label}
      renderOption={(option) => {
        return (
          <React.Fragment>
            <span>
              {countryToFlag(option.code)} {i18nIsoCountries.getName(option.code, languages[0])}
            </span>
          </React.Fragment>
        );
      }}
      renderInput={(params) => {
        const p = {
          ...params,
          InputProps: {
            ...params.InputProps,
            ...inputProps,
            classes: {
              input: inputClasses,
              root: marginTop,
              disabled: classes.disabled,
              underline: underlineClasses,
            },
          },
        };
        return (
          <TextField
            {...p}
            label={labelText}
            InputLabelProps={{ className: labelClasses }}
            variant='standard'
            error={error}
            classes={{
              root: formControlClasses,
            }}
            helperText={errorMessage}
            FormHelperTextProps={{ className: helpTextClasses }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        );
      }}
    />
  );
}

const enhance = compose(
  withTranslation(),
  withStyles(customInputStyle),
  withProps((props) => ({ ...props, name: props.id })),
  withFormsy,
);

export default enhance(CustomInput);
