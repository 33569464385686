import React from 'react';
import { Typography, Avatar } from '@material-ui/core';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import ekoh_banner from '../../../assets/img/ekoh-banner.png';
import cup from '../../../assets/img/cup-2.svg';
import { useStyles as topFirstStyles } from '../../../assets/jss/material-dashboard-pro-react/views/topFirstStyle';

const TopFirst = (props) => {
  const { first } = props;
  const classes = topFirstStyles();

  if (!first) return null;

  return (
    <GridContainer className={classes.top}>
      <GridItem md={6} sm={12} className={classes.topItem1}>
        <GridContainer>
          <GridItem md={2} sm={12} className={classes.topItemSm}>
            <img src={cup} alt='cup' className={classes.cupIcon} />
          </GridItem>
          <GridItem md={6} sm={12} className={classes.topItemSmText}>
            <Typography className={classes.winnerStatsText} variant='h6'>
              {first.name}
            </Typography>
          </GridItem>
          <GridItem md={4} sm={12} className={classes.topItemSm}>
            <Avatar alt='ablum image' src={first.artwork} className={classes.largeAvatar} />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem md={6} sm={12} className={classes.topItem2}>
        <img src={ekoh_banner} alt='ekoh-banner' className={classes.image} />
      </GridItem>
    </GridContainer>
  );
};

export default TopFirst;
