import { makeStyles } from '@material-ui/core/styles';

import { primaryColor, tableColor } from '../../material-dashboard-pro-react';

const salesTableStyle = makeStyles({
  tableHeader: {
    fontWeight: 'bold',
    padding: '15px',
    borderRadius: '15px 15px 0 0',
    background: primaryColor[8],
    borderBottom: '1px solid #e0e0e0',
  },

  root: {
    width: '100%',
  },
  container: {
    maxHeight: 340,
  },
  container2: {
    maxHeight: 340,
  },
  minWidth80: {
    minWidth: '80px',
  },
  minWidth180: {
    minWidth: '180px',
  },
  minWidth100: {
    minWidth: '100px',
  },
  tableCellHeader: {
    minWidth: '80px',
    justifyContent: 'space-between',
  },
  salesIcon: {
    color: 'rgba(255, 152, 0, 0.8)',
  },
  tableRow: {
    borderBottom: '1px solid #e0e0e0',

    padding: '15px',
    justifyContent: 'space-between',
  },
  displayF: {
    display: 'flex',
  },
  idClass: { color: tableColor[2], cursor: 'pointer' },
  subRow: {
    gap: '10px',
  },
  justAligCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  unpaid: {
    color: tableColor[3],
    fontWeight: 'bolder',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  success: { color: tableColor[0], fontWeight: 'bolder' },
  pending: { color: tableColor[1], fontWeight: 'bolder' },
});

export default salesTableStyle;
