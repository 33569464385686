import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

class CollectFunds extends Component {
  render() {
    return (
      <Card>
        <CardBody>
          <p>
            NB. Go through the terms and conditions to see the minimum withdrawal amounts, payout
            options and how long it will take to receive your funds in your region
          </p>
          <List>
            <ListItem>- Click on My Profile under menu</ListItem>
            <ListItem>
              - Click on Payout Page (NB. this button will only appear once your sales have exceeded
              the minimum withdrawal amount for your region)
            </ListItem>
            <ListItem>- Select payout country and enter payment details</ListItem>
            <ListItem>- Confirm your payment details and click Request Payout</ListItem>
          </List>
        </CardBody>
      </Card>
    );
  }
}

export default CollectFunds;
