import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import Context from 'views/Gallery/Context.js';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import DownloadNavbar from 'components/Navbars/DownloadNavbar.jsx';

import ShoppingCart from 'views/ShoppingCart/ShoppingCart.jsx';
import Artist from 'views/Artist/Artist.jsx';
import Country from 'views/Country/Country.jsx';
import Release from 'views/Release/ViewRelease.jsx';
import Payout from 'views/Payout/Payout.jsx';
import UserProfile from 'views/CustomUserProfile/CustomUserProfile.jsx';
import PurchasedReleases from 'views/Release/Purchased.jsx';
import MyReleases from 'views/Release/MyReleases.jsx';
import MySales from 'views/Payout/MySales.jsx';
import MyArtists from 'views/Artist/MyArtists/MyArtists.jsx';
import Genre from 'views/Genre/Genre.jsx';
import { mainPanelContext } from 'views/Gallery/Context.js';
// import { HandlerContext } from "views/Gallery/Context";
import routes from 'routes/gallery.jsx';

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx';

import image from 'assets/img/sidebar-2.jpg';
// import logo from "assets/img/ekoh-online.svg";
import { flowRight as compose } from 'lodash';
import { withTranslation } from 'react-i18next';
import TopChartsDetails from '../views/Gallery/TopChartsDetails';

import TopCharts from '../views/Gallery/charts';

var ps;

const galleryNavigateRoutes = [
  {
    path: '/profile',
    name: 'profile.label',
    component: UserProfile,
    layout: '/gallery',
  },
  {
    path: '/myartists',
    name: 'myartists.label',
    component: MyArtists,
    layout: '/gallery',
  },

  {
    path: '/myreleases',
    name: 'myreleases.label',
    component: MyReleases,
    layout: '/gallery',
  },
  {
    path: '/charts/:chartPeriod/:chartType',
    name: 'chartsdetail.label',
    component: TopChartsDetails,
    layout: '/gallery',
  },
  {
    path: '/mysales',
    name: 'mysales.label',
    component: MySales,
    layout: '/gallery',
  },
  {
    path: '/purchased',
    name: 'purchased.label',
    component: PurchasedReleases,
    layout: '/gallery',
  },
  { path: '/payout', name: 'Payout', component: Payout, layout: '/gallery' },
  {
    path: '/shoppingCart',
    // t("shoppingcart.label")
    name: 'shoppingcart.label',
    component: ShoppingCart,
    layout: '/gallery',
  },
  {
    path: '/artist/:gid',
    // t("artist.label")
    name: 'artist.label',
    component: Artist,
    parameterized: true,
    layout: '/gallery',
  },
  {
    path: '/country/:id',
    // t("country.label")
    name: 'country.label',
    component: Country,
    parameterized: true,
    layout: '/gallery',
  },
  {
    path: '/release/:gid',
    // t("release.label")
    name: 'release.label',
    component: Release,
    parameterized: true,
    layout: '/gallery',
  },
  {
    path: '/genre/:id',
    // t("genre.label")
    name: 'genre.label',
    component: Genre,
    parameterized: true,
    layout: '/gallery',
  },
  {
    path: '/charts/',
    // t("artists.label")
    name: 'Charts',
    component: TopCharts,
    layout: '/gallery',
  },
  ...routes,
];

class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: image,
      color: 'orange',
      bgColor: 'black',
      hasImage: true,
      fixedClasses: 'dropdown',
    };
    this.resizeFunction = this.resizeFunction.bind(this);
    this.mainPanel = React.createRef(null);
  }
  static contextType = mainPanelContext;

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    window.addEventListener('resize', this.resizeFunction);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.mainPanel.current.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleImageClick = (image) => {
    this.setState({ image: image });
  };
  handleColorClick = (color) => {
    this.setState({ color: color });
  };
  handleBgColorClick = (bgColor) => {
    this.setState({ bgColor: bgColor });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === 'dropdown') {
      this.setState({ fixedClasses: 'dropdown show' });
    } else {
      this.setState({ fixedClasses: 'dropdown' });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== '/gallery/full-screen-maps';
  }
  getActiveRoute = (routes) => {
    let activeRoute = 'Ekoh';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/gallery') {
        if (prop.parameterized) {
          return (
            <Route
              path={prop.layout + prop.path}
              key={key}
              render={(p) => <prop.component {...p} />}
            />
          );
        }

        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    const { classes, t, ...rest } = this.props;
    const mainPanel =
      classes.mainPanel +
      ' ' +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
      });

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          {...rest}
        />
        <DownloadNavbar {...rest} />
        <div className={mainPanel} ref={this.mainPanel}>
          <Context mainPanel={this.mainPanel}>
            <AdminNavbar
              sidebarMinimize={this.sidebarMinimize.bind(this)}
              miniActive={this.state.miniActive}
              brandText={t(this.getActiveRoute(galleryNavigateRoutes))}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />

            {this.getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>
                  <Switch>{this.getRoutes(galleryNavigateRoutes)}</Switch>
                </div>
              </div>
            ) : (
              <div className={classes.map}>
                <Switch>{this.getRoutes(galleryNavigateRoutes)}</Switch>
              </div>
            )}

            {this.getRoute() ? <Footer miniActive={this.state.miniActive} fluid /> : null}
          </Context>
        </div>
      </div>
    );
  }
}

Gallery.propTypes = {
  classes: PropTypes.object.isRequired,
};

const GalleryResults = compose(withTranslation(), withStyles(appStyle))(Gallery);

const GalleryView = (props) => {
  return <GalleryResults {...props} />;
};

export default GalleryView;
