import { createStyles } from '@material-ui/core/styles';

import { primaryColor, whiteColor } from '../../../../assets/jss/material-dashboard-pro-react';

const styles = createStyles({
  root: {
    color: primaryColor[0],
    fontSize: '1rem',
    width: '4rem',
    height: '1.7rem',
    border: 'unset',
    borderRadius: '15px',
    '@media (max-width: 453px)': {
      fontSize: '.8rem',
      width: '2rem',
    },

    '&$selected': {
      background: primaryColor[0],
      marginRight: '15px',
      color: whiteColor,
      marginLeft: '10px',
      borderRadius: '15px',
    },
    '&:hover,&:focus': {
      color: whiteColor,
      backgroundColor: primaryColor[0],
      padding: '1px',
      borderRadius: '15px',
    },
  },
  selected: {},
});

export default styles;
