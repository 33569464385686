import gql from 'graphql-tag';

const GET_SIDEBAR_VIEW = gql`
  query SIDEBAR_VIEW {
    loggedInUser {
      id
      userName
      gid
      profilePic(height: 80, width: 80)
    }
    isLoggedIn
  }
`;

export { GET_SIDEBAR_VIEW };
