import { EnhanceWithPlayerControls } from 'util/Player/Player.jsx';

import React, { useState, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import {
  searchBarStyle,
  optionStyle,
} from 'assets/jss/material-dashboard-pro-react/components/SearchBarStyle.jsx';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';

import { overidesStyles } from '../../assets/jss/material-dashboard-pro-react/components/overides';
import { SEARCH } from './graphql';
import PlayIcon from './playIcon';

const SearchBar = ({ addTrackToNewPlaylist, t }) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState('');
  const [key, setId] = useState(null);
  const [hover, setHover] = useState(false);
  const [hoverDiv, setHoverDiv] = useState(false);
  const [absolute, setAbsolute] = useState(false);
  const classes = searchBarStyle();
  const opClasses = optionStyle();
  const overide = overidesStyles();

  const history = useHistory();

  const [performSearch, { data, loading, called }] = useLazyQuery(SEARCH, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (called && loading) {
      console.log('search loading');
    }

    if (data) {
      const { artists, releases, tracks } = data.search;
      const searchResult = [...artists, ...releases, ...tracks];
      setOptions(searchResult);
    }

    if (value.length < 3) {
      setOptions([]);
    }
  }, [called, data, loading, value]);

  const handleChange = (val) => {
    if (val.length > 2) {
      performSearch({ variables: { query: val } });
    } else {
      setOptions([]);
    }
    setValue(val);
  };

  const handleSelectedOption = (val) => {
    setValue(val.name);
    const newVal = {};
    switch (val.searchType) {
      case 'artist':
        history.push(`/gallery/artist/${val.gid}`);
        setOptions([]);
        break;

      case 'release':
        history.push(`/gallery/release/${val.gid}`);
        setOptions([]);
        break;
      case 'track':
        delete Object.assign(newVal, val, { artists: val['artist'] })['artist'];
        addTrackToNewPlaylist(newVal, newVal.releaseGid);
        setOptions([]);
        break;

      default:
        break;
    }
  };

  const renderOptions = (opt) => {
    switch (opt.searchType) {
      case 'artist':
        return (
          <div className={opClasses.container}>
            <div className={opClasses.wrapper}>
              <img
                src={opt.artwork}
                alt={`${opt.name} - img`}
                height={70}
                width={70}
                className={opClasses.artistImage}
              />
              <div className={opClasses.info}>
                <p>{opt.name}</p>
                <p>{opt.artist}</p>
              </div>
            </div>
            <div className={opClasses.arrow}>
              <p>&#8599;</p>
            </div>
          </div>
        );
      // &#62;
      case 'release':
        return (
          <div className={opClasses.container}>
            <div className={opClasses.wrapper}>
              <img src={opt.artwork} alt={`${opt.name} - img`} height={70} width={70} />
              <div className={opClasses.info}>
                <p>{opt.name}</p>
                <p>{opt.artist}</p>
              </div>
            </div>
            <div className={opClasses.arrow}>
              <p>&#8599;</p>
            </div>
          </div>
        );

      case 'track':
        return (
          <div
            className={`${opClasses.container} ${
              hover && opt.gid === key ? overide.hoverShow : ''
            }`}
            onMouseEnter={() => {
              setHover(true);
              setId(opt.gid);
            }}
            onMouseLeave={() => {
              setHover(false);
              setId(opt.gid);
            }}
          >
            <div className={opClasses.wrapper}>
              <div style={{ position: 'relative' }}>
                <img src={opt.artwork} alt={`${opt.name} - img`} height={70} width={70} />
                <PlayIcon />
              </div>
              <div className={opClasses.info}>
                <p>{opt.name}</p>
                <p>{opt.artist}</p>
              </div>
            </div>
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div className={`${classes.search} ${absolute && overide.searchClass}`}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder={t('search.label')}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        onChange={(e) => handleChange(e.target.value)}
        value={value}
        className='search-bar'
        onFocus={() => setAbsolute(true)}
        onBlur={() => {
          setAbsolute(false);
          if (!hoverDiv) {
            setOptions([]);
          }
          console.log(hoverDiv);
        }}
      />
      <div className={opClasses.options}>
        {options.length > 0 &&
          options.map((res) => (
            <div
              onMouseEnter={() => setHoverDiv(true)}
              onMouseLeave={() => setHoverDiv(false)}
              onBlur={() => setHoverDiv(false)}
              onClick={() => {
                handleSelectedOption(res);
                setHoverDiv(false);
              }}
              key={res.gid}
            >
              {renderOptions(res)}
            </div>
          ))}
      </div>
    </div>
  );
};

SearchBar.propTypes = {};

const SearchBarView = (props) => {
  return <SearchBar {...props} />;
};

export default compose(withTranslation(), EnhanceWithPlayerControls)(SearchBarView);
