import Confirm from 'views/Auth/Confirm.jsx';
import Login from 'views/Auth/Login.jsx';
import Register from 'views/Auth/Register.jsx';
import RequestPasswordChange from 'views/Auth/RequestPasswordChange.jsx';
import ResetPassword from 'views/Auth/ResetPassword';

var authRoutes = [
  {
    path: '/login',
    name: 'login.label',
    component: Login,
    layout: '/auth',
  },
  {
    path: '/register',
    name: 'register.label',
    component: Register,
    layout: '/auth',
  },
  {
    path: '/confirm',
    name: 'Confirm',
    component: Confirm,
    layout: '/auth',
  },
  {
    path: '/requestPasswordChange',
    name: 'Request Password Change',
    component: RequestPasswordChange,
    layout: '/auth',
  },
  {
    path: '/resetPassword',
    name: 'Reset Password',
    component: ResetPassword,
    layout: '/auth',
  },
];

export default authRoutes;
