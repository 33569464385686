import { EnhanceWithCartControls } from 'util/Cart/Cart.jsx';

import React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import withStyles from '@material-ui/core/styles/withStyles';
import Close from '@material-ui/icons/Close';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';
import { getPaymentComponent } from 'components/PaymentComponents';
import Table from 'components/Table/Table.jsx';
import Currency from 'react-currency-formatter';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { Redirect, NavLink } from 'react-router-dom';
import { compose } from 'recompose';

import { SHOPPING_CART_VIEW, CLEAR_CART } from './graphql.jsx';

class ShoppingCart extends React.Component {
  render() {
    const {
      classes,
      data: { shoppingCart, paymentMethods, localeCurrency, loggedInUser },
      clearCart,
      t,
      i18n,
    } = this.props;

    const currencyCountryCode = localeCurrency.currencyCountryCode;
    const total = shoppingCart.reduce((acc, rel) => acc + rel.priceCategory.value, 0);
    if (shoppingCart.length < 1) return <Redirect to='/' />;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Table
              tableHead={['', t('common.release.label'), t('common.form.price.text'), '']}
              tableData={[
                ...shoppingCart.map((rel) => [
                  <div className={classes.imgContainer}>
                    <img src={rel.artwork} alt='...' className={classes.img} />
                  </div>,
                  <span>
                    <NavLink to={`/gallery/release/${rel.gid}`} className={classes.tdNameAnchor}>
                      {rel.name}
                    </NavLink>
                    <br />
                    <small className={classes.tdNameSmall}>
                      {t('common.by.text')} {rel.artists[0].name}
                    </small>
                  </span>,
                  <span>
                    <Currency
                      quantity={rel.priceCategory.value}
                      currency={localeCurrency.currency}
                    />
                  </span>,
                  <Button
                    justIcon
                    onClick={() => this.props.toggleToShoppingCart(rel)}
                    color='transparent'
                  >
                    <Close className={classes.icon} />
                  </Button>,
                ]),
                {
                  total: true,
                  colspan: '1',
                  amount: (
                    <span>
                      <Currency
                        quantity={total}
                        currency={localeCurrency.currency}
                        locale={i18n.languages[0]}
                      />
                    </span>
                  ),
                },
              ]}
              tableShopping
              customHeadCellClasses={[
                classes.center,
                classes.description,
                classes.description,
                classes.right,
                classes.right,
                classes.right,
              ]}
              customHeadClassesForCells={[0, 2, 3, 4, 5, 6]}
              customCellClasses={[
                classes.tdName,
                classes.customFont,
                classes.customFont,
                classes.tdNumber,
                classes.tdNumber + ' ' + classes.tdNumberAndButtonGroup,
                classes.tdNumber,
              ]}
              customClassesForCells={[1, 2, 3, 4, 5, 6]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <NavPills
              color='primary'
              tabs={paymentMethods.map((meth) => ({
                tabButton: meth.name,
                tabContent: getPaymentComponent(meth.name, {
                  currency: localeCurrency.currency,
                  currencyCountryCode,
                  amount: total,
                  locale: i18n.languages[0],
                  label: t('common.pay.text'),
                  customer: loggedInUser,
                  onSuccess: () => {
                    clearCart();
                  },
                  onError: (e) => {
                    console.log(e);
                  },
                  shoppingCart,
                }),
              }))}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const ShoppingCartView = (props) => {
  const { loading, data } = useQuery(SHOPPING_CART_VIEW);
  const [clearCart] = useMutation(CLEAR_CART, {
    refetchQueries: ['ShoppingCartView'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      NotificationManager.success(
        props.t('common.form.notification.receiptEmailed'),
        props.t('common.form.notification.purchaseComplete'),
        7000,
      );
    },
  });
  if (loading) return null;

  return <ShoppingCart {...props} data={data} clearCart={clearCart} />;
};

export default compose(
  withTranslation(),
  EnhanceWithCartControls,
  withStyles(extendedTablesStyle),
)(ShoppingCartView);
