import React from 'react';

import SalesStyl from 'assets/jss/material-dashboard-pro-react/components/salesTableStyle.jsx';
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  ValueAxis,
  Title,
  Label,
  ConstantLine,
  // Tooltip,
  VisualRange,
  Margin,
  Tick,
  MinorTick,
} from 'devextreme-react/chart';
import { useTranslation } from 'react-i18next';

import { primaryColor } from '../../assets/jss/material-dashboard-pro-react';
import SalesStyle from '../../assets/jss/material-dashboard-pro-react/components/salesChartStyle.jsx';

const SalesBarGraph = ({
  dataSource,
  threshold,
  currency,
  endGraphValue,
  tickIntervalValue,
  available,
  total,
}) => {
  const classes = SalesStyle();
  const classs = SalesStyl();
  const { t } = useTranslation();
  const histogram = (
    <Chart id='chart' height='400px' dataSource={dataSource}>
      <Margin top={20} bottom={20} left={20} right={30} />
      <CommonSeriesSettings
        argumentField='sales'
        type='stackedBar'
        barPadding={0.7}
        // barOverlapGroup="monarch" />
      />
      <Series
        valueField='available'
        name={t('sales.available') + ' ' + available}
        color={primaryColor[7]}
      />
      <Series valueField='Total' name={t('sales.total') + ' ' + total} color={primaryColor[0]} />
      <ValueAxis position='left' tickInterval={tickIntervalValue} valueMarginsEnabled={false}>
        <VisualRange startValue={0} endValue={endGraphValue} />
        <Title text={t('sales.amountSold') + ' ' + currency} />
        <ConstantLine
          width={3}
          value={threshold}
          color='#ff7c7c'
          dashStyle='dash'
          paddingTopBottom={0.5}
        >
          <Label text={t('sales.threshold')} />
        </ConstantLine>
        <Tick visible={false} />
        <MinorTick visible={false} />
      </ValueAxis>
      <Legend verticalAlignment='bottom' horizontalAlignment='center' itemTextPosition='right' />
      {/* <Tooltip
                enabled={true}
                color="white"
                location="egde"
                customizeTooltip={current}
            /> */}
    </Chart>
  );

  return (
    <div className={classes.chart}>
      {' '}
      <div className={classs.tableHeader}>{t('sales.salesBar')}</div>
      {histogram}
    </div>
  );
};

export default SalesBarGraph;
