import { makeStyles } from '@material-ui/core';
import { green, red, grey } from '@material-ui/core/colors';

export const chartsTableStyle = (theme) => ({
  head: {
    color: theme.palette.grey[900],
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 'bold',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
  },
  body: {
    fontSize: 14,
    fontWeight: 'bold',
  },
});

export const useStyles = makeStyles((theme) => ({
  tableHead: {
    backgroundColor: theme.palette.primary.main,
  },
  tableBodyBgWhite: {
    backgroundColor: 'white',
    width: '10%',
    textAlign: 'center',
  },
  tableBodyWeekPos: {
    backgroundColor: theme.palette.grey[900],
    width: '10%',
    color: 'white',
    textAlign: 'center',
  },
  tableBodyBgTransparent: {
    width: '10%',
    textAlign: 'center',
  },
  weekItem: {
    marginLeft: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  albumImageContainer: {
    width: 100,
    height: 100,
    position: 'relative',
  },
  albumImage: {
    width: '100%',
    height: '100%',
    display: 'block',
  },
  albumImagePlayContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transitionProperty: 'opacity',
    transitionDuration: '0.25',
    '&:hover': {
      opacity: 1,
    },
  },
  albumImageTextContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.grey[900],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transitionProperty: 'opacity',
    transitionDuration: '0.25',
    '&:hover': {
      opacity: 1,
    },
  },
  albumImageText: {
    textAlign: 'center',
    color: 'white',
  },
  albumImagePlayIcon: {
    width: '50%',
    height: '50%',
    color: theme.palette.primary.light,
  },
  tableBodyimage: {
    padding: 0,
  },
  tableBody: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  posIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  table: {
    minWidth: 650,
  },
  green: {
    color: green[500],
  },
  red: {
    color: red[500],
  },
  grey: {
    color: grey[500],
  },
  headerTitle: {
    color: theme.palette.grey[900],
    '&:hover': {
      borderBottomColor: theme.palette.grey[900],
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },
  },
}));
