import React from 'react';

import SalesStyl from 'assets/jss/material-dashboard-pro-react/components/salesTableStyle.jsx';
import { useTranslation } from 'react-i18next';
import './style.scss';
import PieChart, { Series, Label, Legend, Connector } from 'devextreme-react/pie-chart';

import SalesStyle from '../../assets/jss/material-dashboard-pro-react/components/salesChartStyle.jsx';

function formatLabel(arg) {
  return `${arg.argumentText}: ${arg.valueText}`;
}

const App = ({ countries }) => {
  const classes = SalesStyle();
  const classs = SalesStyl();
  const { t } = useTranslation();
  const pies = (
    <PieChart className='pie' palette='Bright' sizeGroup='piesGroup' dataSource={countries}>
      <Series argumentField='currency' valueField='sum'>
        <Label visible={true} format='percent' customizeText={formatLabel}>
          {' '}
          <Connector visible={true} width={0.5} />
        </Label>
      </Series>
      <Legend
        verticalAlignment='bottom'
        horizontalAlignment='center'
        itemTextPosition='right'
        rowCount={1}
      />
    </PieChart>
  );
  const emptyPie = (
    <PieChart className='pieEmpty' sizeGroup='piesGroup' dataSource={[{ currency: '', sum: '' }]}>
      <Series argumentField='currency' valueField='sum'>
        <Label visible={true} format='percent' customizeText={formatLabel}>
          {' '}
          <Connector visible={true} width={0.5} />
        </Label>
      </Series>
      <Legend
        verticalAlignment='bottom'
        horizontalAlignment='center'
        itemTextPosition='right'
        rowCount={1}
      />
    </PieChart>
  );

  return countries === undefined || countries.length === 0 ? (
    <div className={classes.chart}>
      {' '}
      <div className={classs.tableHeader}>{t('sales.salesPie')}</div>
      {emptyPie}
    </div>
  ) : (
    <div className={classes.chart}>
      {' '}
      <div className={classs.tableHeader}>{t('sales.salesPie')}</div>
      {pies}
    </div>
  );
};

export default App;
