import React from 'react';

import CustomPhoneNumber from 'components/CustomPhoneNumber/CustomPhoneNumber.jsx';
import { withFormsy } from 'formsy-react';
import { compose, withProps } from 'recompose';

function FormsyPhoneNumber(props) {
  const inputProps = {
    ...props.inputProps,
    value: props.value ? props.value : '',
    disabled: props.inputProps.disabled || props.isFormDisabled,
    onChange: (e) => {
      props.onChange(e);
      props.setValue(e);
    },
  };

  const errorMessage = props.errorMessage;
  const showError = props.isFormSubmitted && !props.isValid;

  return (
    <CustomPhoneNumber
      {...props}
      inputProps={inputProps}
      error={showError}
      helpText={showError ? errorMessage : ''}
    />
  );
}

const enhance = compose(
  withProps((props) => ({ name: props.id })),
  withFormsy,
);

export default enhance(FormsyPhoneNumber);
