import React, { useState, useRef, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import withStyles from '@material-ui/core/styles/withStyles';
import chartStyle from 'assets/jss/material-dashboard-pro-react/views/chartStyle';
import { compose } from 'recompose';

import FaArrowCircleLeft from 'react-icons/lib/fa/arrow-circle-left';
import FaArrowCircleRight from 'react-icons/lib/fa/arrow-circle-right';
import silver_medal from '../../assets/img/silver-medal.svg';
import gold_medal from '../../assets/img/gold-medal.svg';
import bronze_medal from '../../assets/img/bronze-medal.svg';
import ekoh_banner from '../../assets/img/ekoh-banner.png';

const WeeklyCharts = (props) => {
  const { classes, link1, link2, link3 } = props;
  const [t] = useTranslation();
  const [current, setCurrent] = useState(0);
  const length = 4;
  const timeout = useRef(null);

  useEffect(() => {
    const next = () => {
      setCurrent((current) => (current === length - 1 ? 0 : current + 1));
    };
    timeout.current = setTimeout(next, 2000);
    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [current, length]);
  const next = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prev = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };
  const logo = [
    {
      artwork: `${ekoh_banner}`,
      name: 'Ekoh Charts',
    },
    {
      artwork: `${bronze_medal}`,
      name: 'charts.third',
    },
    {
      artwork: `${silver_medal}`,
      name: 'charts.second',
    },
    {
      artwork: `${gold_medal}`,
      name: 'charts.first',
    },
  ];

  return (
    <div className={classes.chartContainer}>
      <div className={classes.chartSlider}>
        <div className={classes.chartShow}>
          {logo.map((slide, index) => {
            return (
              <div className={classes.slide} key={index}>
                {index === current && (
                  <div className={classes.slider}>
                    <img src={slide.artwork} alt='' />
                    <div className={classes.content}>
                      <h2>{t(`${slide.name}`)}</h2>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          <div className={classes.slideButton}>
            <FaArrowCircleLeft className={classes.dButton} onClick={next} />
            <FaArrowCircleRight className={classes.dButton} onClick={prev} />
          </div>
        </div>
      </div>

      <div className={classes.chartFlowChart}>
        <div className={classes.chartTop10}>
          <h3>Top 10</h3>
        </div>

        <List className={classes.chartTop}>
          <NavLink to={link1}>
            <ListItem>
              <img src={logo[3].artwork} alt='' />
              <h5>{t('charts.track')}</h5>
            </ListItem>
          </NavLink>
          <NavLink to={link2}>
            <ListItem>
              <img src={logo[2].artwork} alt='' />
              <h5>{t('charts.artist')}</h5>
            </ListItem>
          </NavLink>
          <NavLink to={link3}>
            <ListItem>
              <img src={logo[1].artwork} alt='' />
              <h5>{t('charts.album')}</h5>
            </ListItem>
          </NavLink>
        </List>
      </div>
    </div>
  );
};

export default compose(withStyles(chartStyle))(WeeklyCharts);
