import { EnhanceWithPlayerControls } from 'util/Player/Player.jsx';
import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  withStyles,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { PlayArrow } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import {
  chartsTableStyle,
  useStyles as tableStyles,
} from '../../../assets/jss/material-dashboard-pro-react/views/chartsTableStyle';
import { flux } from '../../../util';
import { MOST_PLAYED_RELEASES } from './graphql';
import WeekPostion from './WeekPosition';

const StyledTableCell = withStyles(chartsTableStyle)(TableCell);

class Album extends React.Component {
  render() {
    const { t, chartPeriod, albumTable, classes } = this.props;
    return (
      <TableContainer>
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <StyledTableCell className={classes.tableBody}>Pos.</StyledTableCell>
              <StyledTableCell colSpan={5} className={classes.tableBody}>
                {t('chartsdetail.albumName')}
              </StyledTableCell>
              <StyledTableCell className={classes.tableBody}>
                {t('chartsdetail.statistics.count')}
              </StyledTableCell>
              <StyledTableCell className={classes.tableBody}>
                {t(`chartsdetail.statistics.${chartPeriod}.last`)}
              </StyledTableCell>
              <StyledTableCell className={classes.tableBody}>
                {t('chartsdetail.statistics.peakPosition')}
              </StyledTableCell>
              <StyledTableCell className={classes.tableBody}>
                {t(`chartsdetail.statistics.${chartPeriod}.periodOnCharts`)}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {albumTable.map((row, index) => (
              <TableRow key={index}>
                <StyledTableCell className={classes.tableBodyWeekPos}>
                  <WeekPostion rank={row.rank} lastWeekRank={row.previousPeriodRank} />
                </StyledTableCell>
                <StyledTableCell className={classes.tableBodyimage}>
                  <div className={classes.albumImageContainer}>
                    <img alt='' src={row.artwork} className={classes.albumImage} />
                    <div className={classes.albumImagePlayContainer}>
                      <PlayArrow
                        className={classes.albumImagePlayIcon}
                        onClick={() => this.props.addReleaseToNewPlaylist(row)}
                      />
                    </div>
                  </div>
                </StyledTableCell>
                <StyledTableCell colSpan={4}>
                  <Typography
                    to={`/gallery/release/${row.gid}`}
                    className={classes.headerTitle}
                    component={NavLink}
                  >
                    <span className={classes.headerTitle}>{row.name}</span>
                  </Typography>
                </StyledTableCell>
                <StyledTableCell className={classes.tableBodyBgWhite}>
                  {flux(row.playCount)}
                </StyledTableCell>
                <StyledTableCell className={classes.tableBodyBgTransparent}>
                  {row.previousPeriodRank || '-'}
                </StyledTableCell>
                <StyledTableCell className={classes.tableBodyBgWhite}>
                  {row.highestChartRank}
                </StyledTableCell>
                <StyledTableCell className={classes.tableBodyBgTransparent}>
                  {row.consecutivePeriodsOnChart}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

const AlbumView = compose(withTranslation(), EnhanceWithPlayerControls)(Album);

const AlbumChartTable = (props) => {
  const classes = tableStyles();
  const { loading, data } = useQuery(MOST_PLAYED_RELEASES, {
    variables: {
      frequency: props.chartPeriod,
      limit: props.chartLimit,
      timePeriod: props.timePeriod,
    },
    onCompleted: (data) => {
      props.top({
        rank: data.mostPlayedReleases.edges[0].rank,
        highestChartRank: data.mostPlayedReleases.edges[0].highestChartRank,
        previousPeriodRank: data.mostPlayedReleases.edges[0].previousPeriodRank,
        consecutivePeriodsOnChart: data.mostPlayedReleases.edges[0].consecutivePeriodsOnChart,
        playCount: data.mostPlayedReleases.edges[0].playCount,
        ...data.mostPlayedReleases.edges[0].node,
      });
    },
  });

  if (loading) return null;

  const albumTable = data.mostPlayedReleases.edges.map((edge) => {
    return {
      rank: edge.rank,
      highestChartRank: edge.highestChartRank,
      previousPeriodRank: edge.previousPeriodRank,
      consecutivePeriodsOnChart: edge.consecutivePeriodsOnChart,
      playCount: edge.playCount,
      ...edge.node,
    };
  });

  return <AlbumView classes={classes} albumTable={albumTable} chartPeriod={props.chartPeriod} />;
};

export default AlbumChartTable;
