import { gql } from '@apollo/client';

const GET_USER_TRANSACTIONS = gql`
  query UserTransactions($first: Int, $cursor: String) {
    userSalesSummary {
      available
      threshold
      total
      currency
      currencySums {
        currency
        sum
      }
    }

    receivableTransactionsConnection(first: $first, after: $cursor) {
      edges {
        node {
          id
          transactionDate
          status {
            id
            ident
          }
          item {
            id
            name
          }
          amount
          currency
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        startCursor
      }
      totalCount
    }
  }
`;
const GET_PAYOUT_VIEW = gql`
  query PayoutView {
    getPublicSupportedCountries {
      id
      code
    }
  }
`;
const USER_SALES_GRAPH = gql`
  query UserSalesGraph {
    userSalesSummary {
      available
      threshold
    }
  }
`;
const COUNTRY_BANKS = gql`
  query CountryBanks($countryCode: String!) {
    countryBanks(countryCode: $countryCode) {
      id
      name
      code
    }
  }
`;

const REQUEST_PAYOUT = gql`
  mutation RequestPayout(
    $payoutCountryCode: String!
    $payoutCurrency: String!
    $payoutBank: String!
    $accountNumber: String!
    $confirmAccountNumber: String!
    $password: String!
  ) {
    requestPayout(
      payoutCountryCode: $payoutCountryCode
      payoutBank: $payoutBank
      payoutCurrency: $payoutCurrency
      accountNumber: $accountNumber
      confirmAccountNumber: $confirmAccountNumber
      password: $password
    ) {
      id
      modifiedDate
      status {
        id
        ident
      }
      statusReason
    }
  }
`;

export { REQUEST_PAYOUT, GET_USER_TRANSACTIONS, COUNTRY_BANKS, USER_SALES_GRAPH, GET_PAYOUT_VIEW };
