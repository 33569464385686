import { gql } from '@apollo/client';

const GET_UPLOAD_VIEW = gql`
  query UploadView {
    loggedInUser {
      id
      uploadLimitUsed
      uploadLimitSize
    }
    myArtists {
      id
      gid
      name
    }
    releasePrices {
      id
      tier
      value
    }
    localeCurrency {
      currency
    }
  }
`;

const CREATE_RELEASE = gql`
  mutation CreateRelease(
    $album: String!
    $albumArt: Upload!
    $albumArtist: ID!
    $genre: String!
    $price: ReleaseTier!
    $tracks: [UploadTrack]
    $day: Int!
    $month: Int!
    $year: Int!
  ) {
    createRelease(
      album: $album
      albumArt: $albumArt
      albumArtist: $albumArtist
      genre: $genre
      price: $price
      tracks: $tracks
      day: $day
      month: $month
      year: $year
    ) {
      id
      gid
      genre
      priceCategory {
        id
        tier
        value
      }
      media {
        id
        artwork(height: 80, width: 80)
        trackCount
        tracks {
          id
          gid
          name
          artists
          position
          sampleOffset
        }
      }
    }
  }
`;

const UPDATE_RELEASE = gql`
  mutation UpdateRelease(
    $gid: ID!
    $album: String!
    $albumArt: Upload
    $albumArtist: ID!
    $genre: String!
    $price: ReleaseTier!
    $tracks: [UploadTrack]
    $day: Int!
    $month: Int!
    $year: Int!
  ) {
    updateRelease(
      gid: $gid
      album: $album
      albumArt: $albumArt
      albumArtist: $albumArtist
      genre: $genre
      price: $price
      tracks: $tracks
      day: $day
      month: $month
      year: $year
    ) {
      id
      gid
      genre
      priceCategory {
        id
        tier
        value
      }
      media {
        id
        artwork(height: 80, width: 80)
        trackCount
        tracks {
          id
          gid
          name
          artists
          position
          sampleOffset
        }
      }
    }
  }
`;

const GET_RELEASE_BY_GID = gql`
  query GetRelease($gid: ID!) {
    releasePrices {
      id
      tier
      value
    }
    localeCurrency {
      currency
    }
    myArtists {
      id
      gid
      name
    }
    isLoggedIn
    loggedInUser {
      id
      uploadLimitUsed
      uploadLimitSize
    }
    shoppingCart {
      id
      gid
      name
      artists {
        id
        name
      }
      artwork(height: 80, width: 80)
      priceCategory {
        id
        tier
        value
      }
    }
    releaseByGid(gid: $gid) {
      id
      gid
      name
      day
      month
      year
      genre
      playCount
      artwork(height: 300, width: 300)
      priceCategory {
        id
        tier
        value
      }
      artists {
        id
        name
        gid
      }
      media {
        id
        artwork(height: 300, width: 300)
        trackCount
        tracks {
          id
          gid
          name
          artwork(height: 300, width: 300)
          artists
          duration
          position
          size
          sampleOffset
        }
      }
    }
  }
`;

export { GET_UPLOAD_VIEW, CREATE_RELEASE, GET_RELEASE_BY_GID, UPDATE_RELEASE };
