import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

class HelpRegister extends Component {
  render() {
    return (
      <Card>
        <CardBody>
          <List>
            <ListItem>- Visit ekoh.online and click on the Login/Register button</ListItem>
            <ListItem>
              - Click on Register, enter email address and password, read and agree with the terms
              and condition then click the register button
            </ListItem>
            <ListItem>
              - Go to your email inbox and verify your email by clicking on the button on the email.
              You will be redirected back to the website after verifying (NB. check your promotions
              email folder if the email is not in your inbox)
            </ListItem>
            <ListItem>
              - Click Confirm on the website and you can now enjoy all of Ekoh’s services
            </ListItem>
          </List>
        </CardBody>
      </Card>
    );
  }
}

export default HelpRegister;
