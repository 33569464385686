import AccountCircle from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BarChart from '@material-ui/icons/BarChart';
import Artists from 'views/Gallery/Artists.jsx';
import Gallery from 'views/Gallery/Gallery.jsx';
import TopCharts from '../views/Gallery/charts';

var galleryRoutes = [
  {
    path: '/index',
    // t("gallery.label")
    name: 'gallery.label',
    icon: DashboardIcon,
    component: Gallery,
    layout: '/gallery',
  },
  {
    path: '/artists',
    // t("artists.label")
    name: 'artists.label',
    component: Artists,
    icon: AccountCircle,
    layout: '/gallery',
  },
  {
    path: '/charts',
    // t("artists.label")
    name: 'Charts',
    component: TopCharts,
    icon: BarChart,
    layout: '/gallery',
  },
];

export default galleryRoutes;
