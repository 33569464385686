import { GENRE_LIST } from 'util/GenreList.jsx';
import React from 'react';

import * as Sentry from '@sentry/browser';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput2 from 'components/CustomInput/CustomInput.jsx';
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress.jsx';
import GlobalErrorFallback from 'components/Error/GlobalErrorComponent';
import FormsyDateInput from 'components/FormsyDateInput/FormsyDateInput.jsx';
import FormsyDropdown from 'components/FormsyDropdown/FormsyDropdown.jsx';
import CustomInput from 'components/FormsyInput/FormsyInput.jsx';
import FormsyFileUpload from 'components/FormsyUpload/FormsyFileUpload.jsx';
import FormsyPictureUpload from 'components/FormsyUpload/FormsyPictureUpload.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Formsy from 'formsy-react';
import jsmediatags from 'jsmediatags';
import { flowRight as compose } from 'lodash';
import _ from 'lodash';
import Currency from 'react-currency-formatter';
import Dropzone, { formatBytes, formatDuration } from 'react-dropzone-uploader';
import { NotificationManager } from 'react-notifications';

// core components

import 'react-dropzone-uploader/dist/styles.css';

import { pictureObjectToBase64, pictureObjectToBlob } from 'util.js';

import { withTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { GET_UPLOAD_VIEW, CREATE_RELEASE } from './graphql.jsx';

class UploadRelease extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploadSize: 0,
      progressbarColor: 'primary',
    };
  }

  parseTags = (t) => {
    const { tags } = t;
    const { title, artist, album, track, picture } = tags;
    return { title, artist, album, track, picture };
  };

  handleChangeStatus = (fileObj, status) => {
    if (status === 'done') {
      new jsmediatags.Reader(fileObj.file)
        .setTagsToRead(['title', 'artist', 'album', 'track', 'picture'])
        .read({
          onSuccess: (tags) => {
            let tracks = [...this.state.files, { fileObj, tags: this.parseTags(tags) }].sort(
              (a, b) => a.tags.track > b.tags.track,
            );
            let uploadSize = this.state.uploadSize + fileObj.meta.size;
            this.setState({
              files: tracks,
              uploadSize,
              progressbarColor: uploadSize > this.props.uploadLimitSize ? 'danger' : 'primary',
            });
          },
          onError: (error) => {
            Sentry.withScope((scope) => {
              scope.setExtra('error', error);
              scope.setExtra('name', fileObj.meta.name);
              Sentry.captureException(new Error('Could not read tags'));
            });
            this.setState({
              files: [...this.state.files, { fileObj, tags: {} }],
            });
          },
        });
    } else if (status === 'removed') {
      let uploadSize = this.state.uploadSize - fileObj.meta.size;
      this.setState({
        files: this.state.files.filter(
          (f) => f.fileObj.meta.uploadedDate !== fileObj.meta.uploadedDate,
        ),
        uploadSize,
        progressbarColor: uploadSize > this.props.uploadLimitSize ? 'danger' : 'primary',
      });
    } else if (status === 'rejected_file_type') {
      NotificationManager.error(
        'Make sure it is an audio file, and that it has a valid extension like .mp3 or .mpeg',
        'Invalid file type',
        7000,
      );

      Sentry.withScope((scope) => {
        scope.setExtra('name', fileObj.meta.name);
        Sentry.captureException(new Error('Invalid file'));
      });
    }
  };

  componentDidMount() {
    NotificationManager.info(
      this.props.t('common.form.notification.createArtist'),
      this.props.t('common.form.notification.info'),
      10000,
      () => {
        this.props.history.push('/gallery/myartists/new');
      },
    );
  }

  render() {
    const {
      data: { loggedInUser, releasePrices, myArtists, localeCurrency },
      t,
      i18n,
    } = this.props;

    return (
      <GridContainer justify='center'>
        <GridItem xs={6}>
          <span>{formatBytes(loggedInUser.uploadLimitUsed + this.state.uploadSize)}</span>
        </GridItem>
        <GridItem xs={6}>
          <span>{formatBytes(loggedInUser.uploadLimitSize)}</span>
        </GridItem>
        <GridItem xs={12}>
          <CustomLinearProgress
            color={this.state.progressbarColor}
            variant='buffer'
            value={(loggedInUser.uploadLimitUsed * 100) / loggedInUser.uploadLimitSize}
            valueBuffer={
              ((loggedInUser.uploadLimitUsed + this.state.uploadSize) * 100) /
              loggedInUser.uploadLimitSize
            }
          />
        </GridItem>
        <GridItem xs={12}>
          <GridItem xs={12}>
            <Dropzone
              autoUpload={false}
              getUploadParams={null}
              onChangeStatus={this.handleChangeStatus}
              inputContent={t('common.input.file.add')}
              inputWithFilesContent={t('common.input.file.more')}
              accept='audio/*,video/*'
            />
          </GridItem>
          <GlobalErrorFallback>
            <GridItem xs={12}>
              <Formsy
                onValidSubmit={(d) => {
                  const { date } = d;
                  delete d.date;
                  const day = date.getDate();
                  const month = date.getMonth();
                  const year = date.getFullYear();
                  const tracks = d.tracks.map((tr) => ({
                    ...tr,
                    track: parseInt(tr.track),
                  }));
                  const payload = {
                    ...d,
                    day,
                    month,
                    year,
                    tracks,
                    albumArt: d.albumArt && d.albumArt.file ? d.albumArt.file : null,
                  };
                  this.props.updateRelease({ variables: payload });
                }}
                ref='uploadForm'
              >
                <GridContainer>
                  {this.state.files[0] && (
                    <GridContainer>
                      <GridItem xs={12} md={2}>
                        <GridContainer>
                          <GridItem xs={12}>
                            <FormsyPictureUpload
                              id='albumArt'
                              {...(this.state.files[0] &&
                                this.state.files[0].tags.picture && {
                                  value: {
                                    imagePreviewUrl: pictureObjectToBase64(
                                      this.state.files[0].tags.picture,
                                    ),
                                    file: pictureObjectToBlob(this.state.files[0].tags.picture),
                                  },
                                })}
                              required
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} md={10}>
                        <GridContainer>
                          <GridItem xs={12} md={3}>
                            <CustomInput
                              labelText={t('common.form.album.text')}
                              id='album'
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={this.state.files[0].tags.album}
                              required
                            />
                          </GridItem>
                          <GridItem xs={12} md={2}>
                            <FormsyDropdown
                              labelText={t('common.form.genre.text')}
                              id='genre'
                              formControlProps={{
                                fullWidth: true,
                              }}
                              dropdownList={_.sortBy(GENRE_LIST, (g) => [g.name])}
                              value={''}
                              required
                            />
                          </GridItem>
                          <GridItem xs={12} md={2}>
                            <FormsyDateInput
                              labelText={t('common.form.releaseDate.text')}
                              id='date'
                              formControlProps={{
                                fullWidth: true,
                              }}
                              required
                            />
                          </GridItem>
                          <GridItem xs={12} md={2}>
                            <FormsyDropdown
                              labelText={t('common.form.price.text')}
                              id='price'
                              formControlProps={{
                                fullWidth: true,
                              }}
                              dropdownList={_.sortBy(
                                _.map(releasePrices, (p) => ({
                                  name: (
                                    <Currency
                                      quantity={p.value}
                                      currency={localeCurrency.currency}
                                      locale={i18n.languages[0]}
                                    />
                                  ),
                                  value: p.tier,
                                })),
                                [(p) => p.value],
                              )}
                              value={''}
                              required
                            />
                          </GridItem>
                          <GridItem xs={12} md={2}>
                            <FormsyDropdown
                              labelText={t('common.form.albumArtist.text')}
                              id='albumArtist'
                              formControlProps={{
                                fullWidth: true,
                              }}
                              dropdownList={myArtists.reduce(
                                (acc, i) => [...acc, { name: i.name, value: i.gid }],
                                [
                                  {
                                    navButton: true,
                                    text: t('artists.new'),
                                    to: '/gallery/myartists/new',
                                  },
                                  { divider: true },
                                ],
                              )}
                              value={''}
                              required
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  )}
                  <GridContainer>
                    <GridItem xs={12}>
                      {this.state.files.map((f, i) => (
                        <GridContainer key={i}>
                          <GridItem xs={12} md={2}>
                            <CustomInput
                              labelText={t('common.form.trackNumber.text')}
                              id={`tracks[${i}].track`}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              value={f.tags.track}
                              required
                            />
                          </GridItem>
                          <GridItem xs={12} md={3}>
                            <CustomInput
                              labelText={t('common.form.title.text')}
                              id={`tracks[${i}].title`}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={f.tags.title}
                              required
                            />
                          </GridItem>
                          <GridItem xs={12} md={3}>
                            <CustomInput
                              labelText={t('common.form.artist.text')}
                              id={`tracks[${i}].artist`}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              value={f.tags.artist}
                              required
                            />
                          </GridItem>
                          <GridItem xs={5} md={1}>
                            <CustomInput2
                              labelText={t('common.form.duration.text')}
                              id={`tracks[${i}].displayduration`}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                disabled: true,
                                defaultValue: formatDuration(f.fileObj.meta.duration),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={5} md={1}>
                            <CustomInput2
                              labelText={t('common.form.size.text')}
                              id={`tracks[${i}].displaysize`}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                disabled: true,
                                defaultValue: formatBytes(f.fileObj.meta.size),
                              }}
                            />
                          </GridItem>
                          <GridItem xs={5} md={2}>
                            <CustomInput
                              labelText={t('common.form.trackStart.text')}
                              id={`tracks[${i}].sampleOffset`}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              validations={{
                                matchRegexp: /^\d{2}:\d{2}$/,
                              }}
                              validationError={t('common.form.trackStart.validation')}
                            />
                          </GridItem>
                          <GridItem md={1}>
                            <CustomInput
                              id={`tracks[${i}].duration`}
                              formControlProps={{
                                fullWidth: false,
                              }}
                              inputProps={{
                                disabled: true,
                                type: 'hidden',
                              }}
                              value={f.fileObj.meta.duration}
                              required
                            />
                          </GridItem>
                          <GridItem md={1}>
                            <FormsyFileUpload
                              labelText={t('common.form.file.text')}
                              id={`tracks[${i}].file`}
                              value={f.fileObj.file}
                              required
                            />
                          </GridItem>
                        </GridContainer>
                      ))}
                    </GridItem>
                  </GridContainer>
                </GridContainer>
                {this.state.files[0] && (
                  <div>
                    <Button color='warning' onClick={() => this.refs.uploadForm.reset()}>
                      {t('common.form.reset.text')}
                    </Button>
                    <Button type='submit' color='warning'>
                      {t('common.form.upload.text')}
                    </Button>
                  </div>
                )}
              </Formsy>
            </GridItem>
          </GlobalErrorFallback>
        </GridItem>
      </GridContainer>
    );
  }
}

const UploadReleaseView = (props) => {
  const { loading, data } = useQuery(GET_UPLOAD_VIEW);
  const [updateRelease] = useMutation(CREATE_RELEASE, {
    refetchQueries: ['GalleryReleases', 'UploadView'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      NotificationManager.success(
        props.t('common.form.notification.releaseNotification'),
        props.t('common.form.notification.releaseUploading'),
      );
      props.history.push('/');
    },
  });

  if (loading) return null;

  return <UploadRelease {...props} data={data} updateRelease={updateRelease} />;
};

export default compose(withTranslation())(UploadReleaseView);
