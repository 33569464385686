import React from 'react';

import { withFormsy } from 'formsy-react';
import { compose, withProps } from 'recompose';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.handleFileChange = this.handleFileChange.bind(this);
  }
  handleFileChange(e) {
    e.preventDefault();
    e.persist();
    let file = e.target.files[0];
    e.target.validity.valid && this.props.setValue({ file });
  }

  render() {
    const value = this.props.value;

    const dt = new window.DataTransfer();
    dt.items.add(value);

    var input = document.createElement('input');
    input.type = 'file';
    input.files = dt.files;
    return <div />;
  }
}

const enhance = compose(
  withProps((props) => ({ name: props.id, innerRef: null })),
  withFormsy,
);

export default enhance(FileUpload);
