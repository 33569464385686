import { EnhanceWithCartControls } from 'util/Cart/Cart.jsx';
import React from 'react';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';
import { compose } from 'recompose';
import { useQuery } from '@apollo/client';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';

// @material-ui/icons

import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Close from '@material-ui/icons/Close';

// core components
import Button from 'components/CustomButtons/Button.jsx';

import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx';

import { GET_NAVBAR_DATA } from './graphql.jsx';

import NotificationsLink from './NotificationsLink.jsx';

class HeaderLinks extends React.Component {
  state = {
    searchBarOpen: false,
    shoppingCartCloseButtonOpen: false,
  };

  render() {
    const { classes, rtlActive, data } = this.props;

    if (!data) {
      return null;
    }

    const { isLoggedIn, shoppingCart } = data;
    const { shoppingCartCloseButtonOpen } = this.state;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive,
    });

    return (
      <div className={wrapper}>
        {isLoggedIn ? (
          <div>
            <NavLink to='/gallery/shoppingCart'>
              <Button
                color='transparent'
                justIcon
                aria-label='ShoppingCart'
                className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                muiClasses={{
                  label: rtlActive ? classes.labelRTL : '',
                }}
                ref={(node) => {
                  this.shoppingCartEl = node;
                }}
                onMouseOver={() => this.setState({ shoppingCartCloseButtonOpen: true })}
              >
                <ShoppingCart
                  color='action'
                  className={
                    classes.headerLinksSvg +
                    ' ' +
                    (rtlActive ? classes.links + ' ' + classes.linksRTL : classes.links)
                  }
                />
                {shoppingCart && shoppingCart.length > 0 && (
                  <span className={classes.notifications}>{shoppingCart.length}</span>
                )}
                <Hidden mdUp implementation='css'>
                  <span className={classes.linkText}>
                    {rtlActive ? 'الملف الشخصي' : 'ShoppingCart'}
                  </span>
                </Hidden>
              </Button>
            </NavLink>
            <Popper
              open={shoppingCartCloseButtonOpen}
              anchorEl={this.shoppingCartEl}
              transition
              disablePortal
              placement='bottom'
              className={classNames({
                [classes.popperClose]: !shoppingCartCloseButtonOpen,
                [classes.pooperResponsive]: true,
                [classes.pooperNav]: true,
              })}
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps} id='menu-list' style={{ transformOrigin: '0 0 0' }}>
                  <Paper className={classes.dropdown}>
                    <ClickAwayListener
                      onClickAway={() => this.setState({ shoppingCartCloseButtonOpen: false })}
                    >
                      <Button
                        aria-label='Clear Cart'
                        color='transparent'
                        aria-owns={shoppingCartCloseButtonOpen ? 'menu-list' : null}
                        aria-haspopup='true'
                        onClick={() => {
                          this.props.clearCart();
                          this.setState({
                            shoppingCartCloseButtonOpen: !this.state.shoppingCartCloseButtonOpen,
                          });
                        }}
                      >
                        Clear Cart <Close />
                      </Button>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <NotificationsLink />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

const HeaderLinksViewResult = compose(
  EnhanceWithCartControls,
  withStyles(adminNavbarLinksStyle),
)(HeaderLinks);

const HeaderLinksView = (props) => {
  const { loading, data } = useQuery(GET_NAVBAR_DATA);
  if (loading) return null;
  return <HeaderLinksViewResult data={data} {...props} />;
};

export default HeaderLinksView;
