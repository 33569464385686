import { makeStyles } from '@material-ui/core/styles';
import { whiteColor } from 'assets/jss/material-dashboard-pro-react.jsx';

const SalesCardStyle = makeStyles({
  cardContainer: {
    width: '176px',
    height: '89',
    borderRadius: '5px',
    background: whiteColor,
  },
  cardHeader: {
    color: 'red',
    fontSize: '14px',
  },
  cardFoot: {
    color: 'red',
    border: '2px solid red',
    display: 'flex',
  },
  salesAmount: {
    color: 'red',
  },
  saleRoot: { flexGrow: 1 },
  barItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  barCont: {
    height: 'fit-content',
    display: 'inline-block',
    width: '50%',
  },
  pieCont: {
    height: 'fit-content',
    width: '45%',
  },
});

export default SalesCardStyle;
