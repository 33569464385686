import React from 'react';

import { useMutation } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import Email from '@material-ui/icons/Email';
import * as Sentry from '@sentry/browser';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/FormsyInput/FormsyInput.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { compose } from 'recompose';
import Store from 'store';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { LOGIN_USER } from './graphql.jsx';

class LoginPage extends React.Component {
  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={6} md={4}>
            <Formsy
              className={classes.form}
              onValidSubmit={(d) => {
                this.props.login({ variables: d });
              }}
              ref='loginForm'
            >
              <Card login className={classes.cardSignup}>
                <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} signup>
                  <h4 className={classes.cardTitle}>{t('login.label')}</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    id='email'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      placeholder: `${t('common.form.email.text')} ...`,
                    }}
                    validations='isEmail'
                    validationError='Not a valid Email address'
                    required
                  />
                  <CustomInput
                    id='password'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                        </InputAdornment>
                      ),
                      type: 'password',
                      placeholder: `${t('common.form.password.text')} ...`,
                    }}
                    required
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button type='submit' color='warning' size='lg' round block>
                    {t('login.label')}
                  </Button>
                </CardFooter>
                <CardFooter className={classes.justifyContentCenter}>
                  <GridContainer>
                    <GridItem>
                      <div>
                        {t('login.link.register')}
                        <NavLink to={'/auth/register'}>{t('register.label')}</NavLink>
                      </div>
                    </GridItem>
                    <GridItem>
                      <NavLink to={'/auth/requestPasswordChange'}>
                        {t('login.link.password')}
                      </NavLink>
                    </GridItem>
                  </GridContainer>
                </CardFooter>
              </Card>
            </Formsy>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const LoginView = (props) => {
  const [login] = useMutation(LOGIN_USER, {
    onCompleted: (d) => {
      const auth = getAuth();
      const { accessToken, email, gid, firebaseToken } = d.loginUser;
      Store.set('userToken', accessToken);
      Sentry.setUser({ email: email, gid: gid });
      signInWithCustomToken(auth, firebaseToken)
        .then(() => {
          props.client.resetStore().then(() => {
            props.history.push('/');
          });
        })
        .catch((error) => {
          Sentry.captureException(error);
        });
    },
  });

  return <LoginPage {...props} login={login} />;
};

export default compose(withTranslation(), withStyles(loginPageStyle), withApollo)(LoginView);
