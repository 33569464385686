import {
  defaultFont,
  primaryColor,
  boxShadow,
  drawerWidth,
  transition,
  whiteColor,
  grayColor,
  blackColor,
  hexToRgb,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const pagesHeaderStyle = (theme) => ({
  appBar: {
    backgroundColor: '#333333',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    height: '35px',
    position: 'absolute',
    width: '100%',
    zIndex: '21009',
    color: grayColor[6],
    border: '0',
    padding: ' 0',
    transition: 'all 150ms ease 0s',
    minHeight: '40px',
    display: 'block',
  },
  navT: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '6px',
    paddingRight: '20px',
  },

  flex: {
    flex: 1,
  },
  bold: {
    fontWeight: '700',
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: whiteColor,
    '&:hover,&:focus': {
      background: 'transparent',
      color: whiteColor,
    },
  },
  logoMin: {
    display: 'flex',
    alignItems: 'center',
    height: 'fit-content',
    gap: '10px',
    textDecoration: 'none',
    color: whiteColor,
    fontSize: '20px',
    '&:hover,&:focus': {
      background: 'transparent',
      color: primaryColor[0],
    },
  },
  logoNormal: {
    color: whiteColor,
    textDecoration: 'none',
    '&:hover,&:focus': {
      background: 'transparent',
      color: whiteColor,
    },
  },
  appResponsive: {
    top: '8px',
  },

  list: {
    ...defaultFont,
    fontSize: '14px',
    margin: '0',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    paddingLeft: '0',
    listStyle: 'none',
    color: whiteColor,
    paddingTop: '0',
    paddingBottom: '0',
  },
  listItem: {
    float: 'left',
    position: 'relative',
    display: 'block',
    width: 'auto',
    margin: '0',
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      zIndex: '999',
      width: '100%',
      paddingRight: '15px',
    },
  },
  navLink: {
    color: whiteColor,
    margin: '0 5px',
    fontSize: '10px',
    fontWeight: '700',
    borderRadius: '3px',
    lineHeight: '20px',
    position: 'relative',
    display: 'block',
    padding: '3px 5px',
    textDecoration: 'none',
    '&:hover,&:focus': {
      color: whiteColor,
      background: 'rgba(' + hexToRgb(grayColor[17]) + ', 0.2)',
    },
  },
  navLogo: {
    color: whiteColor,

    marginLeft: '20px',
    height: 'fit-content',
    fontSize: '10px',
    fontWeight: '700',
    position: 'relative',
    display: 'block',

    textDecoration: 'none',
    '&:hover,&:focus': {
      color: whiteColor,
    },
  },

  listItemIcon: {
    marginTop: '-3px',
    top: '0px',
    position: 'relative',
    marginRight: '5px',
    width: '15px',
    height: '15px',
    verticalAlign: 'middle',
    color: primaryColor[0],
    display: 'inline-block',
    '@media only screen and (max-width: 453px)': {
      fontSize: '.8rem',
    },
  },
  hiddenText: {
    fontSize: '14px',
  },
  listItemText: {
    flex: 'none',
    padding: '0',
    minWidth: '0',
    margin: 0,
    color: primaryColor[0],
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
    '@media (max-width: 453px)': {
      fontSize: '.5rem',
      width: '2.3rem',
      margin: '0 1px 0 1px ',
    },
  },
  navLinkActive: {
    backgroundColor: 'rgba(' + hexToRgb(whiteColor) + ', 0.1)',
  },
  drawerPaper: {
    border: 'none',
    bottom: '0',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    ...boxShadow,
    width: drawerWidth,
    ...boxShadow,
    position: 'fixed',
    display: 'block',
    top: '0',
    height: '100vh',
    right: '0',
    left: 'auto',
    visibility: 'visible',
    overflowY: 'visible',
    borderTop: 'none',
    textAlign: 'left',
    paddingRight: '0px',
    paddingLeft: '0',
    ...transition,
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0',
    },
    '&:after': {
      background: blackColor,
      opacity: '.8',
    },
  },
  sidebarButton: {
    '&,&:hover,&:focus': {
      color: whiteColor,
    },
    top: '-2px',
  },
});

export default pagesHeaderStyle;
