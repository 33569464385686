import React from 'react';
import Button from 'components/CustomButtons/Button.jsx';
import { usePaystackPayment } from 'react-paystack';

const Paystack = (props) => {
  const {
    currency,
    amount,
    text,
    title,
    onSuccess,
    email,
    firstName,
    lastName,
    reference,
    metadata,
    publicKey,
  } = props;

  const config = {
    publicKey,
    email,
    amount,
    currency,
    firstName,
    lastName,
    reference,
    metadata,
    text,
    title,
  };

  const initializePayment = usePaystackPayment(config);
  const handleClick = () => {
    initializePayment(() => {
      onSuccess();
    });
  };

  return (
    <Button color='warning' onClick={handleClick}>
      {text}
    </Button>
  );
};

export default Paystack;
