import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';

import dd from '../../assets/img/dd1.png';

import './style.css';

function Download() {
  const [apkLink, setApkLink] = useState(null);

  const opt = { ignoreErrors: true };
  const { t } = useTranslation();
  useEffect(() => {
    const storeUrl = 'https://apk-prod-ekoh-online.s3-eu-west-1.amazonaws.com/android/version.json';

    fetch(storeUrl, opt.fetchOptions)
      .then((res) => res.json())
      .then((text) => {
        if (text) {
          setApkLink(text.updateUrl);
        }
      });
  }, []);

  return (
    <div className='container'>
      <div className='c'>
        <div className='image'>
          {' '}
          <img
            id='img'
            src='https://imagecdn.ekoh.online/unsafe/trim/fit-in/300x300/smart/public-assets/ekoh_mobile_pic.png'
            alt='App'
          />{' '}
        </div>
        <div className='title'>
          <br />
          <h3>{t('download.title')}</h3>
        </div>
        <div className='email'>
          <br />
          {/* <div className="emailInputContainer">
            <input className="emailInput" type="email" placeholder="Email" />
            <button className="emailButton">{t("download.emailButton")}</button>
          </div> */}
        </div>
        <div className='text'>{t('download.text')}</div>
        <div className='buttons'>
          <a
            href='https://play.google.com/store/apps/details?id=online.ekoh'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src='https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg'
              alt='GET IT FROM PLAY STORE'
              style={{ height: 50, width: 300 }}
            />
          </a>

          <a href={apkLink}>
            <img src={dd} alt='DOWNLOAD APK FILE' style={{ height: 60, width: 200 }} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Download;
