import React from 'react';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SalesStyle from 'assets/jss/material-dashboard-pro-react/components/salesTableStyle.jsx';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const Status = ({ type = 'success' }) => {
  const classes = SalesStyle();
  const { t } = useTranslation();
  if (type === 'unpaid') {
    return (
      <div className={cx(classes.displayF, classes.pending)}>
        <FiberManualRecordIcon style={{ fontSize: '18px', marginTop: '1px' }} />
        {t('sales.status.unpaid')}
      </div>
    );
  } else if (type === 'error') {
    return (
      <div className={cx(classes.displayF, classes.unpaid)}>
        <FiberManualRecordIcon style={{ fontSize: '18px', marginTop: '1px' }} />{' '}
        {t('sales.status.error')}
      </div>
    );
  } else {
    return (
      <div className={cx(classes.displayF, classes.success)}>
        <FiberManualRecordIcon style={{ fontSize: '18px', marginTop: '1px' }} />{' '}
        {t('sales.status.complete')}
      </div>
    );
  }
};

export default Status;
