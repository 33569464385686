import React, { useEffect, useContext } from 'react';

import { useQuery } from '@apollo/client';
import withStyles from '@material-ui/core/styles/withStyles';
import releaseStyle from 'assets/jss/material-dashboard-pro-react/views/releasesStyle';
import { compose } from 'recompose';
import { mainPanelContext } from 'views/Gallery/Context.js';
import * as Sentry from '@sentry/browser';

import FreeRelease from '../../components/Release/FreeRelease';
import { FREE_RELEASES_CONNECTION } from './graphql.jsx';

const FreeReleasesContent = (props) => {
  const { mainPanel } = useContext(mainPanelContext);
  const { active, index, classes } = props;

  const { loading, data, fetchMore } = useQuery(FREE_RELEASES_CONNECTION, {
    variables: {
      first: 8,
    },
  });

  const scrollEvent = (e) => {
    let element = e.target;

    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      if (data && data.freeReleasesConnection.pageInfo.hasNextPage) {
        try {
          return fetchMore({
            variables: {
              cursor: data.freeReleasesConnection.pageInfo.endCursor,
            },
          });
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    }
  };
  useEffect(() => {
    if (active === index) {
      if (mainPanel && mainPanel.current) {
        try {
          mainPanel.current.addEventListener('scroll', scrollEvent, true);
        } catch (error) {
          Sentry.captureException(error);
        }
      }
    }
    return () => {
      if (active === index) {
        if (mainPanel && mainPanel.current) {
          mainPanel.current.removeEventListener('scroll', scrollEvent, true);
        }
      }
    };
  }, [data, active, index]);
  if (loading) return null;

  const freeReleases = data.freeReleasesConnection.edges.map((edge) => edge.node);

  return active ? (
    <div> </div>
  ) : (
    <div className={classes.mainContainer}>
      {' '}
      {freeReleases.map((rel) => (
        <FreeRelease key={rel.id} rel={rel} gallery={true} />
      ))}
    </div>
  );
};

export default compose(withStyles(releaseStyle))(FreeReleasesContent);
