import { EnhanceWithArtistControls } from 'util/Artist/Artist';
import { EnhanceWithPlayerControls } from 'util/Player/Player.jsx';

import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Check from '@material-ui/icons/VerifiedUser';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import ReactCountryFlag from 'react-country-flag';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { compose } from 'recompose';

function Artist({ ...props }) {
  const { artist, loggedInUser, isLoggedIn, classes, t } = props;
  const { gid, name, artwork, follows, followerCount, country, verified } = artist;

  let followText = follows ? t('common.follow.unfollow') : t('common.follow.text');

  return (
    <GridItem xs={12} sm={12} md={3}>
      <Card product className={classes.cardHover}>
        <CardHeader image className={classes.cardHeaderHover}>
          <NavLink to={`/gallery/artist/${gid}`}>
            <img className={classes.cardImage} src={artwork} alt='...' />
          </NavLink>
        </CardHeader>
        <CardBody>
          <p className={classes.cardProductDesciprion}>
            <NavLink to={`/gallery/artist/${gid}`}>
              {name} {verified && <Check style={{ fill: 'orange' }} />}
            </NavLink>
          </p>
          <p className={classes.cardProductDesciprion}>
            <b>{followerCount}</b> {t('common.follower.text', { count: followerCount })}
          </p>
        </CardBody>
        <CardFooter product>
          <div className={`${classes.stats} ${classes.productStats}`}>
            <NavLink to={`/gallery/country/${country.id}`}>
              <ReactCountryFlag code={country.code} svg />
            </NavLink>
          </div>
          {isLoggedIn && (
            <Button
              color='warning'
              size='sm'
              onClick={() => props.toggleFollowArtist(loggedInUser.email, gid)}
              round
            >
              {followText}
            </Button>
          )}
        </CardFooter>
      </Card>
    </GridItem>
  );
}

const ArtistResults = compose(
  withTranslation(),
  EnhanceWithPlayerControls,
  EnhanceWithArtistControls,
  withStyles(dashboardStyle),
)(Artist);

const ArtistView = (props) => <ArtistResults {...props} />;

export default ArtistView;
