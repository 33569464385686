import { gql } from '@apollo/client';

const RELEASE_FRAGMENT = gql`
  fragment ReleaseFragment on Release {
    id
    gid
    name
    year
    genre
    playCount
    country {
      id
      code
    }
    priceCategory {
      id
      tier
      value
    }
    artists {
      id
      gid
      name
    }
    media {
      id
      artwork(height: 500, width: 500)
      trackCount
    }
  }
`;

const PAGEINFO_FRAGMENT = gql`
  fragment PAGEINFO on PageInfo {
    endCursor
    hasNextPage
  }
`;

const GALLERY_RELEASES = gql`
  query GalleryReleases {
    freeReleases {
      ...ReleaseFragment
    }

    paidReleases {
      ...ReleaseFragment
    }
  }
  ${RELEASE_FRAGMENT}
`;

const GALLERY_ARTISTS = gql`
  query GalleryArtists {
    loggedInUser {
      id
      email
      userName
      verified
    }
    isLoggedIn
    publicArtists {
      id
      gid
      name
      artwork(height: 500, width: 500)
      verified
      follows
      followerCount
      country {
        id
        code
      }
    }
  }
`;

const FREE_RELEASES_CONNECTION = gql`
  query FreeReleasesConnection($first: Int, $cursor: String) {
    freeReleasesConnection(first: $first, after: $cursor) {
      edges {
        node {
          ...ReleaseFragment
        }
      }
      pageInfo {
        ...PAGEINFO
      }
    }
  }

  ${RELEASE_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;

const PAID_RELEASES_CONNECTION = gql`
  query PaidReleasesConnection($first: Int, $cursor: String) {
    paidReleasesConnection(first: $first, after: $cursor) {
      edges {
        node {
          ...ReleaseFragment
        }
      }
      pageInfo {
        ...PAGEINFO
      }
    }
  }
  ${RELEASE_FRAGMENT}
  ${PAGEINFO_FRAGMENT}
`;

export { GALLERY_RELEASES, GALLERY_ARTISTS, FREE_RELEASES_CONNECTION, PAID_RELEASES_CONNECTION };
