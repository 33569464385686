import * as moment from 'moment';
import 'moment-duration-format';
import approx from 'approximate-number';

import * as fromCodePoint from 'string.fromcodepoint';

const countryToFlag = (code) => {
  return code.toUpperCase().replace(/./g, (char) => fromCodePoint(char.charCodeAt(0) + 127397));
};

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  var byteString = window.atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var arrayBuffer = new ArrayBuffer(byteString.length);
  var _ia = new Uint8Array(arrayBuffer);
  for (var i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }

  var dataView = new DataView(arrayBuffer);
  var blob = new window.Blob([dataView], { type: mimeString });
  return blob;
}

function pictureToBase64(picture) {
  const { data } = picture;
  if (data) {
    return window.btoa(data.reduce((acc, val) => acc + String.fromCharCode(val), ''));
  }
  return '';
}

function pictureObjectToBase64(picture) {
  const { format } = picture;
  if (format) {
    return 'data:' + format + ';base64,' + pictureToBase64(picture);
  }
  return '';
}

function pictureObjectToBlob(picture) {
  return dataURItoBlob(pictureObjectToBase64(picture));
}

const objectListToString = (o) => o.map((i) => i.name).join();

const formatTime = (t) => moment.duration(t).format();

const getMonthName = (monthNumber) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return monthNames[monthNumber];
};

const monthFormater = (month) => {
  if (!month) {
    return '';
  }
  const [year, monthNum] = month.split('-M');
  const date = moment([year, monthNum - 1, 1]);
  return date;
};

const flux = (value) => {
  return approx(value, { capital: true });
};

export {
  dataURItoBlob,
  pictureObjectToBase64,
  pictureObjectToBlob,
  objectListToString,
  formatTime,
  countryToFlag,
  getMonthName,
  monthFormater,
  flux,
};
