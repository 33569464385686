import React, { Component } from 'react';

import { useMutation } from '@apollo/client';
import withStyles from '@material-ui/core/styles/withStyles';
import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import Button from 'components/CustomButtons/Button.jsx';
import CountryDropdown from 'components/FormsyDropdown/CountryDropdown.jsx';
import CustomInput from 'components/FormsyInput/FormsyInput.jsx';
import FormsyPictureUpload from 'components/FormsyUpload/FormsyPictureUpload.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Formsy from 'formsy-react';
import { flowRight as compose } from 'lodash';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';

import { CREATE_ARTIST } from './graphql.jsx';

class Artist extends Component {
  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Formsy
              className={classes.form}
              onValidSubmit={(d) => {
                const payload = {
                  ...d,
                  artwork: d.artwork && d.artwork.file ? d.artwork.file : null,
                  country: d.country ? d.country.code : null,
                };
                this.props.createArtist({ variables: payload });
              }}
              ref='form'
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <FormsyPictureUpload id='artwork' required />
                </GridItem>
                <GridItem xs={12} md={8}>
                  <CustomInput
                    labelText={t('common.form.artist.textOnly')}
                    id='name'
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      placeholder: `${t('common.form.artist.textOnly')}...`,
                    }}
                    validations='isExisty'
                    validationError={t('common.form.artist.validation')}
                    required
                  />

                  <CountryDropdown
                    id='country'
                    labelText={t('common.form.country.text')}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={{ code: '', label: '' }}
                    required
                  />
                  <CustomInput
                    labelText={t('common.form.artistBio.text')}
                    id='bio'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                      placeholder: `${t('common.form.artistBio.text')} ...`,
                    }}
                    required
                  />
                  <Button color='warning' onClick={() => this.refs.form.reset()}>
                    {t('common.form.reset.text')}
                  </Button>
                  <Button type='submit' color='warning'>
                    {t('common.form.create.text')}
                  </Button>
                </GridItem>
              </GridContainer>
            </Formsy>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const ArtistView = (props) => {
  const [createArtist] = useMutation(CREATE_ARTIST, {
    refetchQueries: ['MyArtists', 'UploadView'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      NotificationManager.success('', props.t('common.form.notification.artistAdded'));
      props.history.goBack();
    },
  });

  return <Artist {...props} createArtist={createArtist} />;
};

export default compose(withTranslation(), withStyles(registerPageStyle))(ArtistView);
