import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Table from 'components/Table/Table.jsx';
import PropTypes from 'prop-types';

const style = {
  typo: {
    paddingLeft: '25%',
    marginBottom: '40px',
    position: 'relative',
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: '10px',
    color: '#c0c1c2',
    display: 'block',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '13px',
    left: '0',
    marginLeft: '20px',
    position: 'absolute',
    width: '260px',
  },
};

class Terms extends React.Component {
  render() {
    return (
      <Card>
        <CardBody>
          <p>
            Ekoh.online provides website features and other products and services to you when you
            visit or shop at Ekoh.online, use Ekoh products or services, use Ekoh applications for
            mobile, or use software provided by Ekoh in connection with any of the foregoing
            (collectively, "Ekoh Services"). When you purchase content on Ekoh, you are purchasing
            from the artist or company selling it. Ekoh provides a platform to make sure that
            customers get the correct product from the correct person when needed, and vice versa.
            Our role is to provide a fair business environment to all our users. Ekoh provides the
            Ekoh Services subject to the following conditions.
          </p>
          <h3>PRIVACY</h3>
          <p>
            Please review our Privacy Notice at the end of this document, which also governs your
            use of Ekoh Services, to understand our practices.
          </p>
          <h3>ELECTRONIC COMMUNICATIONS</h3>
          <p>
            When you use Ekoh Services, or send emails, text messages, and other communications from
            your desktop or mobile device to us, you may be communicating with us electronically.
            You consent to receive communications from us electronically, such as e-mails, texts,
            mobile push notices, or notices and messages on this site or through the other Ekoh
            Services, such as our Message Center, and you can retain copies of these communications
            for your records. You agree that all agreements, notices, disclosures, and other
            communications that we provide to you electronically satisfy any legal requirement that
            such communications be in writing.
          </p>
          <h3>COPYRIGHT</h3>
          <p>
            All content included in or made available through any Ekoh Service, such as text,
            graphics, logos, button icons, images, audio clips, digital downloads, data
            compilations, and software is the property of Ekoh or its content suppliers and
            protected by South Africa and international copyright laws. The compilation of all
            content included in or made available through any Ekoh Service is the exclusive property
            of Ekoh and protected by R.S.A and international copyright laws.
          </p>
          <h3>TRADEMARKS</h3>
          <p>
            In addition, graphics, logos, page headers, button icons, scripts, and service names
            included in or made available through any Ekoh Service are trademarks or trade dress of
            Ekoh in the Republic of South Africa. and other countries. Ekoh's trademarks and trade
            dress may not be used in connection with any product or service that is not Ekoh's, in
            any manner that is likely to cause confusion among customers, or in any manner that
            disparages or discredits Ekoh. All other trademarks not owned by Ekoh that appear in any
            Ekoh Service are the property of their respective owners, who may or may not be
            affiliated with, connected to, or sponsored by Ekoh.
          </p>
          <h3>LICENSE AND ACCESS</h3>
          <p>
            Subject to your compliance with these Conditions of Use and any Service Terms, and your
            payment of any applicable fees, Ekoh or its content providers grant you a limited,
            non-exclusive, non-transferable, non-sublicensable license to access and make personal
            and non-commercial use of the Ekoh Services. This license does not include any resale or
            commercial use of any Ekoh Service, or its contents; any collection and use of any
            product listings, descriptions, or prices; any derivative use of any Ekoh Service or its
            contents; any downloading, copying, or other use of account information for the benefit
            of any third party; or any use of data mining, robots, or similar data gathering and
            extraction tools. All rights not expressly granted to you in these Conditions of Use or
            any Service Terms are reserved and retained by Ekoh or its licensors, suppliers,
            publishers, rightsholders, or other content providers. No Ekoh Service, nor any part of
            any Ekoh Service, may be reproduced, duplicated, copied, sold, resold, visited, or
            otherwise exploited for any commercial purpose without express written consent of Ekoh.
            You may not frame or utilize framing techniques to enclose any trademark, logo, or other
            proprietary information (including images, text, page layout, or form) of Ekoh without
            express written consent. You may not use any meta tags or any other "hidden text"
            utilizing Ekoh's name or trademarks without the express written consent of Ekoh. You may
            not misuse the Ekoh Services. You may use the Ekoh Services only as permitted by law.
            The licenses granted by Ekoh terminate if you do not comply with these Conditions of Use
            or any Service Terms.
          </p>
          <h3>YOUR ACCOUNT</h3>
          <p>
            You may need your own Ekoh account to use certain Ekoh Services, and you may be required
            to be logged in to the account and have a valid payment method associated with it. If
            there is a problem charging your selected payment method, we may charge any other valid
            payment method associated with your account. Click here to manage your payment options.
            You are responsible for maintaining the confidentiality of your account and password and
            for restricting access to your account, and you agree to accept responsibility for all
            activities that occur under your account or password. Ekoh does sell products for
            children, but it sells them to adults, who can purchase with a credit card or other
            permitted payment method. If you are under 18, you may use the Ekoh Services only with
            involvement of a parent or guardian. Parents and guardians may create profiles for
            teenagers in their Ekoh Household. Ekoh reserves the right to refuse service, terminate
            accounts, terminate your rights to use Ekoh Services, remove or edit content, or cancel
            orders in its sole discretion.
          </p>
          <h3>PRICING, EARNINGS AND WITHDRAWALS</h3>
          <p>
            When you purchase content on Ekoh, you are purchasing from the artist or person selling
            it. Ekoh provides a gateway to make sure that you get the correct product from the
            correct person when you need it. Our role is to provide a fair business environment to
            all our users. The uploader owns all of the content and information they post on Ekoh.
            All payments on Ekoh shall be done using our payment gateway Flutterwave Rave . Ekoh
            reserves the right to add or remove any payment method at any time. No User's payment
            information shall be permanently stored on our database for security. Therefore on any
            cash in or cash out, we will use Flutterwave Rave and their database. The seller is
            responsible for any Taxes or transaction fees, and buyers must pay for Content without
            any reduction for Taxes or this fees. "Taxes" means any duties, customs fees, or taxes
            associated with the sale of Content, including any related penalties or interest. When a
            buyer purchases a product or service, they will be charged at the current price. Further
            changes in price won't apply to your purchase. Prices will be determined by the seller
            by selecting between 3 tiers (i.e. price points). The seller will get 70% of the
            proceeds of his/her sales (please note that sellers will be paid out in their local
            currency and any proceeds in a different currency will be converted to the sellers local
            currency using the spot exchange rate at the time a withdrawal is requested). Any sales
            transaction has a maturation period of 14 days after which 70% of the proceeds will be
            available for withdrawal by the seller. The minimum sales withdrawal amount depends on
            the sellers location (see below):
          </p>
          <Table
            tableHeaderColor='primary'
            tableHead={['Location (country)', 'Minimum withdrawal amount']}
            tableData={[
              ['South Africa', 'R360'],
              ['Cameroon', 'CFA14,500'],
              ['Rest of the world', 'US$30'],
            ]}
          />
          <b>*Please note that the seller will be getting 70% of the above stated amounts.</b>
          <h3>REVIEWS, COMMENTS, COMMUNICATIONS, AND OTHER CONTENT</h3>
          <p>
            You may post reviews, comments, photos, videos, and other content; send e-cards and
            other communications; and submit suggestions, ideas, comments, questions, or other
            information, so long as the content is not illegal, obscene, threatening, defamatory,
            invasive of privacy, infringing of intellectual property rights (including publicity
            rights), or otherwise injurious to third parties or objectionable, and does not consist
            of or contain software viruses, political campaigning, commercial solicitation, chain
            letters, mass mailings, or any form of "spam" or unsolicited commercial electronic
            messages. You may not use a false e-mail address, impersonate any person or entity, or
            otherwise mislead as to the origin of a card or other content. Ekoh reserves the right
            (but not the obligation) to remove or edit such content, but does not regularly review
            posted content. If you do post content or submit material, and unless we indicate
            otherwise, you grant Ekoh a nonexclusive, royalty-free, perpetual, irrevocable, and
            fully sublicensable right to use, reproduce, modify, adapt, publish, perform, translate,
            create derivative works from, distribute, and display such content throughout the world
            in any media. You grant Ekoh and sublicensees the right to use the name that you submit
            in connection with such content, if they choose. You represent and warrant that you own
            or otherwise control all of the rights to the content that you post; that the content is
            accurate; that use of the content you supply does not violate this policy and will not
            cause injury to any person or entity; and that you will indemnify Ekoh for all claims
            resulting from content you supply. Ekoh has the right but not the obligation to monitor
            and edit or remove any activity or content. Ekoh takes no responsibility and assumes no
            liability for any content posted by you or any third party.
          </p>
          <h3>INTELLECTUAL PROPERTY COMPLAINTS</h3>
          <p>
            Ekoh respects the intellectual property of others. If you believe that your intellectual
            property rights are being infringed, please follow the Notice and Procedure for Making
            Claims of Copyright Infringement.
          </p>
          <h3>APP PERMISSIONS</h3>
          <p>
            When you use apps created by Ekoh, you may grant certain permissions to us for your
            device. Most mobile devices provide you with information about these permissions.
          </p>
          <h3>OTHER BUSINESSES</h3>
          <p>
            Parties other than Ekoh operate accounts, provide services or software, or sell product
            lines through the Ekoh Services. In addition, we provide links to the sites of
            affiliated companies and certain other businesses. If you purchase any of the products
            or services offered by these businesses or individuals, you are purchasing directly from
            those third parties, not from Ekoh. We are not responsible for examining or evaluating,
            and we do not warrant, the offerings of any of these businesses or individuals
            (including the content of their Web sites). Ekoh does not assume any responsibility or
            liability for the actions, product, and content of all these and any other third
            parties. You should carefully review their privacy statements and other conditions of
            use.
          </p>
          <h3>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h3>
          <p>
            THE EKOH SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE)
            AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE EKOH
            SERVICES ARE PROVIDED BY EKOH ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE
            SPECIFIED IN WRITING. EKOH MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS
            OR IMPLIED, AS TO THE OPERATION OF THE EKOH SERVICES, OR THE INFORMATION, CONTENT,
            MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE
            AVAILABLE TO YOU THROUGH THE EKOH SERVICES, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU
            EXPRESSLY AGREE THAT YOUR USE OF THE EKOH SERVICES IS AT YOUR SOLE RISK. TO THE FULL
            EXTENT PERMISSIBLE BY LAW, EKOH DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
            BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
            PURPOSE. EKOH DOES NOT WARRANT THAT THE EKOH SERVICES, INFORMATION, CONTENT, MATERIALS,
            PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE
            TO YOU THROUGH THE EKOH SERVICES, EKOH'S SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM
            EKOH ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY
            LAW, EKOH WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY
            EKOH SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE)
            OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH ANY EKOH
            SERVICE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND
            CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.
          </p>
          <h3>DISPUTES</h3>
          <p>
            Any dispute or claim relating in any way to your use of any Ekoh Service, or to any
            products or services sold or distributed by Ekoh or through Ekoh.online will be resolved
            by binding arbitration, rather than in court, except that you may assert claims in small
            claims court if your claims qualify. The Federal Arbitration Act and federal arbitration
            law apply to this agreement. There is no judge or jury in arbitration, and court review
            of an arbitration award is limited. However, an arbitrator can award on an individual
            basis the same damages and relief as a court (including injunctive and declaratory
            relief or statutory damages), and must follow the terms of these Conditions of Use as a
            court would. We each agree that any dispute resolution proceedings will be conducted
            only on an individual basis and not in a class, consolidated or representative action.
            If for any reason a claim proceeds in court rather than in arbitration we each waive any
            right to a jury trial. We also both agree that you or we may bring suit in court to
            enjoin infringement or other misuse of intellectual property rights.
          </p>
          <h3>APPLICABLE LAW</h3>
          <p>
            By using any Ekoh Service, you agree that the Federal Arbitration Act, applicable
            federal law, and the laws of the Republic of South Africa, without regard to principles
            of conflict of laws, will govern these Conditions of Use and any dispute of any sort
            that might arise between you and Ekoh.
          </p>
          <h3>SITE POLICIES, MODIFICATION, AND SEVERABILITY</h3>
          <p>
            Please review our other policies, such as our privacy policy, posted on this site. These
            policies also govern your use of Ekoh Services. We reserve the right to make changes to
            our site, policies, Service Terms, and these Conditions of Use at any time. If any of
            these conditions shall be deemed invalid, void, or for any reason unenforceable, that
            condition shall be deemed severable and shall not affect the validity and enforceability
            of any remaining condition.
          </p>
          <p>
            OUR ADDRESS
            <br />
            Ekoh Media
            <br />
            Kloof Street
            <br />
            Cape Town
            <br />
            <br />
            www.ekoh.online
          </p>
          <h2>Privacy Notice</h2>
          <p>
            Ekoh.online knows that you care how information about you is used and shared, and we
            appreciate your trust that we will do so carefully and sensibly. This notice describes
            our privacy policy. By visiting Ekoh.online, you are accepting the practices described
            in this Privacy Notice.
          </p>
          <ul>
            <li>What Personal Information About Customers Does Ekoh.online Gather?</li>
            <li>What About Cookies?</li>
            <li>Does Ekoh.online Share the Information It Receives?</li>
            <li>How Secure Is Information About Me?</li>
            <li>What About Third-Party Advertisers and Links to Other Websites?</li>
            <li>Which Information Can I Access?</li>
            <li>What Choices Do I Have?</li>
            <li>Are Children Allowed to Use Ekoh.online?</li>
            <li>Conditions of Use, Notices, and Revisions</li>
            <li>Examples of Information Collected</li>
          </ul>
          <h3>What Personal Information About Customers Does Ekoh.online Gather?</h3>
          <p>
            The information we learn from customers helps us personalize and continually improve
            your Ekoh experience. Here are the types of information we gather.
          </p>
          <ul>
            <li>
              Information You Give Us: We receive and store any information you enter on our Web
              site or give us in any other way. You can choose not to provide certain information,
              but then you might not be able to take advantage of many of our features. We use the
              information that you provide for such purposes as responding to your requests,
              customizing future shopping for you, improving our stores, and communicating with you.
            </li>
            <li>
              Automatic Information: We receive and store certain types of information whenever you
              interact with us. For example, like many Web sites, we use "cookies," and we obtain
              certain types of information when your Web browser accesses Ekoh.online or
              advertisements and other content served by or on behalf of Ekoh.online on other Web
              sites.
            </li>
            <li>
              Mobile: When you download or use apps created by Ekoh or our subsidiaries, we may
              receive information about your location and your mobile device, including a unique
              identifier for your device. We may use this information to provide you with
              location-based services, such as advertising, search results, and other personalized
              content. Most mobile devices allow you to turn off location services.
            </li>
            <li>
              E-mail Communications: To help us make emails more useful and interesting, we often
              receive a confirmation when you open email from Ekoh.online if your computer supports
              such capabilities. We also compare our customer list to lists received from other
              companies, in an effort to avoid sending unnecessary messages to our customers. If you
              do not want to receive e-mail or other mail from us, please adjust your Customer
              Communication Preferences .
            </li>
            <li>
              Information from Other Sources: We might receive information about you from other
              sources and add it to our account information.
            </li>
          </ul>
          <p>What About Cookies?</p>
          <ul>
            <li>
              Cookies are unique identifiers that we transfer to your device to enable our systems
              to recognize your device and to provide features such as Recommended for You,
              personalized advertisements on other Websites (e.g., Ekoh Associates with content
              served by Ekoh.online and Web sites using Checkout by Rave payment service), and
              storage of items in your Shopping Cart between visits.
            </li>
            <li>
              The Help feature on most browsers will tell you how to prevent your browser from
              accepting new cookies, how to have the browser notify you when you receive a new
              cookie, or how to disable cookies altogether. Additionally, you can disable or delete
              similar data used by browser add-ons, such as Flash cookies, by changing the add-ons
              settings or visiting the Web site of its manufacturer. Because cookies allow you to
              take advantage of some of Ekoh.online's essential features, we recommend that you
              leave them turned on. For instance, if you block or otherwise reject our cookies, you
              will not be able to add items to your Shopping Cart, proceed to Checkout, or use any
              Ekoh.online products and services that require you to Sign in.
            </li>
          </ul>
          <h3>Does Ekoh.online Share the Information It Receives?</h3>
          <p>
            Information about our customers is an important part of our business, and we are not in
            the business of selling it to others. We share customer information only as described
            below and with subsidiaries Ekoh.online, Inc. controls that either are subject to this
            Privacy Notice or follow practices at least as protective as those described in this
            Privacy Notice.
          </p>
          <ul>
            <li>
              Affiliated Businesses We Do Not Control: We work closely with affiliated businesses.
              In some cases, such as Marketplace sellers, these businesses operate stores at
              Ekoh.online or sell offerings to you at Ekoh.online. In other cases, we operate
              stores, provide services, or sell product lines jointly with these businesses. You can
              tell when a third party is involved in your transactions, and we share customer
              information related to those transactions with that third party.
            </li>
            <li>
              Third-Party Service Providers: We employ other companies and individuals to perform
              functions on our behalf. Examples include fulfilling orders, delivering packages,
              sending postal mail and e-mail, removing repetitive information from customer lists,
              analyzing data, providing marketing assistance, providing search results and links
              (including paid listings and links), processing credit card payments, and providing
              customer service. They have access to personal information needed to perform their
              functions, but may not use it for other purposes.
            </li>
          </ul>
          <p>Links to privacy policy of third party service providers used by the app</p>
          <ul>
            <li>
              <a href='https://policies.google.com/privacy'>Google Play Services</a>
            </li>
            <li>
              <a href='https://firebase.google.com/policies/analytics'>
                Google Analytics for Firebase
              </a>
            </li>
            <li>
              <a href='https://firebase.google.com/support/privacy/'>Firebase Crashlytics</a>
            </li>
            <li>
              <a href='https://sentry.io/privacy/'>Sentry</a>
            </li>
          </ul>
          <ul>
            <li>
              Promotional Offers: Sometimes we send offers to selected groups of Ekoh.online
              customers on behalf of other businesses. When we do this, we do not give that business
              your name and address. If you do not want to receive such offers, please adjust your
              Customer Communication Preferences .
            </li>
            <li>
              Business Transfers: As we continue to develop our business, we might sell or buy
              stores, subsidiaries, or business units. In such transactions, customer information
              generally is one of the transferred business assets but remains subject to the
              promises made in any pre-existing Privacy Notice (unless, of course, the customer
              consents otherwise). Also, in the unlikely event that Ekoh.online, Inc., or
              substantially all of its assets are acquired, customer information will of course be
              one of the transferred assets.
            </li>
            <li>
              Protection of Ekoh.online and Others: We release account and other personal
              information when we believe release is appropriate to comply with the law; enforce or
              apply our Conditions of Use and other agreements; or protect the rights, property, or
              safety of Ekoh.online, our users, or others. This includes exchanging information with
              other companies and organizations for fraud protection and credit risk reduction.
              Obviously, however, this does not include selling, renting, sharing, or otherwise
              disclosing personally identifiable information from customers for commercial purposes
              in violation of the commitments set forth in this Privacy Notice.
            </li>
            <li>
              With Your Consent: Other than as set out above, you will receive notice when
              information about you might go to third parties, and you will have an opportunity to
              choose not to share the information.
            </li>
          </ul>
          <h3>How Secure Is Information About Me?</h3>
          <ul>
            <li>
              We work to protect the security of your information during transmission by using
              Secure Sockets Layer (SSL) software, which encrypts information you input.
            </li>
            <li>
              We reveal only the last four digits of your credit card numbers when confirming an
              order. Of course, we transmit the entire credit card number to the appropriate credit
              card company during order processing.
            </li>
            <li>
              It is important for you to protect against unauthorized access to your password and to
              your computer. Be sure to sign off when finished using a shared computer.
            </li>
          </ul>
          <p>
            What About Third-Party Advertisers and Links to Other Websites? Our site includes
            third-party advertising and links to other Websites. For more information about
            third-party advertising at Ekoh.online, including personalized or interest-based ads,
            please read our Interest-Based Ads policy.
          </p>
          <h3>Which Information Can I Access?</h3>
          <p>
            Ekoh.online gives you access to a broad range of information about your account and your
            interactions with Ekoh.online for the limited purpose of viewing and, in certain cases,
            updating that information.
            <br />
            What Choices Do I Have?
          </p>
          <ul>
            <li>
              As discussed above, you can always choose not to provide information, even though it
              might be needed to make a purchase or to take advantage of such Ekoh.online features
              as Your Profile, Customer Reviews.
            </li>
            <li>
              You can add or update certain information on pages such as those referenced in the
              Which Information Can I Access? section. When you update information, we usually keep
              a copy of the prior version for our records.
            </li>
            <li>
              If you do not want to receive e-mail or other mail from us, please adjust your
              Customer Communication Preferences . (If you do not want to receive Conditions of Use
              and other legal notices from us, such as this Privacy Notice, those notices will still
              govern your use of Ekoh.online, and it is your responsibility to review them for
              changes).
            </li>
            <li>
              If you do not want us to use personal information that we gather to allow third
              parties to personalize advertisements we display to you, please adjust your
              Advertising Preferences.
            </li>
            <li>
              The Help feature on most browsers will tell you how to prevent your browser from
              accepting new cookies, how to have the browser notify you when you receive a new
              cookie, or how to disable cookies altogether. Additionally, you can disable or delete
              similar data used by browser add-ons, such as Flash cookies, by changing the add-ons
              settings or visiting the Web site of its manufacturer. Because cookies allow you to
              take advantage of some of Ekoh.online's essential features, we recommend that you
              leave them turned on. For instance, if you block or otherwise reject our cookies, you
              will not be able to add items to your Shopping Cart, proceed to Checkout, or use any
              Ekoh.online products and services that require you to Sign in.
            </li>
          </ul>
          <h3>Are Children Allowed to Use Ekoh.online?</h3>
          <p>
            Ekoh.online does not sell products for purchase by children. We sell children's products
            for purchase by adults. If you are under 18, you may use Ekoh.online only with the
            involvement of a parent or guardian. We do not knowingly collect personal information
            from children under the age of 13 without the consent of the child's parent or guardian.
          </p>
          <h3>Conditions of Use, Notices, and Revisions</h3>
          <p>
            If you choose to visit Ekoh.online, your visit and any dispute over privacy is subject
            to this Notice and our Conditions of Use, including limitations on damages, resolution
            of disputes, and application of the law of the Republic of South Africa. If you have any
            concern about privacy at Ekoh.online, please contact us with a thorough description, and
            we will try to resolve it. Our business changes constantly, and our Privacy Notice and
            the Conditions of Use will change also. We may email periodic reminders of our notices
            and conditions, but you should check our Web site frequently to see recent changes.
            Unless stated otherwise, our current Privacy Notice applies to all information that we
            have about you and your account. We stand behind the promises we make, however, and will
            never materially change our policies and practices to make them less protective of
            customer information collected in the past without the consent of affected customers.
          </p>
          <h3>Examples of Information Collected</h3>
          <p>
            Information You Give Us You provide most such information when you search, buy, post,
            participate in a contest or questionnaire, or communicate with customer service. For
            example, you provide information when you search for a product; place an order through
            Ekoh.online or one of our third-party sellers; provide information in Your Account (and
            you might have more than one if you have used more than one email address when shopping
            with us) or Your Profile ; communicate with us by phone, e-mail, or otherwise; complete
            a questionnaire or a contest entry form; compile Wish Lists or other gift registries;
            participate in Discussion Boards or other community features; provide and rate Reviews;
            and employ Release Notification Alerts, such as Available to Order Notifications. As a
            result of those actions, you might supply us with such information as your name,
            address, and phone numbers; credit card information; people to whom purchases have been
            shipped, including addresses and phone number; e-mail addresses of your friends and
            other people; content of reviews and e-mails to us; personal description and photograph
            in Your Profile ; and financial information, including Social Security and driver's
            license numbers.
            <br />
            <br />
            Automatic Information
            <br />
            <br />
            Examples of the information we collect and analyze include the Internet protocol (IP)
            address used to connect your computer to the Internet; login; e-mail address; password;
            computer and connection information such as browser type, version, and time zone
            setting, browser plug-in types and versions, operating system, and platform; purchase
            history, which we sometimes aggregate with similar information from other customers to
            create features like Top Sellers ; the full Uniform Resource Locator (URL) clickstream
            to, through, and from our Web site, including date and time; cookie number; products you
            viewed or searched for; and the phone number you used to call our 800 number. We may
            also use browser data such as cookies, Flash cookies (also known as Flash Local Shared
            Objects), or similar data on certain parts of our Website for fraud prevention and other
            purposes. During some visits we may use software tools such as JavaScript to measure and
            collect session information, including page response times, download errors, length of
            visits to certain pages, page interaction information (such as scrolling, clicks, and
            mouse-overs), and methods used to browse away from the page. We may also collect
            technical information to help us identify your device for fraud prevention and
            diagnostic purposes.
            <br />
            <br />
            Mobile
            <br />
            <br />
            Most mobile devices provide users with the ability to disable location services. Most
            likely, these controls are located in the device's settings menu. If you have questions
            about how to disable your device's location services, we recommend you contact your
            mobile service carrier or your device manufacturer.
            <br />
            <br />
            Information from Other Sources
            <br />
            <br />
            Examples of information we receive from other sources include updated delivery and
            address information from our carriers or other third parties, which we use to correct
            our records and deliver your next purchase or communication more easily; account
            information, purchase or redemption information, and page-view information from some
            merchants with which we operate co-branded businesses or for which we provide technical,
            fulfillment, advertising, or other services; search term and search result information
            from some searches conducted through the Web search features; search results and links,
            including paid listings (such as Sponsored Links); and credit history information from
            credit bureaus, which we use to help prevent and detect fraud and to offer certain
            credit or financial services to some customers.
            <br />
            <br />
            Co-branded and Joint Offerings
            <br />
            <br />
            Examples of businesses with which we offer joint or co-branded products and other
            offerings include Flutterwave Rave etc.
            <br />
            <br />
            Information You Can Access
            <br />
            <br />
            Examples of information you can access easily at Ekoh.online include up-to-date
            information regarding recent orders; personally identifiable information (including
            name, e-mail, password, communications and personalized advertising preferences, address
            book); payment settings (including credit card information); e-mail notification
            settings; Recommendations (including Recommended for You and Improve Your
            Recommendations); Seller accounts; and Your Profile (including your product Reviews,
            Recommendations, and, personal profile)
          </p>
        </CardBody>
      </Card>
    );
  }
}

Terms.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Terms);
