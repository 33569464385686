import React from 'react';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';
import { useTranslation } from 'react-i18next';

import FreeReleaseContent from './FreeReleasesContent';
import PaidReleaseContent from './PaidReleasesContent';

const Gallery = () => {
  const [t] = useTranslation();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <NavPills
          color='warning'
          alignCenter
          tabs={[
            {
              tabButton: t('gallery.releases.free'),
              tabContent: <FreeReleaseContent />,
            },
            {
              tabButton: t('gallery.releases.paid'),
              tabContent: <PaidReleaseContent />,
            },
          ]}
        />
      </GridItem>
    </GridContainer>
  );
};

export default Gallery;
