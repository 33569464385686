import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Link from '@material-ui/core/Link';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

// core components
import AuthNavbar from 'components/Navbars/AuthNavbar.jsx';
import Footer from 'components/Footer/Footer.jsx';

import routes from 'routes/help.jsx';

import pagesStyle from 'assets/jss/material-dashboard-pro-react/layouts/helpStyle.jsx';

class Pages extends React.Component {
  componentDidMount() {
    document.body.style.overflow = 'unset';
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/help') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  getActiveRoute = (routes) => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <AuthNavbar brandText={this.getActiveRoute(routes)} {...rest} />
        <div className={classes.wrapper} ref='wrapper'>
          <div className={classes.fullPage}>
            <GridContainer className={classes.content}>
              <GridItem xs={9}>
                <Switch>
                  {this.getRoutes(routes)}
                  <Redirect from='/' to='/help/how-to-register' />
                </Switch>
              </GridItem>
              <GridItem xs={3}>
                <List>
                  {routes.map((r, i) => (
                    <ListItem key={i}>
                      <Link color='inherit' underline='always' href={r.layout + r.path} block>
                        {r.name}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </GridItem>
            </GridContainer>
            <Footer white />
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(pagesStyle)(Pages);
