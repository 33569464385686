import React from 'react';

// material-ui components
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import Check from '@material-ui/icons/Check';
// core components
import styles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import classNames from 'classnames';

class CustomCheckbox extends React.Component {
  render() {
    const { classes, id, onChange, formLabelProps, helpText, error, success } = this.props;

    var helpTextClasses = classNames({
      [classes.labelRootError]: error,
      [classes.labelRootSuccess]: success && !error,
    });

    return (
      <FormControl className={classes.checkboxAndRadio + ' ' + classes.checkboxAndRadioHorizontal}>
        <FormControlLabel
          {...formLabelProps}
          control={
            <Checkbox
              tabIndex={-1}
              onChange={onChange}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{ checked: classes.checked }}
            />
          }
          classes={{ label: classes.label }}
        />
        {helpText !== undefined ? (
          <FormHelperText id={id + '-text'} className={helpTextClasses}>
            {helpText}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}

export default withStyles(styles)(CustomCheckbox);
