import gql from 'graphql-tag';

const GET_NAVBAR_DATA = gql`
  query NavbarView {
    isLoggedIn
    shoppingCart {
      id
      gid
      name
      artists {
        id
        name
      }
      artwork(height: 80, width: 80)
      priceCategory {
        id
        tier
        value
      }
    }
  }
`;

const GET_NOTIFICATIONS = gql`
  query Notifications {
    notifications {
      id
      title
      message
      status
    }
  }
`;

const GET_USER_PROFILE_STATUS = gql`
  query UserProfileStatus {
    isProfileComplete
  }
`;

export { GET_NAVBAR_DATA, GET_NOTIFICATIONS, GET_USER_PROFILE_STATUS };
