import React from 'react';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Home from '@material-ui/icons/Home';
import Button from 'components/CustomButtons/Button.jsx';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    margin: 0,
  },
  button: {
    marginTop: 20,
  },
});

const PageNotFound = ({ classes, t }) => {
  return (
    <Paper className={classes.paper}>
      <div className={classes.container}>
        <Typography variant='h4'>{t('common.pageNotFound.text')}</Typography>
        <Button color='warning' aria-label='home' href='/' className={classes.button}>
          <Home />
          <Typography variant='subtitle1'>{t('common.pageNotFound.return')}</Typography>
        </Button>
      </div>
    </Paper>
  );
};

export default compose(withTranslation(), withStyles(styles, { withTheme: true }))(PageNotFound);
