/**
 * @version 4.10.1
 * @name react-jinke-music-player
 * @description Maybe the best beautiful HTML5 responsive player component for react :)
 * @author Jinke.Li <1359518268@qq.com>
 */

import React, { PureComponent, Fragment } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isMobile from 'is-mobile';
import Slider from 'rc-slider/lib/Slider';
import Switch from 'rc-switch';
import * as Sentry from '@sentry/browser';
import ReactPlayer from 'vendor/react-player';
import download from 'downloadjs';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import Draggable from 'react-draggable';

import FaHeadphones from 'react-icons/lib/fa/headphones';
import FaMinusSquareO from 'react-icons/lib/fa/minus-square-o';
import FaPlayCircle from 'react-icons/lib/fa/play-circle';
import FaPauseCircle from 'react-icons/lib/fa/pause-circle';
import LyricIcon from 'react-icons/lib/fa/angellist';
import Reload from 'react-icons/lib/fa/refresh';
import MdVolumeDown from 'react-icons/lib/md/volume-up';
import MdVolumeMute from 'react-icons/lib/md/volume-off';
import Download from 'react-icons/lib/fa/cloud-download';
import LoadIcon from 'react-icons/lib/fa/spinner';
import LoopIcon from 'react-icons/lib/md/repeat-one';
import RepeatIcon from 'react-icons/lib/md/repeat';
import ShufflePlayIcon from 'react-icons/lib/ti/arrow-shuffle';
import OrderPlayIcon from 'react-icons/lib/md/view-headline';
import PlayLists from 'react-icons/lib/md/queue-music';
import NextAudioIcon from 'react-icons/lib/md/skip-next';
import PrevAudioIcon from 'react-icons/lib/md/skip-previous';
import CloseIcon from 'react-icons/lib/md/close';
import DeleteIcon from 'react-icons/lib/fa/trash-o';

import playerStyle from 'assets/jss/material-dashboard-pro-react/components/playerStyle';

import { drawerWidth, drawerMiniWidth } from 'assets/jss/material-dashboard-pro-react.jsx';
import Lyric from './lyric';
import AudioPlayerMobile from './components/PlayerMobile';
import AudioListsPanel from './components/AudioListsPanel';
import { formatTime, createRandomNum, arrayEqual, uuId, isSafari } from './utils';

import 'rc-slider/assets/index.css';
import 'rc-switch/assets/index.css';

export const SPACE_BAR_KEYCODE = 32;
const IS_MOBILE = isMobile();

export const AnimatePlayIcon = () => (
  <FaPlayCircle className='react-jinke-music-player-play-icon' />
);
export const AnimatePauseIcon = () => (
  <FaPauseCircle className='react-jinke-music-player-pause-icon' />
);

export const Load = () => (
  <span className='loading group' key='loading'>
    <LoadIcon />
  </span>
);

export const PlayModel = ({ visible, value }) => (
  <div
    className={classNames('play-mode-title', {
      'play-mode-title-visible': visible,
    })}
    key='play-mode-title'
  >
    {value}
  </div>
);

//迷你模式进度条
export const CircleProcessBar = ({ progress = 0, r = 40 } = {}) => {
  const _progress = progress.toFixed(2);
  const perimeter = Math.PI * 2 * r;
  const strokeDasharray = `${~~(perimeter * _progress)} ${~~(perimeter * (1 - _progress))}`;
  return (
    <svg className='audio-circle-process-bar'>
      <circle
        cx={r}
        cy={r}
        r={r - 1}
        fill='none'
        className='stroke'
        strokeDasharray={strokeDasharray}
      />
      <circle cx={r} cy={r} r={r - 1} fill='none' className='bg' strokeDasharray='0 1000' />
    </svg>
  );
};

const sliderBaseOptions = {
  min: 0,
  step: 0.01,
  trackStyle: { backgroundColor: '#ff9800' },
  handleStyle: { backgroundColor: '#ff9800', border: '2px solid #fff' },
};

class ReactJkMusicPlayer extends PureComponent {
  initPlayId = ''; //初始播放id
  audioListRemoveAnimateTime = 350; // 列表删除动画时间(ms)
  NETWORK_STATE = {
    NETWORK_EMPTY: 0, //未初始化
    NETWORK_IDLE: 1, //未使用网络 304 缓存
    NETWORK_LOADING: 2, //浏览器正在下载数据
    NETWORK_NO_SOURCE: 3, //未找到资源
  };
  READY_SUCCESS_STATE = 4;
  state = {
    audioLists: [],
    playId: this.initPlayId, //播放id
    name: '', //当前歌曲名
    cover: '', //当前歌曲封面
    singer: '', //当前歌手
    musicSrc: '', //当前歌曲链
    lyric: '', // 当前歌词
    currentLyric: '',
    isMobile: IS_MOBILE,
    toggle: false,
    pause: true,
    playing: false,
    duration: 0,
    currentTime: 0,
    isLoop: false,
    isMute: false,
    soundValue: 100,
    isDrag: false, //是否支持拖拽
    currentX: 0,
    currentY: 0,
    moveX: 0,
    moveY: 0,
    isMove: false,
    loading: false,
    audioListsPanelVisible: false,
    playModelNameVisible: false,
    theme: this.darkThemeName,
    extendsContent: [], //自定义扩展功能按钮
    playMode: '', //当前播放模式
    currentAudioVolume: 0, //当前音量  静音后恢复到之前记录的音量
    initAnimate: false,
    isInitAutoplay: false,
    isInitRemember: false,
    loadProgress: 0,
    removeId: -1,
    isNeedMobileHack: IS_MOBILE,
    audioLyricVisible: false,
    isAudioListsChange: false,
    notAutoPlayUntilPlayClicked: false,
  };
  static defaultProps = {
    audioLists: [],
    theme: 'dark',
    mode: 'mini',
    playModeText: {
      order: 'order',
      orderLoop: 'orderLoop',
      singleLoop: 'singleLoop',
      shufflePlay: 'shufflePlay',
    },
    defaultPlayMode: 'order',
    defaultPosition: {
      left: 0,
      top: 0,
    },
    controllerTitle: <FaHeadphones />,
    panelTitle: 'PlayList',
    closeText: 'close',
    openText: 'open',
    notContentText: 'no music',
    checkedText: '',
    unCheckedText: '',
    once: false, //onAudioPlay 事件  是否只触发一次
    drag: true,
    toggleMode: true, //能换在迷你 和完整模式下 互相切换
    showMiniModeCover: true, //迷你模式下 是否显示封面图
    showDownload: true,
    showPlay: true,
    showReload: true,
    showPlayMode: true,
    showThemeSwitch: true,
    showLyric: false,
    playModeTipVisible: false, //手机端切换播放模式
    autoPlay: true,
    defaultVolume: 1,
    showProgressLoadBar: true, //音频预加载进度
    seeked: true,
    playModeShowTime: 600, //播放模式提示 显示时间,
    bounds: 'body', //mini 模式拖拽的可移动边界
    showMiniProcessBar: false, //是否在迷你模式 显示进度条
    loadAudioErrorPlayNext: true, // 加载音频失败时 是否尝试播放下一首
    preload: false, //是否在页面加载后立即加载音频
    glassBg: false, //是否是毛玻璃效果
    remember: false, //是否记住当前播放状态
    remove: true, //音乐是否可以删除
    defaultPlayIndex: 0, //默认播放索引
    emptyLyricPlaceholder: 'NO LYRIC',
    getContainer: () => document.body, // 播放器挂载的节点
    autoHiddenCover: false, // 当前播放歌曲没有封面时是否自动隐藏
    onBeforeAudioDownload: () => {}, // 下载前转换音频地址等
    spaceBar: false, // 是否可以通过空格键 控制播放暂停
    showDestroy: false,
  };
  static propTypes = {
    audioLists: PropTypes.array.isRequired,
    theme: PropTypes.oneOf(['dark', 'light']),
    mode: PropTypes.oneOf(['mini', 'full']),
    defaultPlayMode: PropTypes.oneOf(['order', 'orderLoop', 'singleLoop', 'shufflePlay']),
    drag: PropTypes.bool,
    seeked: PropTypes.bool,
    autoPlay: PropTypes.bool,
    clearPriorAudioLists: PropTypes.bool,
    autoPlayInitLoadPlayList: PropTypes.bool,
    playModeText: PropTypes.object,
    panelTitle: PropTypes.string,
    closeText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    openText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    notContentText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    controllerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultPosition: PropTypes.shape({
      top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    onAudioPlay: PropTypes.func,
    onAudioPause: PropTypes.func,
    onAudioEnded: PropTypes.func,
    onAudioAbort: PropTypes.func,
    onAudioVolumeChange: PropTypes.func,
    onAudioLoadError: PropTypes.func,
    onAudioProgress: PropTypes.func,
    onAudioSeeked: PropTypes.func,
    onAudioDownload: PropTypes.func,
    onAudioReload: PropTypes.func,
    onThemeChange: PropTypes.func,
    onAudioListsChange: PropTypes.func,
    onPlayModeChange: PropTypes.func,
    onModeChange: PropTypes.func,
    onAudioListsPanelChange: PropTypes.func,
    onAudioPlayTrackChange: PropTypes.func,
    onAudioListsDragEnd: PropTypes.func,
    onAudioLyricChange: PropTypes.func,
    showDownload: PropTypes.bool,
    showPlay: PropTypes.bool,
    showReload: PropTypes.bool,
    showPlayMode: PropTypes.bool,
    showThemeSwitch: PropTypes.bool,
    showMiniModeCover: PropTypes.bool,
    toggleMode: PropTypes.bool,
    once: PropTypes.bool,
    extendsContent: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
      PropTypes.object,
      PropTypes.node,
      PropTypes.element,
      PropTypes.string,
    ]),
    checkedText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    unCheckedText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    defaultVolume: PropTypes.number,
    playModeShowTime: PropTypes.number,
    bounds: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    showMiniProcessBar: PropTypes.bool,
    loadAudioErrorPlayNext: PropTypes.bool,
    preload: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['auto', 'metadata', 'none'])]),
    glassBg: PropTypes.bool,
    remember: PropTypes.bool,
    remove: PropTypes.bool,
    defaultPlayIndex: PropTypes.number,
    playIndex: PropTypes.number,
    lyricClassName: PropTypes.string,
    emptyLyricPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    showLyric: PropTypes.bool,
    getContainer: PropTypes.func,
    getAudioInstance: PropTypes.func,
    onBeforeAudioDownload: PropTypes.func,
    autoHiddenCover: PropTypes.bool,
    spaceBar: PropTypes.bool,
    showDestroy: PropTypes.bool,
    onBeforeDestroy: PropTypes.func,
    onDestroyed: PropTypes.func,
    customDownloader: PropTypes.func,
    audioTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  };

  constructor(props) {
    super(props);
    this.audio = null; //当前播放器
    this.lightThemeName = 'light';
    this.darkThemeName = 'dark';
    //模式配置
    this.toggleModeName = {
      full: 'full',
      mini: 'mini',
    };
    this.targetId = 'music-player-controller';
    this.openPanelPeriphery = 1; //移动差值 在 这之间 认为是点击打开panel
    this.x = 0;
    this.y = 0;
    this.isDrag = false;

    const {
      playModeText: { order, orderLoop, singleLoop, shufflePlay },
    } = props;
    //播放模式配置
    this.PLAY_MODE = {
      order: {
        key: 'order',
        value: order,
      },
      orderLoop: {
        key: 'orderLoop',
        value: orderLoop,
      },
      singleLoop: {
        key: 'singleLoop',
        value: singleLoop,
      },
      shufflePlay: {
        key: 'shufflePlay',
        value: shufflePlay,
      },
    };
    this._PLAY_MODE_ = _.values(this.PLAY_MODE);
    this._PLAY_MODE_LENGTH_ = this._PLAY_MODE_.length;
  }

  render() {
    const {
      className,
      controllerTitle,
      closeText,
      openText,
      notContentText,
      drag,
      style,
      showDownload,
      showPlay,
      showReload,
      showPlayMode,
      showThemeSwitch,
      panelTitle,
      checkedText,
      unCheckedText,
      toggleMode,
      showMiniModeCover,
      extendsContent,
      defaultPlayMode,
      seeked,
      showProgressLoadBar,
      bounds,
      defaultPosition,
      showMiniProcessBar,
      glassBg,
      remove,
      lyricClassName,
      showLyric,
      emptyLyricPlaceholder,
      getContainer,
      autoHiddenCover,
      showDestroy,
      miniActive,
    } = this.props;

    const {
      toggle,
      playing,
      duration,
      currentTime,
      isMute,
      soundValue,
      moveX,
      moveY,
      loading,
      audioListsPanelVisible,
      pause,
      theme,
      name,
      cover,
      singer,
      musicSrc,
      playId,
      isMobile,
      playMode,
      playModeTipVisible,
      playModelNameVisible,
      initAnimate,
      loadProgress,
      audioLists,
      removeId,
      currentLyric,
      audioLyricVisible,
      currentAudioVolume,
    } = this.state;

    const commonStyleProps = {
      transitionProperty: 'left, width',
      transitionDuration: '.35s, .35s',
      transitionTimingFunction: 'ease, ease',
    };

    const panelLeft = miniActive ? drawerMiniWidth : drawerWidth;

    const panelStyle = {
      ...commonStyleProps,
      left: panelLeft,
      width: 'calc(100% - ' + panelLeft + 'px)',
    };

    const panelToggleAnimate = initAnimate
      ? { show: audioListsPanelVisible, hide: !audioListsPanelVisible }
      : { show: audioListsPanelVisible };

    const _playMode_ = this.PLAY_MODE[playMode || defaultPlayMode];

    const currentPlayMode = _playMode_['key'];
    const currentPlayModeName = _playMode_['value'];

    const isShowMiniModeCover =
      (showMiniModeCover && !autoHiddenCover) || (autoHiddenCover && cover)
        ? {
            style: {
              backgroundImage: `url(${cover})`,
            },
          }
        : {};

    const _currentTime = formatTime(currentTime);
    const _duration = formatTime(duration);

    const progressHandler = seeked
      ? {
          onChange: this.onHandleProgress,
          onAfterChange: this.onAudioSeeked,
        }
      : {};

    //进度条
    const ProgressBar = (
      <Slider
        max={Math.ceil(duration)}
        defaultValue={0}
        value={Math.ceil(currentTime)}
        {...progressHandler}
        {...sliderBaseOptions}
      />
    );

    //下载按钮
    const DownloadComponent = showDownload && (
      <span
        className='group audio-download'
        {...{ [IS_MOBILE ? 'onTouchStart' : 'onClick']: this.onAudioDownload }}
      >
        <Download />
      </span>
    );

    //主题开关
    const ThemeSwitchComponent = showThemeSwitch && (
      <span className='group theme-switch'>
        <Switch
          className='theme-switch-container'
          onChange={this.themeChange}
          checkedChildren={checkedText}
          unCheckedChildren={unCheckedText}
          checked={theme === this.lightThemeName}
        />
      </span>
    );

    //重放
    const ReloadComponent = showReload && (
      <span
        className='group reload-btn'
        {...(IS_MOBILE ? { onTouchStart: this.onAudioReload } : { onClick: this.onAudioReload })}
        key='reload-btn'
        title='Reload'
      >
        <Reload />
      </span>
    );

    //歌词
    const LyricComponent = showLyric && (
      <span
        className={classNames('group lyric-btn', {
          'lyric-btn-active': audioLyricVisible,
        })}
        {...(IS_MOBILE
          ? { onTouchStart: this.toggleAudioLyric }
          : { onClick: this.toggleAudioLyric })}
        key='lyric-btn'
        title='toggle lyric'
      >
        <LyricIcon />
      </span>
    );

    //播放模式
    const PlayModeComponent = showPlayMode ? (
      <span
        className={classNames('group loop-btn')}
        {...(IS_MOBILE ? { onTouchStart: this.togglePlayMode } : { onClick: this.togglePlayMode })}
        key='play-mode-btn'
        title={this.PLAY_MODE[currentPlayMode]['value']}
      >
        {this.renderPlayModeIcon(currentPlayMode)}
      </span>
    ) : undefined;

    const miniProcessBarR = isMobile ? 30 : 40;

    const DestroyComponent = showDestroy && (
      <span
        title='destroy'
        className='group destroy-btn'
        ref={(node) => (this.destroyBtn = node)}
        {...(!drag || toggle
          ? { [IS_MOBILE ? 'onTouchStart' : 'onClick']: this.onDestroyPlayer }
          : null)}
      >
        <CloseIcon />
      </span>
    );

    const AudioController = (
      <div
        className={classNames('react-jinke-music-player')}
        key='react-jinke-music-player'
        style={{
          ...defaultPosition,
          ...commonStyleProps,
          left: panelLeft,
        }}
      >
        <div className={classNames('music-player')} key='music-player'>
          {showMiniProcessBar ? (
            <CircleProcessBar progress={currentTime / duration} r={miniProcessBarR} />
          ) : undefined}
          <div
            key='controller'
            id={this.targetId}
            className={classNames('scale', 'music-player-controller', {
              'music-player-playing': this.state.playing,
            })}
            {...isShowMiniModeCover}
          >
            {loading ? (
              <Load />
            ) : (
              <Fragment>
                <span className='controller-title' key='controller-title'>
                  {controllerTitle}
                </span>
                <div key='setting' className='music-player-controller-setting'>
                  {toggle ? closeText : openText}
                </div>
              </Fragment>
            )}
          </div>
          {DestroyComponent}
        </div>
      </div>
    );

    const container = getContainer() || document.body;
    const audioTitle = this.getAudioTitle();

    return createPortal(
      <div
        className={classNames(
          'react-jinke-music-player-main',
          {
            'light-theme': theme === this.lightThemeName,
            'dark-theme': theme === this.darkThemeName,
          },
          className,
        )}
        style={style}
        ref={(player) => (this.player = player)}
      >
        {toggle && isMobile ? (
          <AudioPlayerMobile
            playing={playing}
            loading={loading}
            pause={pause}
            name={name}
            singer={singer}
            cover={cover}
            themeSwitch={ThemeSwitchComponent}
            duration={_duration}
            currentTime={_currentTime}
            progressBar={ProgressBar}
            onPlay={this.onTogglePlay}
            currentPlayModeName={this.PLAY_MODE[currentPlayMode]['value']}
            playMode={PlayModeComponent}
            audioNextPlay={this.audioNextPlay}
            audioPrevPlay={this.audioPrevPlay}
            playListsIcon={<PlayLists />}
            reloadIcon={ReloadComponent}
            downloadIcon={DownloadComponent}
            nextAudioIcon={<NextAudioIcon />}
            prevAudioIcon={<PrevAudioIcon />}
            playIcon={<AnimatePlayIcon />}
            pauseIcon={<AnimatePauseIcon />}
            closeIcon={<FaMinusSquareO />}
            loadingIcon={<Load />}
            playModeTipVisible={playModeTipVisible}
            openAudioListsPanel={this.openAudioListsPanel}
            onClose={this.onHidePanel}
            extendsContent={extendsContent}
            glassBg={glassBg}
            LyricIcon={LyricComponent}
            autoHiddenCover={autoHiddenCover}
          />
        ) : undefined}

        {toggle ? undefined : drag ? (
          <Draggable
            bounds={bounds}
            position={{ x: moveX, y: moveY }}
            onDrag={this.controllerMouseMove}
            onStop={this.controllerMouseUp}
            onStart={this.controllerMouseMove}
          >
            {AudioController}
          </Draggable>
        ) : (
          <Fragment>{AudioController}</Fragment>
        )}
        {toggle ? (
          isMobile ? undefined : (
            <div
              key='panel'
              className={classNames('music-player-panel', 'translate', {
                'glass-bg': glassBg,
              })}
              style={panelStyle}
            >
              <section className='panel-content' key='panel-content'>
                {(!autoHiddenCover || (autoHiddenCover && cover)) && (
                  <div
                    className={classNames('img-content', 'img-rotate', {
                      'img-rotate-pause': pause || !playing || !cover,
                    })}
                    style={{ backgroundImage: `url(${cover})` }}
                    key='img-content'
                  />
                )}
                <div className='progress-bar-content' key='progress-bar-content'>
                  <span className='audio-title'>{audioTitle}</span>
                  <section className='audio-main'>
                    <span key='current-time' className='current-time'>
                      {loading ? '--' : _currentTime}
                    </span>
                    <div className='progress-bar' key='progress-bar'>
                      {showProgressLoadBar ? (
                        <div
                          className='progress-load-bar'
                          key='progress-load-bar'
                          style={{ width: `${Math.min(loadProgress, 100)}%` }}
                        />
                      ) : undefined}

                      {ProgressBar}
                    </div>
                    <span key='duration' className='duration'>
                      {loading ? '--' : _duration}
                    </span>
                  </section>
                </div>
                <div className='player-content' key='player-content'>
                  {/*播放按钮*/}
                  {loading ? (
                    <Load />
                  ) : showPlay ? (
                    <span className='group'>
                      <span
                        className='group prev-audio'
                        title='Previous track'
                        {...(IS_MOBILE
                          ? { onTouchStart: this.audioPrevPlay }
                          : { onClick: this.audioPrevPlay })}
                      >
                        <PrevAudioIcon />
                      </span>
                      <span
                        className='group play-btn'
                        key='play-btn'
                        ref={(node) => (this.playBtn = node)}
                        {...(IS_MOBILE
                          ? { onTouchStart: this.onTogglePlay }
                          : { onClick: this.onTogglePlay })}
                        title={playing ? 'Click to pause' : 'Click to play'}
                      >
                        {playing ? (
                          <span>
                            <AnimatePauseIcon />
                          </span>
                        ) : (
                          <span>
                            <AnimatePlayIcon />
                          </span>
                        )}
                      </span>
                      <span
                        className='group next-audio'
                        title='Next track'
                        {...(IS_MOBILE
                          ? { onTouchStart: this.audioNextPlay }
                          : { onClick: this.audioNextPlay })}
                      >
                        <NextAudioIcon />
                      </span>
                    </span>
                  ) : undefined}

                  {/*重播*/}
                  {ReloadComponent}
                  {/*下载歌曲*/}
                  {DownloadComponent}
                  {/* 主题选择 */}
                  {ThemeSwitchComponent}

                  {/* 自定义扩展按钮 */}
                  {extendsContent || null}

                  {/*音量控制*/}
                  <span className='group play-sounds' key='play-sound' title='Volume'>
                    {isMute ? (
                      <span
                        className='sounds-icon'
                        {...(IS_MOBILE
                          ? { onTouchStart: this.onSound }
                          : { onClick: this.onSound })}
                      >
                        <MdVolumeMute />
                      </span>
                    ) : (
                      <span
                        className='sounds-icon'
                        {...(IS_MOBILE ? { onTouchStart: this.onMute } : { onClick: this.onMute })}
                      >
                        <MdVolumeDown />
                      </span>
                    )}
                    <Slider
                      max={1}
                      value={soundValue}
                      onChange={this.audioSoundChange}
                      className='sound-operation'
                      {...sliderBaseOptions}
                    />
                  </span>

                  {/*播放模式*/}
                  {PlayModeComponent}

                  {/*歌词按钮*/}
                  {LyricComponent}

                  {/*播放列表按钮*/}
                  <span
                    className='group audio-lists-btn'
                    key='audio-lists-btn'
                    title='play lists'
                    {...(IS_MOBILE
                      ? { onTouchStart: this.openAudioListsPanel }
                      : { onClick: this.openAudioListsPanel })}
                  >
                    <span className='audio-lists-icon'>
                      <PlayLists />
                    </span>
                    <span className='audio-lists-num'>{audioLists.length}</span>
                  </span>

                  {/*收起面板*/}
                  {toggleMode ? (
                    <span
                      className='group hide-panel'
                      title='toggle mode'
                      {...(IS_MOBILE
                        ? { onTouchStart: this.onHidePanel }
                        : { onClick: this.onHidePanel })}
                    >
                      <FaMinusSquareO />
                    </span>
                  ) : undefined}

                  {/*销毁播放器*/}
                  {DestroyComponent}
                </div>
              </section>
              {/* 播放模式提示框 */}
              <PlayModel visible={playModelNameVisible} value={currentPlayModeName} />
            </div>
          )
        ) : undefined}
        {/* 播放列表面板 */}
        <AudioListsPanel
          playId={playId}
          pause={pause}
          loading={loading ? <Load /> : undefined}
          visible={audioListsPanelVisible}
          audioLists={audioLists}
          notContentText={notContentText}
          onPlay={this.audioListsPlay}
          onCancel={this.closeAudioListsPanel}
          playIcon={<AnimatePlayIcon />}
          pauseIcon={<AnimatePauseIcon />}
          closeIcon={<CloseIcon />}
          panelTitle={panelTitle}
          isMobile={isMobile}
          panelToggleAnimate={panelToggleAnimate}
          glassBg={glassBg}
          cover={cover}
          remove={remove}
          deleteIcon={<DeleteIcon />}
          onDelete={this.deleteAudioLists}
          removeId={removeId}
          audioListsDragEnd={this.audioListsDragEnd}
        />
        {/* 歌词 */}
        {audioLyricVisible && (
          <Draggable>
            <div className={classNames('music-player-lyric', lyricClassName)}>
              {currentLyric || emptyLyricPlaceholder}
            </div>
          </Draggable>
        )}
        <ReactPlayer
          className='music-player-audio'
          url={musicSrc}
          ref={(node) => (this.audio = node)}
          volume={currentAudioVolume}
          onReady={this.canPlay}
          onPlay={this.onAudioPlay}
          onBuffer={this.load}
          onPause={this.onPauseAudio}
          onSeek={this.onAudioSeeked}
          onEnded={this.audioEnd}
          onError={this.onAudioLoadError}
          playing={playing}
          muted={isMute}
          // onStart={this.onStart}

          // playbackRate={playbackRate}
          onProgress={this.audioTimeUpdate}
          // onDuration={this.handleDuration}
          config={{
            forceAudio: true,
            file: {
              hlsVersion: '0.14.0-beta.1',
              hlsOptions: {
                fragLoadingTimeOut: 20000,
                fragLoadingMaxRetry: 6,
                fragLoadingRetryDelay: 1000,
                fragLoadingMaxRetryTimeout: 64000,
                startFragPrefetch: true,

                manifestLoadingTimeOut: 5000,
                manifestLoadingMaxRetry: 6,
                manifestLoadingRetryDelay: 1000,
                manifestLoadingMaxRetryTimeout: 64000,

                // debug: true,
                xhrSetup: (xhr, url) => {
                  const { creds } = this.state;
                  xhr.setRequestHeader(
                    'Access-Control-Allow-Headers',
                    'Content-Type, Accept, X-Requested-With',
                  );
                  xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
                  url = url + '?' + creds;
                  xhr.open('GET', url, true);
                },
              },
            },
          }}
        />
      </div>,
      container,
    );
  }

  getAudioTitle = () => {
    const { audioTitle } = this.props;
    const { name, singer } = this.state;
    if (typeof audioTitle === 'function' && this.audio) {
      return audioTitle(this.getBaseAudioInfo());
    }
    return audioTitle || `${name} ${singer ? `- ${singer}` : ''}`;
  };

  toggleAudioLyric = () => {
    this.setState({
      audioLyricVisible: !this.state.audioLyricVisible,
    });
  };
  //播放模式切换
  togglePlayMode = () => {
    let index = this._PLAY_MODE_.findIndex(({ key }) => key === this.state.playMode);
    const playMode =
      index === this._PLAY_MODE_LENGTH_ - 1
        ? this._PLAY_MODE_[0]['key']
        : this._PLAY_MODE_[++index]['key'];
    this.setState({
      playMode,
      playModelNameVisible: true,
      playModeTipVisible: true,
    });
    this.props.onPlayModeChange && this.props.onPlayModeChange(this.PLAY_MODE[playMode]);

    clearTimeout(this.playModelTimer);
    this.playModelTimer = setTimeout(() => {
      this.setState({ playModelNameVisible: false, playModeTipVisible: false });
    }, this.props.playModeShowTime);
  };
  //渲染播放模式 对应按钮
  renderPlayModeIcon = (playMode) => {
    let IconNode = '';
    const animateName = 'react-jinke-music-player-mode-icon';
    switch (playMode) {
      case this.PLAY_MODE['order']['key']:
        IconNode = <OrderPlayIcon className={animateName} />;
        break;
      case this.PLAY_MODE['orderLoop']['key']:
        IconNode = <RepeatIcon className={animateName} />;
        break;
      case this.PLAY_MODE['singleLoop']['key']:
        IconNode = <LoopIcon className={animateName} />;
        break;
      case this.PLAY_MODE['shufflePlay']['key']:
        IconNode = <ShufflePlayIcon className={animateName} />;
        break;
      default:
        IconNode = <OrderPlayIcon className={animateName} />;
    }
    return IconNode;
  };
  /**
   * 音乐列表面板选择歌曲
   * 上一首 下一首
   * 音乐结束
   * 通用方法
   * @tip: ignore 如果 为 true playId相同则不暂停 可是重新播放 适用于 随机播放 重新播放等逻辑
   */
  audioListsPlay = (playId, ignore = false, state = this.state) => {
    const { playId: currentPlayId, pause, playing, audioLists } = state;
    if (Array.isArray(audioLists) && audioLists.length === 0) {
      return console.warn('Your playlist has no songs. and cannot play !');
    }
    //如果点击当前项 就暂停 或者播放
    if (playId === currentPlayId && !ignore) {
      this.setState({ pause: !pause, playing: !playing });
      return pause ? this.audio.getInternalPlayer().play() : this._pauseAudio();
      // return
    }

    if (playId === currentPlayId && ignore) {
      return;
    }

    const {
      name,
      cover,
      musicSrc,
      singer,
      lyric = '',
      gid = '',
      releaseGid = '',
    } = audioLists.find((audio) => audio.id === playId);

    const loadAudio = (musicSrc) => {
      this.setState(
        {
          name,
          cover,
          musicSrc,
          singer,
          playId,
          lyric,
          gid,
          releaseGid,
          currentTime: 0,
          duration: 0,
          playing: false,
          loading: true,
          loadProgress: 0,
        },
        () => {
          this.initLyricParser();
          this.audio.getInternalPlayer().load();
          // this.setState({playing: true});
        },
      );
    };
    this.props.onAudioPlay && this.props.onAudioPlay(this.getBaseAudioInfo());
    this.props.onAudioPlayTrackChange &&
      this.props.onAudioPlayTrackChange(playId, audioLists, this.getBaseAudioInfo());

    switch (typeof musicSrc) {
      case 'function':
        musicSrc().then((originMusicSrc) => {
          const { path, creds } = originMusicSrc;
          loadAudio(path);
          this.setState({ creds });
        }, this.onAudioLoadError);
        break;
      default:
        loadAudio(musicSrc);
    }
  };

  resetAudioStatus = () => {
    if (this.audio.getInternalPlayer()) {
      this.audio.getInternalPlayer().pause();
    }
    this.initPlayInfo([]);
    this.setState({
      currentTime: 0,
      duration: 0,
      loading: false,
      playing: false,
      pause: true,
      currentLyric: '',
    });
  };
  deleteAudioLists = (audioId) => (e) => {
    e.stopPropagation();
    //如果不 传 id  删除全部
    const { audioLists, playId } = this.state;
    if (audioLists.length < 1) {
      return;
    }
    this.lyric && this.lyric.stop();
    if (!audioId) {
      this.props.onAudioListsChange && this.props.onAudioListsChange('', [], {});
      return this.resetAudioStatus();
    }
    const newAudioLists = [...audioLists].filter((audio) => audio.id !== audioId);
    //触发删除动画,等动画结束 删除列表
    this.setState({ removeId: audioId });
    setTimeout(() => {
      this.setState(
        {
          audioLists: newAudioLists,
          removeId: -1,
        },
        () => {
          if (!newAudioLists.length) {
            return this.resetAudioStatus();
          }
          // 如果删除的是当前正在播放的 顺延下一首播放
          if (audioId === playId) {
            this.handlePlay(this.PLAY_MODE['orderLoop']['key']);
          }
        },
      );
    }, this.audioListRemoveAnimateTime);

    this.props.onAudioListsChange &&
      this.props.onAudioListsChange(playId, newAudioLists, this.getBaseAudioInfo());
  };
  openAudioListsPanel = () => {
    this.setState(({ audioListsPanelVisible }) => ({
      initAnimate: true,
      audioListsPanelVisible: !audioListsPanelVisible,
    }));
    this.props.onAudioListsPanelChange &&
      this.props.onAudioListsPanelChange(!this.state.audioListsPanelVisible);
  };
  closeAudioListsPanel = (e) => {
    e.stopPropagation();
    this.setState({ audioListsPanelVisible: false });
    this.props.onAudioListsPanelChange && this.props.onAudioListsPanelChange(false);
  };
  themeChange = (isLight) => {
    const theme = isLight ? this.lightThemeName : this.darkThemeName;
    this.setState({
      theme,
    });
    this.props.onThemeChange && this.props.onThemeChange(theme);
  };
  onAudioDownload = () => {
    const { musicSrc } = this.state;
    if (this.state.musicSrc) {
      const { customDownloader } = this.props;
      const baseAudioInfo = this.getBaseAudioInfo();
      const onBeforeAudioDownload = this.props.onBeforeAudioDownload(baseAudioInfo);
      let transformedDownloadAudioInfo = {};
      if (onBeforeAudioDownload && onBeforeAudioDownload.then) {
        onBeforeAudioDownload.then((info) => {
          const { src, filename, mimeType } = info;
          transformedDownloadAudioInfo = info;
          if (customDownloader) {
            customDownloader(info);
          } else {
            download(src, filename, mimeType);
          }
        });
      } else {
        customDownloader ? customDownloader({ src: musicSrc }) : download(musicSrc);
      }
      this.props.onAudioDownload &&
        this.props.onAudioDownload(baseAudioInfo, transformedDownloadAudioInfo);
    }
  };
  controllerMouseMove = (e, { deltaX, deltaY }) => {
    const isMove =
      Math.abs(deltaX) >= this.openPanelPeriphery || Math.abs(deltaY) >= this.openPanelPeriphery;
    this.setState({
      isMove,
    });
  };
  controllerMouseUp = (e, { x, y }) => {
    if (this.props.showDestroy && this.destroyBtn && this.destroyBtn.contains(e.target)) {
      this.onDestroyPlayer();
      return;
    }
    if (!this.state.isMove) {
      if (this.state.isNeedMobileHack) {
        this.loadAndPlayAudio();
        this.setState({ isNeedMobileHack: false });
      }
      this.openPanel();
    }
    this.setState({ moveX: x, moveY: y });
    return false;
  };
  onHandleProgress = (value) => {
    // this.audio.currentTime = value
    this.audio.seekTo(value);
  };
  onSound = () => {
    this.setState({
      isMute: false,
    });
    this.setAudioVolume(this.state.currentAudioVolume);
  };
  setAudioVolume = (value) => {
    // this.audio.volume = value
    this.setState({
      currentAudioVolume: value,
      soundValue: value,
    });
  };
  stopAll = (target) => {
    target.stopPropagation();
    target.preventDefault();
  };
  getBoundingClientRect = (ele) => {
    const { left, top } = ele.getBoundingClientRect();
    return {
      left,
      top,
    };
  };
  //循环播放
  audioLoop = () => {
    this.setState(({ isLoop }) => {
      return {
        isLoop: !isLoop,
      };
    });
  };
  //重新播放
  onAudioReload = () => {
    this.handlePlay(this.PLAY_MODE['singleLoop']['key']);
    this.props.onAudioReload && this.props.onAudioReload(this.getBaseAudioInfo());
  };
  openPanel = () => {
    if (this.props.toggleMode) {
      this.setState({ toggle: true });
      this.props.onModeChange && this.props.onModeChange(this.toggleModeName.full);
    }
  };
  //收起播放器
  onHidePanel = () => {
    this.setState({ toggle: false, audioListsPanelVisible: false });
    this.props.onModeChange && this.props.onModeChange(this.toggleModeName.mini);
  };

  onDestroyPlayer = () => {
    if (this.props.onBeforeDestroy) {
      const onBeforeDestroy = Promise.resolve(
        this.props.onBeforeDestroy(
          this.state.playId,
          this.state.audioLists,
          this.getBaseAudioInfo(),
        ),
      );

      if (onBeforeDestroy && onBeforeDestroy.then) {
        onBeforeDestroy
          .then(() => {
            this._onDestroyPlayer();
          })
          // ignore unhandledrejection handler
          .catch(() => {});
      }
      return;
    }
    this._onDestroyPlayer();
  };

  _onDestroyPlayer = () => {
    this.componentWillUnmount();
    this.player.remove();
  };

  _onDestroyed = () => {
    if (this.props.onDestroyed) {
      this.props.onDestroyed(this.state.playId, this.state.audioLists, this.getBaseAudioInfo());
    }
  };

  //返回给使用者的 音乐信息
  getBaseAudioInfo() {
    const { playId, cover, name, musicSrc, soundValue, lyric, audioLists, gid, releaseGid } =
      this.state;

    // const {
    //   currentTime,
    //   duration,
    //   muted,
    //   networkState,
    //   readyState,
    //   played,
    //   paused,
    //   ended,
    //   startDate,
    // } = this.audio

    const currentTime = this.audio.getCurrentTime();
    const duration = this.audio.getDuration();

    const currentPlayIndex = audioLists.findIndex((audio) => audio.id === playId);
    const currentAudioListInfo = audioLists[currentPlayIndex] || {};

    return {
      ...currentAudioListInfo,
      cover,
      name,
      musicSrc,
      volume: soundValue,
      currentTime,
      duration,
      // muted,
      // networkState,
      // readyState,
      // played,
      // paused,
      // ended,
      // startDate,
      lyric,
      gid,
      releaseGid,
    };
  }

  //播放
  onTogglePlay = () => {
    if (this.state.audioLists.length >= 1) {
      this.lyric.togglePlay();
      if (this.state.playing) {
        return this._pauseAudio();
      }
      this.setState({ notAutoPlayUntilPlayClicked: !this.props.autoPlay }, this.loadAndPlayAudio);
    }
  };

  canPlay = () => {
    this.setAudioLength();
    this.setState({
      loading: false,
      playing: false,
      isAudioListsChange: false,
    });

    if (this.state.isInitAutoplay) {
      this.loadAndPlayAudio();
    }
  };

  //暂停
  _pauseAudio = () => {
    // this.audio.pause()
    this.setState({ playing: false, pause: true }, () => {
      this.lyric && this.lyric.stop();
    });
  };
  onPauseAudio = () => {
    this._pauseAudio();
    this.lyric && this.lyric.stop();
    this.props.onAudioPause && this.props.onAudioPause(this.getBaseAudioInfo());
  };

  loadAndPlayAudio = () => {
    const { autoPlay, remember } = this.props;
    const {
      isInitAutoplay,
      isInitRemember,
      // loadProgress,
      notAutoPlayUntilPlayClicked,
    } = this.state;
    const maxLoadProgress = 100;
    const { pause } = this.getLastPlayStatus();
    const isLastPause = remember && !isInitRemember && pause;
    const canPlay = isInitAutoplay || autoPlay || notAutoPlayUntilPlayClicked;
    this.setState(
      {
        playing: remember ? !isLastPause : canPlay,
        loading: false,
        pause: remember ? isLastPause : !canPlay,
        loadProgress: maxLoadProgress,
      },
      () => {
        if (remember ? !isLastPause : canPlay) {
          // fuck Safari is need muted :(
          // this.audio.getInternalPlayer().muted = true
          this.audio.getInternalPlayer().play();
        }
        this.setState({ isInitAutoplay: true, isInitRemember: true });
      },
    );
  };
  //加载音频
  load = () => {
    this.setState({ loading: true });
  };
  //设置音频长度
  setAudioLength = () => {
    this.setState({
      duration: this.audio.getDuration(),
    });
  };
  onAudioLoadError = (e, ...args) => {
    const { playMode, audioLists, playId, musicSrc } = this.state;
    const { loadAudioErrorPlayNext } = this.props;
    const isSingleLoop = playMode === this.PLAY_MODE.singleLoop.key;
    const isLastAudio =
      (playMode === this.PLAY_MODE.order.key || playMode === this.PLAY_MODE.orderLoop.key) &&
      playId === audioLists[audioLists.length - 1].id;
    const currentPlayMode = isSingleLoop ? this.PLAY_MODE.order.key : playMode;

    this.lyric.stop();

    //如果当前音乐加载出错 尝试播放下一首
    if (loadAudioErrorPlayNext && !isLastAudio) {
      this.handlePlay(currentPlayMode, true);
    }

    console.log(e, args);

    // 如果删除歌曲或其他原因导致列表为空时
    // 这时候会触发 https://developer.mozilla.org/en-US/docs/Web/API/MediaError
    if (musicSrc) {
      Sentry.withScope((scope) => {
        if (args) {
          _.forEach(args, (el) => {
            _.toPairs(el, (ele) => _.forEach(ele, ([key, value]) => scope.setExtra(key, value)));
          });
        }

        Sentry.captureException(new Error('playback error'));
      });

      this.props.onAudioLoadError &&
        this.props.onAudioLoadError(
          this.audio.error || e.reason || null,
          playId,
          audioLists,
          this.getBaseAudioInfo(),
        );
    }
  };
  //isNext true 下一首  false
  handlePlay = (playMode, isNext = true) => {
    // eslint-disable-next-line no-unused-vars
    let IconNode = '';
    let { playId, audioLists } = this.state;
    const audioListsLen = audioLists.length;
    const currentPlayIndex = audioLists.findIndex((audio) => audio.id === playId);
    switch (playMode) {
      //顺序播放
      case this.PLAY_MODE['order']['key']:
        IconNode = <OrderPlayIcon />;
        // 拖拽排序后 或者 正常播放 到最后一首歌 就暂停
        if (currentPlayIndex === audioListsLen - 1) return this._pauseAudio();
        this.audioListsPlay(
          isNext ? audioLists[currentPlayIndex + 1].id : audioLists[currentPlayIndex - 1].id,
        );
        break;

      //列表循环
      case this.PLAY_MODE['orderLoop']['key']:
        IconNode = <RepeatIcon />;
        if (isNext) {
          if (currentPlayIndex === audioListsLen - 1) {
            return this.audioListsPlay(audioLists[0].id);
          }
          this.audioListsPlay(audioLists[currentPlayIndex + 1].id);
        } else {
          if (currentPlayIndex === 0) {
            return this.audioListsPlay(audioLists[audioListsLen - 1].id);
          }
          this.audioListsPlay(audioLists[currentPlayIndex - 1].id);
        }
        break;

      //单曲循环
      case this.PLAY_MODE['singleLoop']['key']:
        IconNode = <LoopIcon />;
        this.audio.seekTo(0);
        this.audioListsPlay(playId, true);
        break;

      //随机播放
      case this.PLAY_MODE['shufflePlay']['key']:
        {
          IconNode = <ShufflePlayIcon />;
          let randomIndex = createRandomNum(0, audioListsLen - 1);
          const randomPlayId = (audioLists[randomIndex] || {}).id;
          this.audioListsPlay(randomPlayId, true);
        }
        break;
      default:
        IconNode = <OrderPlayIcon />;
    }
  };
  //音频播放结束
  audioEnd = () => {
    this.props.onAudioEnded &&
      this.props.onAudioEnded(this.state.playId, this.state.audioLists, this.getBaseAudioInfo());
    this.handlePlay(this.state.playMode);
  };
  /**
   * 上一首 下一首 通用方法
   * 除随机播放之外 都以  点击了上一首或者下一首 则以列表循环的方式 顺序放下一首歌
   * 参考常规播放器的逻辑
   */
  audioPrevAndNextBasePlayHandle = (isNext = true) => {
    const { playMode } = this.state;
    let _playMode = '';
    switch (playMode) {
      case this.PLAY_MODE['shufflePlay']['key']:
        _playMode = playMode;
        break;
      default:
        _playMode = this.PLAY_MODE['orderLoop']['key'];
        break;
    }
    this.handlePlay(_playMode, isNext);
  };
  //上一首
  audioPrevPlay = () => {
    this.audioPrevAndNextBasePlayHandle(false);
  };
  //下一首
  audioNextPlay = () => {
    this.audioPrevAndNextBasePlayHandle(true);
  };
  //播放进度更新
  audioTimeUpdate = () => {
    const currentTime = this.audio.getCurrentTime();
    this.setState({ currentTime });
    if (this.props.remember) {
      this.saveLastPlayStatus();
    }
    this.props.onAudioProgress && this.props.onAudioProgress(this.getBaseAudioInfo());
  };
  //音量改变
  audioSoundChange = (value) => {
    this.setAudioVolume(value);
  };
  onAudioVolumeChange = () => {
    const volume = this.audio.volume;
    this.setState({
      isMute: this.audio.volume <= 0,
      soundValue: volume,
    });
    this.props.onAudioVolumeChange && this.props.onAudioVolumeChange(volume);
  };
  onAudioPlay = () => {
    this.setState({ playing: true, loading: false }, this.initLyricParser);
    this.props.onAudioPlay && this.props.onAudioPlay(this.getBaseAudioInfo());
  };
  //进度条跳跃
  onAudioSeeked = () => {
    if (this.state.audioLists.length >= 1) {
      if (this.state.playing) {
        this.setState({ playing: true }, () => {
          this.loadAndPlayAudio();
          this.lyric.seek(this.getLyricPlayTime());
        });
      }
      this.props.onAudioSeeked && this.props.onAudioSeeked(this.getBaseAudioInfo());
    }
  };
  //静音
  onMute = () => {
    this.setState(
      {
        isMute: true,
        soundValue: 0,
        currentAudioVolume: this.audio.volume,
      },
      () => {
        this.audio.volume = 0;
      },
    );
  };
  //加载中断
  onAudioAbort = (e) => {
    const { audioLists, playId } = this.state;
    const audioInfo = this.getBaseAudioInfo();
    const mergedAudioInfo = _.assign({}, e, audioInfo);
    this.props.onAudioAbort && this.props.onAudioAbort(playId, audioLists, mergedAudioInfo);
    if (audioLists.length) {
      this.audio.getInternalPlayer().pause();
      this.state.isInitAutoplay && this.audio.getInternalPlayer().play();
      this.lyric.stop();
    }
  };
  //切换播放器模式
  toggleMode = (mode) => {
    if (mode === this.toggleModeName['full']) {
      this.setState({ toggle: true });
    }
  };
  //列表拖拽排序
  audioListsDragEnd = (fromIndex, toIndex) => {
    const { playId, audioLists } = this.state;
    const _audioLists = [...audioLists];
    const item = _audioLists.splice(fromIndex, 1)[0];
    _audioLists.splice(toIndex, 0, item);

    //如果拖动正在播放的歌曲 播放Id 等于 拖动后的index
    let _playId = fromIndex === playId ? toIndex : playId;

    this.setState({ audioLists: _audioLists, playId: _playId });

    this.props.onAudioListsDragEnd && this.props.onAudioListsDragEnd(fromIndex, toIndex);

    this.props.onAudioListsChange &&
      this.props.onAudioListsChange(_playId, _audioLists, this.getBaseAudioInfo());
  };
  saveLastPlayStatus = () => {
    const {
      currentTime,
      playId,
      duration,
      theme,
      soundValue,
      playMode,
      name,
      cover,
      singer,
      musicSrc,
      pause,
    } = this.state;
    const lastPlayStatus = JSON.stringify({
      currentTime,
      playId,
      duration,
      theme,
      playMode,
      soundValue,
      name,
      cover,
      singer,
      musicSrc,
      pause,
    });
    localStorage.setItem('lastPlayStatus', lastPlayStatus);
  };
  getLastPlayStatus = () => {
    const { theme, defaultPlayMode } = this.props;

    let status = {
      currentTime: 0,
      duration: 0,
      playMode: defaultPlayMode,
      name: '',
      cover: '',
      singer: '',
      musicSrc: '',
      lyric: '',
      playId: this.getDefaultPlayId(),
      theme,
      pause: false,
    };
    try {
      return JSON.parse(localStorage.getItem('lastPlayStatus')) || status;
    } catch (error) {
      return status;
    }
  };
  mockAutoPlayForMobile = () => {
    if (this.props.autoPlay && !this.state.playing && this.state.pause) {
      this.audio.getInternalPlayer().load();
      this.audio.getInternalPlayer().pause();
      this.audio.getInternalPlayer().play();
    }
  };
  bindMobileAutoPlayEvents = () => {
    document.addEventListener(
      'touchstart',
      () => {
        this.mockAutoPlayForMobile();
      },
      { once: true },
    );
    //监听微信准备就绪事件
    document.addEventListener('WeixinJSBridgeReady', () => {
      this.mockAutoPlayForMobile();
    });
  };
  bindSafariAutoPlayEvents = () => {
    document.addEventListener(
      'click',
      () => {
        this.mockAutoPlayForMobile();
      },
      { once: true },
    );
  };
  unBindEvents = (...options) => {
    this.bindEvents(...options);
  };
  /**
   * 绑定 audio 标签 事件
   */
  bindEvents = (
    target = this.audio,
    eventsNames = {
      waiting: this.loadAndPlayAudio,
      canplay: this.canPlay,
      error: this.onAudioLoadError,
      ended: this.audioEnd,
      seeked: this.onAudioSeeked,
      pause: this.onPauseAudio,
      play: this.onAudioPlay,
      timeupdate: this.audioTimeUpdate,
      volumechange: this.onAudioVolumeChange,
      stalled: this.onAudioLoadError, //当浏览器尝试获取媒体数据，但数据不可用时
      abort: this.onAudioAbort,
    },
    bind = true,
  ) => {
    const { once } = this.props;
    for (let name in eventsNames) {
      const _events = eventsNames[name];
      bind
        ? target.addEventListener(name, _events, {
            once: !!(once && name === 'play'),
          })
        : target.removeEventListener(name, _events);
    }
  };
  getPlayInfo = (audioLists = []) => {
    const newAudioLists = audioLists.filter((audio) => !audio['id']);
    const lastAudioLists = audioLists.filter((audio) => audio['id']);
    const _audioLists = [
      ...lastAudioLists,
      ...newAudioLists.map((info) => {
        return {
          ...info,
          id: uuId(),
        };
      }),
    ];
    const playIndex = Math.max(0, Math.min(_audioLists.length, this.props.defaultPlayIndex));

    const playId = this.state.playId || (_audioLists.length > 0 ? _audioLists[playIndex].id : '');
    const {
      name = '',
      cover = '',
      singer = '',
      musicSrc = '',
      lyric = '',
      gid = '',
      releaseGid = '',
    } = _audioLists.find(({ id }) => id === playId) || {};
    return {
      name,
      cover,
      singer,
      musicSrc,
      lyric,
      audioLists: _audioLists,
      playId,
      gid,
      releaseGid,
    };
  };
  // I change the name of getPlayInfo to getPlayInfoOfNewList because i didn't want to change the prior changes
  // the only thing this function does is to add id to audiolist elements.
  getPlayInfoOfNewList = (audioLists = []) => {
    const _audioLists = audioLists.map((info) => {
      return {
        ...info,
        // id: uuId(),
      };
    });

    const playIndex = Math.max(0, Math.min(_audioLists.length, this.props.defaultPlayIndex));

    const playId = _audioLists.find((a) => a.id === this.state.playId)
      ? this.state.playId
      : _audioLists[playIndex].id;

    const {
      name = '',
      cover = '',
      singer = '',
      musicSrc = '',
      lyric = '',
      gid = '',
      releaseGid = '',
    } = _audioLists.find(({ id }) => id === playId) || {};
    return {
      name,
      cover,
      singer,
      musicSrc,
      lyric,
      audioLists: _audioLists,
      playId,
      gid,
      releaseGid,
    };
  };

  initPlayInfo = (audioLists, cb) => {
    const info = this.getPlayInfo(audioLists);

    switch (typeof info.musicSrc) {
      case 'function':
        info.musicSrc().then((originMusicSrc) => {
          const { path, creds } = originMusicSrc;
          this.setState({ ...info, musicSrc: path, creds }, cb);
        }, this.onAudioLoadError);
        break;
      default:
        this.setState(info, cb);
    }
  };

  listenerIsMobile = ({ matches }) => {
    this.setState({
      isMobile: !!matches,
    });
  };
  addMobileListener = () => {
    this.media = window.matchMedia('(max-width: 768px) and (orientation : portrait)');
    this.media.addListener(this.listenerIsMobile);
  };
  setDefaultAudioVolume = () => {
    const { defaultVolume, remember } = this.props;
    //音量 [0-1]
    this.defaultVolume = Math.max(0, Math.min(defaultVolume, 1));
    const { soundValue = this.defaultVolume } = this.getLastPlayStatus();
    this.setAudioVolume(remember ? soundValue : this.defaultVolume);
  };
  getDefaultPlayId = (audioLists = this.props.audioLists) => {
    const playIndex = Math.max(0, Math.min(audioLists.length, this.props.defaultPlayIndex));
    return audioLists[playIndex] && audioLists[playIndex].id;
  };
  getLyricPlayTime = () => {
    const [m, s] = formatTime(this.audio.getCurrentTime()).split(':');
    return m * 1000 + s * 10;
  };
  initLyricParser = () => {
    this.lyric = undefined;
    this.lyric = new Lyric(this.state.lyric, this.onLyricChange);
    this.lyric.stop();
    if (this.props.showLyric && this.state.playing) {
      this.lyric.seek(this.getLyricPlayTime());
      this.lyric.play();
    }
  };
  onLyricChange = ({ lineNum, txt }) => {
    this.setState({
      currentLyric: txt,
    });
    this.props.onAudioLyricChange && this.props.onAudioLyricChange(lineNum, txt);
  };

  updateTheme = (theme) => {
    if (
      theme &&
      theme !== this.props.theme &&
      (theme === this.lightThemeName || theme === this.darkThemeName)
    ) {
      this.setState({ theme });
      this.props.onThemeChange && this.props.onThemeChange(theme);
    }
  };

  updateMode = (mode) => {
    if (
      mode &&
      mode !== this.props.mode &&
      (mode === this.toggleModeName.full || mode === this.toggleModeName.mini)
    ) {
      this.setState({ toggle: mode === this.toggleModeName.full });
      this.props.onModeChange && this.props.onModeChange(mode);
    }
  };

  updateAudioLists = (audioLists) => {
    const newAudioLists = [...audioLists];
    this.initPlayInfo(newAudioLists);
    // this.bindEvents(this.audio)
    this.props.onAudioListsChange &&
      this.props.onAudioListsChange(this.state.playId, audioLists, this.getBaseAudioInfo());
  };

  loadNewAudioLists = (
    audioLists,
    { remember, defaultPlayIndex, defaultPlayMode, theme, autoPlayInitLoadPlayList },
  ) => {
    if (audioLists.length >= 1) {
      const info = this.getPlayInfoOfNewList(audioLists);
      const lastPlayStatus = remember
        ? this.getLastPlayStatus(defaultPlayIndex)
        : { playMode: defaultPlayMode, theme };

      switch (typeof info.musicSrc) {
        case 'function':
          info.musicSrc().then((val) => {
            const { path, creds } = val;
            this.setState({
              ...info,
              musicSrc: path,
              creds,
              isInitAutoplay: autoPlayInitLoadPlayList,
              ...lastPlayStatus,
            });
          }, this.onAudioLoadError);
          break;
        default:
          this.setState({
            ...info,
            isInitAutoplay: autoPlayInitLoadPlayList,
            ...lastPlayStatus,
          });
      }
    }
  };

  changeAudioLists = (audioLists) => {
    this.resetAudioStatus();
    this.loadNewAudioLists(audioLists, this.props);
    this.props.onAudioListsChange &&
      this.props.onAudioListsChange(this.state.playId, audioLists, this.getBaseAudioInfo());
    this.setState({ isAudioListsChange: true });
  };

  resetPlayList = (state) => {
    const _playIndex = Math.max(0, Math.min(state.audioLists.length, this.props.defaultPlayIndex));

    const currentPlay = state.audioLists[_playIndex];
    if (currentPlay && currentPlay.id) {
      this.audioListsPlay(currentPlay.id, true, state);
    }
  };

  updatePlayIndex = (playIndex) => {
    // 播放索引 改变
    const currentPlayIndex = this.state.audioLists.findIndex(
      (audio) => audio.id === this.state.playId,
    );

    if (currentPlayIndex !== playIndex) {
      const _playIndex = Math.max(0, Math.min(this.state.audioLists.length, currentPlayIndex));
      const currentPlay = this.state.audioLists[_playIndex];
      if (currentPlay && currentPlay.id) {
        this.audioListsPlay(currentPlay.id, true);
      }
    }
  };

  onGetAudioInstance = () => {
    if (this.props.getAudioInstance) {
      Object.defineProperty(this.audio, 'destroy', {
        value: () => {
          this.onDestroyPlayer();
        },
        writable: false,
      });
      this.props.getAudioInstance(this.audio);
    }
  };

  //当父组件 更新 props 时 如 audioLists 改变 更新播放信息
  UNSAFE_componentWillReceiveProps({
    audioLists,
    defaultPlayIndex,
    theme,
    mode,
    clearPriorAudioLists,
  }) {
    if (!arrayEqual(audioLists)(this.props.audioLists)) {
      if (clearPriorAudioLists) {
        this.changeAudioLists(audioLists);
      } else {
        this.updateAudioLists(audioLists);
      }
    } else {
      this.updatePlayIndex(defaultPlayIndex);
    }
    this.updateTheme(theme);
    this.updateMode(mode);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextProps.clearPriorAudioLists) {
      if (nextState.isAudioListsChange !== this.state.isAudioListsChange) {
        this.resetPlayList(nextState);
      }
    }
  }

  UNSAFE_componentWillMount() {
    const { mode, audioLists, defaultPlayMode, remember, theme, defaultPlayIndex, autoPlay } =
      this.props;

    //切换 'mini' 或者 'full' 模式
    this.toggleMode(mode);

    if (audioLists.length >= 1) {
      const info = {
        ...this.getPlayInfo(audioLists),
        isInitAutoplay: autoPlay,
      };
      const lastPlayStatus = remember
        ? this.getLastPlayStatus(defaultPlayIndex)
        : { playMode: defaultPlayMode, theme };

      switch (typeof info.musicSrc) {
        case 'function':
          info.musicSrc().then((val) => {
            const { path, creds } = val;
            this.setState({
              ...info,
              musicSrc: path,
              creds,
              ...lastPlayStatus,
            });
          }, this.onAudioLoadError);
          break;
        default:
          this.setState({
            ...info,
            ...lastPlayStatus,
          });
      }
    }
  }

  bindUnhandledRejection = () => {
    window.addEventListener('unhandledrejection', this.onAudioLoadError);
  };
  unBindUnhandledRejection = () => {
    window.removeEventListener('unhandledrejection', this.onAudioLoadError);
  };
  bindKeyDownEvents = () => {
    document.addEventListener('keydown', this.onKeyDown, false);
  };
  unBindKeyDownEvents = () => {
    document.removeEventListener('keydown', this.onKeyDown, false);
  };
  onKeyDown = (e) => {
    const { spaceBar } = this.props;
    if (spaceBar && e.keyCode === SPACE_BAR_KEYCODE) {
      this.onTogglePlay();
    }
  };

  componentWillUnmount() {
    this._onDestroyed();
    // this.unBindEvents(this.audio, undefined, false)
    this.unBindUnhandledRejection();
    this.unBindKeyDownEvents();
    this.media.removeListener(this.listenerIsMobile);
    this.media = undefined;
    this.lyric.stop();
  }

  componentDidMount() {
    this.addMobileListener();
    this.setDefaultAudioVolume();
    this.bindUnhandledRejection();
    if (this.props.audioLists.length >= 1) {
      // this.bindEvents(this.audio)
      this.onGetAudioInstance();
      this.initLyricParser();
      if (IS_MOBILE) {
        this.bindMobileAutoPlayEvents();
      } else {
        this.bindKeyDownEvents();
      }
      if (!IS_MOBILE && isSafari()) {
        this.bindSafariAutoPlayEvents();
      }
    }
  }
}

export default withStyles(playerStyle)(ReactJkMusicPlayer);
