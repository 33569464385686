import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx';
import CustomSelect from 'components/CustomSelect/CustomSelect.jsx';
import { withFormsy } from 'formsy-react';
import { compose, withProps } from 'recompose';

function CustomInput({ ...props }) {
  const { value: valueProp } = props;

  const showError = props.isFormSubmitted && !props.isValid;

  const value = props.value !== 'undefined' ? props.value : valueProp;
  const errorMessage = props.errorMessage;

  return (
    <CustomSelect
      {...props}
      value={value}
      onChange={(e) => props.setValue(e.target.value)}
      error={showError}
      helpText={errorMessage}
    />
  );
}

const enhance = compose(
  withStyles(customInputStyle),
  withProps((props) => ({ ...props, name: props.id })),
  withFormsy,
);

export default enhance(CustomInput);
