import { gql } from '@apollo/client';

const GET_RELEASE_BY_GID = gql`
  query GetRelease($gid: ID!) {
    releaseByGid(gid: $gid) {
      id
      gid
      name
      year
      genre
      artists {
        id
        name
      }
      media {
        id
        artwork(height: 80, width: 80)
        trackCount
        tracks {
          id
          gid
          name
          artists
          position
          size
          duration
          artwork(height: 80, width: 80)
        }
      }
    }
  }
`;

const TRACK_STREAMED = gql`
  mutation TrackStreamed($email: String, $gid: ID!, $releaseGid: ID!, $playDuration: Int!) {
    trackStreamed(email: $email, gid: $gid, releaseGid: $releaseGid, playDuration: $playDuration) {
      id
    }
  }
`;

export { GET_RELEASE_BY_GID, TRACK_STREAMED };
