import React from 'react';

import classNames from 'classnames';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';

import 'moment/locale/fr';
import 'react-day-picker/lib/style.css';

import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx';
import { withFormsy } from 'formsy-react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { withTranslation } from 'react-i18next';
import { compose, withProps } from 'recompose';

class DateInput extends React.Component {
  render() {
    const {
      classes,
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      white,
      inputRootCustomClasses,
      success,
      i18n,
    } = this.props;

    const value = this.props.value || '';

    const helpText = this.props.errorMessage;
    const error = this.props.isFormSubmitted && !this.props.isValid;

    const labelClasses = classNames({
      [' ' + classes.labelRootError]: error,
      [' ' + classes.labelRootSuccess]: success && !error,
    });
    const underlineClasses = classNames({
      [classes.underlineError]: error,
      [classes.underlineSuccess]: success && !error,
      [classes.underline]: true,
      [classes.whiteUnderline]: white,
    });
    const marginTop = classNames({
      [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
    });
    const inputClasses = classNames({
      [classes.input]: true,
      [classes.whiteInput]: white,
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
      formControlClasses = classNames(formControlProps.className, classes.formControl);
    } else {
      formControlClasses = classes.formControl;
    }
    var helpTextClasses = classNames({
      [classes.labelRootError]: error,
      [classes.labelRootSuccess]: success && !error,
    });

    return (
      <FormControl {...formControlProps} className={formControlClasses}>
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + ' ' + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <DayPickerInput
          dayPickerProps={{
            disabledDays: {
              after: new Date(),
            },
          }}
          localeUtils={MomentLocaleUtils}
          locale={i18n.languages[0]}
          component={(props) => (
            <Input
              id={id}
              classes={{
                input: inputClasses,
                root: marginTop,
                disabled: classes.disabled,
                underline: underlineClasses,
              }}
              {...props}
            />
          )}
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder={''}
          onDayChange={(selectedDay) => {
            this.props.setValue(selectedDay);
          }}
          inputProps={{ ...inputProps, ref: null }}
          value={value}
        />
        {helpText !== undefined ? (
          <FormHelperText id={id + '-text'} className={helpTextClasses}>
            {helpText}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withStyles(customInputStyle),
  withProps((props) => ({ name: props.id, innerRef: null })),
  withFormsy,
);

export default enhance(DateInput);
