import { alpha } from '@material-ui/core/styles/colorManipulator';
import {
  containerFluid,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const headerStyle = (theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'absolute',
    width: '100%',
    paddingTop: '10px',
    zIndex: '1029',
    color: grayColor[6],
    border: '0',
    borderRadius: '3px',
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block',
  },
  container: {
    ...containerFluid,
  },
  banner: {
    flexGrow: 2,
    padding: '0px',
  },

  message: {
    paddingLeft: '20px',
    textDecoration: 'underline',
  },

  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    margin: '0 !important',
    '&:hover,&:focus': {
      background: 'transparent',
    },
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  sidebarMinimize: {
    float: 'left',
    padding: '0 0 0 15px',
    display: 'block',
    color: grayColor[6],
  },
  sidebarMinimizeRTL: {
    padding: '0 15px 0 0 !important',
  },
  sidebarMiniIcon: {
    width: '20px',
    height: '17px',
  },
  search: {
    position: 'relative',
    // borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    // margin: "0",
    marginLeft: 0,
    width: '100%',
    paddingTop: '7px',
    paddingBottom: '7px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 15px',
      float: 'none !important',
      paddingTop: '1px',
      paddingBottom: '1px',
      padding: '10px 15px',
      width: 'auto',
    },
  },
  searchInput: {
    paddingTop: '2px',
    borderWidth: '0px',
    border: 'none',
  },
  searchIcon: {
    zIndex: '4',
    width: theme.spacing(9),
    marginLeft: 0,
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  app__header: {
    '& *::after': {
      clear: 'unset !important;',
      content: 'unset !important;',
      display: 'unset !important;',
    },
    '& *::before': {
      clear: 'unset !important;',
      content: 'unset !important;',
      display: 'unset !important;',
    },
  },
  profileContainer: {
    display: 'flex',
    height: 'fit-content',
    '& .PrivateHiddenCss-smDown-111': {
      display: 'flex',
      height: 'fit-content',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& .MuiSnackbarContent-root': {
      minWidth: '156px !important',
      marginRight: '1em !important',

      [theme.breakpoints.down('sm')]: {
        flexGrow: 'initial',
        minWidth: '156px !important',
      },
    },
  },
});

export default headerStyle;
