import React from 'react';

import { useQuery } from '@apollo/client';
import withStyles from '@material-ui/core/styles/withStyles';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import defaultImage from 'assets/img/default-avatar.png';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { withTranslation } from 'react-i18next';
import { Switch, Route, NavLink } from 'react-router-dom';
import { compose } from 'recompose';
import EditArtist from 'views/Artist/MyArtists/EditArtist.jsx';
import NewArtist from 'views/Artist/MyArtists/NewArtist.jsx';

import { MY_ARTISTS } from './graphql.jsx';

const MyArtists = ({ ...props }) => {
  const {
    classes,
    data: { myArtists },
    t,
  } = props;
  return (
    <div>
      <GridContainer justify='flex-end' alignItems='center'>
        <GridItem xs={2}>
          <NavLink to='/gallery/myartists/new'>
            <Button color='warning' variant='contained'>
              <Add />
              {t('artists.new')}
            </Button>
          </NavLink>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {myArtists.map((artist, i) => (
          <GridItem key={i} xs={12} md={2}>
            <Card product className={classes.cardHover}>
              <NavLink to={`/gallery/artist/${artist.gid}`}>
                <CardHeader image>
                  <img
                    className={classes.headerImage}
                    src={artist.artwork ? artist.artwork : defaultImage}
                    alt='...'
                  />
                </CardHeader>
              </NavLink>
              <CardBody>
                <h4 className={classes.cardProductTitle}>
                  <NavLink to={`/gallery/myartists/${artist.gid}`}>{artist.name}</NavLink>
                </h4>
                <p className={classes.cardProductDesciprion}>{artist.bio}</p>
              </CardBody>
              <CardFooter product>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Edit />
                  <NavLink to={`/gallery/myartists/${artist.gid}`}>
                    {t('common.form.edit.text')}
                  </NavLink>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

const MyArtistViewResults = compose(withTranslation(), withStyles(dashboardStyle))(MyArtists);

const MyArtistsView = (props) => {
  const { loading, data } = useQuery(MY_ARTISTS);
  if (loading) return null;
  return (
    <Switch>
      <Route
        exact
        path='/gallery/myartists'
        render={(p) => {
          return <MyArtistViewResults data={data} {...props} {...p} />;
        }}
      />
      <Route exact path='/gallery/myartists/new' render={(p) => <NewArtist {...props} {...p} />} />
      <Route path='/gallery/myartists/:gid' render={(p) => <EditArtist {...props} {...p} />} />
    </Switch>
  );
};

export default MyArtistsView;
