import gql from 'graphql-tag';

const SEARCH = gql`
  query search($query: String!) {
    search(query: $query) {
      releases {
        gid
        name
        artist
        artwork
        searchType
      }
      artists {
        gid
        name
        artist
        artwork
        searchType
      }
      tracks {
        gid
        name
        artist
        artwork
        releaseGid
        searchType
        playUrl
      }
    }
  }
`;

export { SEARCH };
