import { gql } from '@apollo/client';

const GET_USER_DATA = gql`
  query UserData {
    loggedInUser {
      id
      gid
      email
      firebaseToken
    }
    isLoggedIn
  }
`;

export { GET_USER_DATA };
