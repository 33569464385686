import { gql } from '@apollo/client';

const CLEAR_CART = gql`
  mutation ClearCart {
    clearCart {
      id
      gid
    }
  }
`;

const SHOPPING_CART_VIEW = gql`
  query ShoppingCartView {
    shoppingCart {
      id
      gid
      name
      artists {
        id
        name
      }
      artwork(height: 100, width: 100)
      priceCategory {
        id
        tier
        value
      }
    }
    localeCurrency {
      currency
      currencyCountryCode
    }
    paymentMethods {
      id
      name
    }
    loggedInUser {
      id
      email
      userName
      firstName
      lastName
      profilePic
      verified
    }
  }
`;

export { SHOPPING_CART_VIEW, CLEAR_CART };
