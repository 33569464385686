import { gql } from '@apollo/client';

const TOGGLE_CART_ITEM = gql`
  mutation AddCartItem($gid: ID!) {
    toggleCartItem(gid: $gid) {
      gid
    }
  }
`;

const CLEAR_CART = gql`
  mutation ClearCart {
    clearCart {
      id
    }
  }
`;

export { TOGGLE_CART_ITEM, CLEAR_CART };
