import React from 'react';

import Button from 'components/CustomButtons/Button.jsx';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { compose, pure, branch, renderComponent, renderNothing } from 'recompose';

const RenderNothingOnNoData = (noData) => compose(branch(noData, renderNothing), pure);

const ErrorComponent = (props) => {
  if (_.includes(props.error.message, 'LOGIN_USER')) {
    return (
      <SnackbarContent
        color='danger'
        message={
          <span>
            User needs to Sign In{' '}
            <NavLink to='/auth/login'>
              <Button size='sm' color='warning'>
                Login
              </Button>
            </NavLink>
          </span>
        }
      />
    );
  } else {
    return <SnackbarContent color='danger' message={'Error occurred'} />;
  }
};

const RenderForError = (errorState) =>
  compose(branch(errorState, renderComponent(ErrorComponent)), pure);

const RenderNothingForError = (errorState) => compose(branch(errorState, renderNothing), pure);

export { RenderNothingOnNoData, RenderForError, RenderNothingForError };
