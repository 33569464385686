import { gql } from '@apollo/client';

const GET_USER_PROFILE = gql`
  query UserProfile {
    loggedInUser {
      id
      email
      firstName
      lastName
      userName
      country {
        id
        code
      }
      profilePic(height: 100, width: 0)
      phoneNumber
      verified
    }
    payableTransactions {
      id
      gid
      transactionDate
      modifiedDate
      item {
        id
        gid
        name
      }
      itemOwner {
        id
        gid
      }
      boughtBy {
        id
        gid
      }
      amount
      currency
      status {
        id
        ident
      }
      statusReason
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $firstName: String
    $lastName: String
    $userName: String
    $country: String
    $profilePic: Upload
    $phoneNumber: String
  ) {
    updateUser(
      firstName: $firstName
      lastName: $lastName
      userName: $userName
      country: $country
      profilePic: $profilePic
      phoneNumber: $phoneNumber
    ) {
      id
      firstName
      lastName
      userName
      country {
        id
        code
      }
      profilePic
      phoneNumber
    }
  }
`;

const VERIFY_EMAIL = gql`
  mutation VerifyEmail {
    verifyEmail {
      id
      email
      verified
    }
  }
`;

export { GET_USER_PROFILE, UPDATE_USER, VERIFY_EMAIL };
