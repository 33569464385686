import React from 'react';

import 'moment-timezone';
import 'moment/locale/fr';
import { useQuery, NetworkStatus } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import SalesPieChart from 'components/Charts/salesPieChart.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Table from 'components/Table/SalesTable.jsx';
import { useTranslation } from 'react-i18next';

import SalesStyle from '../../assets/jss/material-dashboard-pro-react/components/salesCardStyle.jsx';
import SalesBarGraph from '../../components/SalesBarGraph/SalesBarGraph';
import { GET_USER_TRANSACTIONS } from './graphql';

const MySales = () => {
  const classes = SalesStyle();
  const { t, i18n } = useTranslation();
  const { loading, data, fetchMore, networkStatus } = useQuery(GET_USER_TRANSACTIONS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 5,
    },
  });

  const flat = (data) => {
    return data.map((el) => ({
      ...el,
      item: el.item.name,
      status: el.status.ident,
    }));
  };

  const getItems = () => {
    if (!(data === undefined || data === null)) {
      return flat(data.receivableTransactionsConnection.edges.map((edge) => edge.node));
    }
  };

  if ((loading && !data) || networkStatus === NetworkStatus.refetch) return null;

  const handleLoadMore = () => {
    if (data && data.receivableTransactionsConnection.pageInfo.hasNextPage) {
      return flat(
        fetchMore({
          variables: {
            cursor: data.receivableTransactionsConnection.pageInfo.endCursor,
          },
        }).then((response) =>
          response.data.receivableTransactionsConnection.edges.map((edge) => edge.node),
        ),
      );
    }
  };

  const sales = t('sales.salesTitle');

  const { userSalesSummary } = data;
  const { total, available, currency, threshold } = userSalesSummary;
  const Total = total - available;
  const histogramData = [{ available, Total, sales }];

  const maxValue = Math.max(threshold, total);
  const tickInterval = maxValue / 5;
  const endGraphValue = maxValue + tickInterval;

  const formatter = new Intl.NumberFormat(i18n.languages[0], {
    style: 'currency',
    currency: currency,
  });

  const count = () => {
    return data.userSalesSummary.currencySums.map((val) => ({
      ...val,
      sum: val.sum / data.userSalesSummary.total,
    }));
  };

  return (
    <div className={classes.saleRoot}>
      <GridContainer spacing={3}>
        <Grid item xs={12} className={classes.barItem}>
          <div className={classes.barCont}>
            <SalesBarGraph
              dataSource={histogramData}
              threshold={threshold}
              endGraphValue={endGraphValue}
              intervalValue={tickInterval}
              currency={currency}
              available={formatter.format(available)}
              total={formatter.format(total)}
            />
          </div>
          <div className={classes.pieCont}>
            <SalesPieChart countries={count()} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Table items={getItems()} loadMore={handleLoadMore} />
        </Grid>
      </GridContainer>
    </div>
  );
};

export default MySales;
