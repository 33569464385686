import { gql } from '@apollo/client';

const MY_ARTISTS = gql`
  query MyArtists {
    myArtists {
      id
      gid
      name
      artwork(height: 300, width: 300)
    }
  }
`;

const GET_EDIT_ARTIST_VIEW = gql`
  query GetArtist($gid: ID!) {
    artistByGid(gid: $gid) {
      id
      gid
      name
      bio
      artwork(height: 300, width: 300)
      artistType {
        ident
      }
      gender {
        ident
      }
      country {
        id
        code
      }
    }
  }
`;

const CREATE_ARTIST = gql`
  mutation CreateArtist($name: String!, $bio: String!, $country: String!, $artwork: Upload!) {
    createArtist(name: $name, bio: $bio, country: $country, artwork: $artwork) {
      id
      gid
      bio
      artwork(height: 300, width: 300)
    }
  }
`;

const UPDATE_ARTIST = gql`
  mutation UpdateArtist(
    $gid: ID!
    $name: String!
    $bio: String!
    $country: String!
    $artwork: Upload
  ) {
    updateArtist(gid: $gid, name: $name, bio: $bio, country: $country, artwork: $artwork) {
      id
      gid
      name
      bio
      artwork(height: 300, width: 300)
      country {
        id
        code
      }
    }
  }
`;

export { MY_ARTISTS, CREATE_ARTIST, UPDATE_ARTIST, GET_EDIT_ARTIST_VIEW };
