import React from 'react';

import { useMutation } from '@apollo/client';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import Email from '@material-ui/icons/Email';
import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import FormsyCheckbox from 'components/FormsyCheckbox/FormsyCheckbox.jsx';
import CustomInput from 'components/FormsyInput/FormsyInput.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { compose } from 'recompose';

// material-ui components

// @material-ui/icons

// core components

import { CREATE_USER } from './graphql.jsx';

class RegisterPage extends React.Component {
  render() {
    const { classes, t } = this.props;

    return (
      <div className={classes.container}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={10}>
            <Card className={classes.cardSignup}>
              <h2 className={classes.cardTitle}>{t('register.label')}</h2>
              <CardBody>
                <GridContainer justify='center'>
                  <GridItem xs={12} sm={8} md={5}>
                    <Formsy
                      className={classes.form}
                      onValidSubmit={(d) => {
                        const payload = { ...d };
                        this.props.createUser({ variables: payload });
                      }}
                      ref='registerForm'
                    >
                      <CustomInput
                        id='email'
                        labelText={t('common.form.email.text')}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position='start' className={classes.inputAdornment}>
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: `${t('common.form.email.text')} ...`,
                        }}
                        validations='isEmail'
                        validationError='Not a valid Email address'
                        required
                      />
                      <CustomInput
                        id='password'
                        labelText={t('common.form.password.text')}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position='start' className={classes.inputAdornment}>
                              <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                            </InputAdornment>
                          ),
                          type: 'password',
                          placeholder: `${t('common.form.password.text')} ...`,
                        }}
                        validations='minLength:8'
                        validationError={t('common.form.password.validation')}
                        required
                      />
                      <CustomInput
                        id='confirmPassword'
                        labelText={t('common.form.confirmPassword.text')}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position='start' className={classes.inputAdornment}>
                              <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                            </InputAdornment>
                          ),
                          type: 'password',
                          placeholder: `${t('common.form.confirmPassword.text')} ...`,
                        }}
                        validations='equalsField:password'
                        validationError={t('common.form.confirmPassword.validation')}
                        required
                      />
                      <FormsyCheckbox
                        id='termsAndConditionsAccepted'
                        formLabelProps={{
                          classes: {
                            root: classes.checkboxLabelControl,
                            label: classes.checkboxLabel,
                          },
                          label: (
                            <Trans i18nKey='register.terms.text'>
                              <span>
                                I agree to the{' '}
                                <a
                                  href='/help/terms-of-conditions-and-privacy-policy'
                                  target='_blank'
                                >
                                  terms and conditions
                                </a>
                                .
                              </span>
                            </Trans>
                          ),
                        }}
                        validations='isTrue'
                        validationError={t('common.form.terms.validation')}
                        required
                      />
                      <div className={classes.center}>
                        <Button
                          round
                          color='warning'
                          onClick={() => this.refs.registerForm.reset()}
                        >
                          {t('common.form.reset.text')}
                        </Button>
                        <Button type='submit' color='warning' round>
                          {t('register.label')}
                        </Button>
                      </div>
                    </Formsy>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const RegisterView = (props) => {
  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: () => {
      NotificationManager.success(
        props.t('register.confirmation.text'),
        props.t('register.comfirmation.title'),
        7000,
      );
      props.history.push('/auth/login');
    },
  });

  return <RegisterPage {...props} createUser={createUser} />;
};

export default compose(withTranslation(), withStyles(registerPageStyle))(RegisterView);
