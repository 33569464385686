import { InMemoryCache, makeVar } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isConnected: {
          read() {
            return isConnectedVar();
          },
        },
        playlist: {
          read() {
            return playlistVar();
          },
        },
        clearPriorAudioLists: {
          read() {
            return clearPriorAudioListsVar();
          },
        },
        freeReleasesConnection: relayStylePagination(),
        receivableTransactionsConnection: relayStylePagination(),
        paidReleasesConnection: relayStylePagination(),
      },
    },
  },
});

export const addReleaseToNewPlaylistMutation = (items) => {
  playlistVar(items);
  clearPriorAudioListsVar(true);
};

export const appendReleaseToPlaylistMutation = (items) => {
  const playlist = playlistVar();
  playlistVar([...playlist, ...items]);
  clearPriorAudioListsVar(false);
};

export const addTrackToNewPlaylistMutation = (item) => {
  playlistVar([item]);
  clearPriorAudioListsVar(true);
};

export const appendTrackToPlaylistMutation = (item) => {
  const playlist = playlistVar();
  playlistVar([...playlist, item]);
  clearPriorAudioListsVar(false);
};

export const isConnectedVar = makeVar(true);
export const playlistVar = makeVar([]);
export const clearPriorAudioListsVar = makeVar(false);
