import { withApollo } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';

import { REFETCH_QUERIES_HACK, NOTIFICATION_READ } from './graphql.jsx';

const newNotificationItem = async (client) => {
  let props = {
    mutation: REFETCH_QUERIES_HACK,
    awaitRefetchQueries: true,
    refetchQueries: [
      'Notifications',
      'GalleryReleases',
      'UploadView',
      'ShoppingCartView',
      'NavBarView',
      'UserTransactions',
      'GalleryReleases',
      'GalleryArtists',
    ],
  };
  await client.mutate(props);
};

const notificationRead = async (client, id) => {
  await client.mutate({
    mutation: NOTIFICATION_READ,
    variables: { id },
    refetchQueries: ['Notifications'],
  });
};

const EnhanceNotifier = compose(
  withApollo,
  withProps((props) => ({
    newNotificationItem: () => newNotificationItem(props.client),
    notificationRead: (id) => notificationRead(props.client, id),
  })),
);

export { EnhanceNotifier };
