import React from 'react';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import { monthFormater } from '../../../util';

const PeriodFormater = (props) => {
  const { t, i18n } = useTranslation();
  const { chartPeriod, value } = props;
  const title = t(`chartsdetail.${chartPeriod}Format`);
  let period = '';

  if (chartPeriod === 'weekly') {
    const date = moment(value, moment.ISO_8601);
    period = date.locale(i18n.languages[0]).format('MMMM DD, YYYY');
  } else if (chartPeriod === 'monthly') {
    const date = monthFormater(value);
    period = moment(date).locale(i18n.languages[0]).format('MMMM YYYY');
  }

  return (
    <span>
      {title} {period}
    </span>
  );
};

export default PeriodFormater;
