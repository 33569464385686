import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

class PlayMusic extends Component {
  render() {
    return (
      <Card>
        <CardBody>
          <List>
            <ListItem>- Click on Gallery under menu</ListItem>
            <ListItem>
              - Click Play on the track you wish to listen to (you can play the entire Free Releases
              but can only sample 20 seconds per track for Paid Releases)
            </ListItem>
            <ListItem>
              - You can only play the full paid release after paying (click on Purchased under menu
              for quick access to your purchased releases)
            </ListItem>
            <ListItem>
              - You can also create a playlist as you continue browsing by clicking Add To Playlist
            </ListItem>
            <ListItem>- Close to music player to stop the music</ListItem>
          </List>
        </CardBody>
      </Card>
    );
  }
}

export default PlayMusic;
