import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx';
import classNames from 'classnames';
import CustomCheckbox from 'components/CustomCheck/CustomCheckbox.jsx';
import { withFormsy } from 'formsy-react';
import { withTranslation } from 'react-i18next';
import { compose, withProps } from 'recompose';
class MyInput extends React.Component {
  render() {
    const { classes, id, formLabelProps, t } = this.props;

    const onChange = (e) => {
      if (e.target.checked) {
        this.props.setValue(e.target.checked);
      } else {
        this.props.setValue('');
      }
    };

    const errorMessage = this.props.errorMessage;

    const showLabelText = this.props.isFormSubmitted && !this.props.isValid;
    const showError = this.props.isFormSubmitted && !this.props.isValid;

    const labelClasses = classNames({
      [' ' + classes.labelRootError]: showError,
      [' ' + classes.labelRootSuccess]: !showError,
    });

    return (
      <div>
        <CustomCheckbox
          id={id}
          onChange={onChange}
          formLabelProps={formLabelProps}
          helpText={
            <span className={classes.labelRoot + ' ' + labelClasses}>
              {showLabelText ? `*${t('common.form.validation.required')}` : null}
            </span>
          }
          error={showError}
        />
        <span>{errorMessage}</span>
      </div>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withStyles(customInputStyle),
  withProps((props) => ({ name: props.id, innerRef: null })),
  withFormsy,
);

export default enhance(MyInput);
