import { EnhanceWithArtistControls } from 'util/Artist/Artist';

import React, { Component } from 'react';

import { useQuery } from '@apollo/client';
import withStyles from '@material-ui/core/styles/withStyles';
import Check from '@material-ui/icons/VerifiedUser';
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';
import Card from 'components/Card/Card.jsx';
import CardAvatar from 'components/Card/CardAvatar.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import MetaTags from 'components/MetaTags/MetaTags.jsx';
import FreeRelease from 'components/Release/FreeRelease.jsx';
import PaidRelease from 'components/Release/PaidRelease.jsx';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { GET_ARTIST_VIEW } from './graphql.jsx';

class Artist extends Component {
  render() {
    const {
      classes,
      data: { artistReleases, artistByGid, artistMetaImage, loggedInUser, isLoggedIn },
      t,
    } = this.props;

    const { artwork, name, bio, follows, followerCount, verified } = artistByGid;
    let followText = follows ? t('common.follow.unfollow') : t('common.follow.text');

    const title = name;
    const description = bio;
    const image = artistMetaImage.artwork;
    const url = `${process.env.REACT_APP_SITE_URL}${window.location.pathname}`;
    return (
      <GridContainer>
        <MetaTags title={title} description={description} url={url} image={image} />
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardAvatar profile>
                <img src={artwork} alt='...' />
              </CardAvatar>
              <CardBody profile>
                <h4 className={classes.cardTitle}>
                  {name} {verified && <Check style={{ fill: 'orange' }} />}
                </h4>
                <p className={classes.description}>{bio}</p>
                <p>
                  <b>{followerCount}</b> {t('common.follower.text', { count: followerCount })}{' '}
                </p>
                {isLoggedIn && (
                  <Button
                    color='warning'
                    round
                    onClick={() =>
                      this.props.toggleFollowArtist(loggedInUser.email, artistByGid.gid)
                    }
                  >
                    {followText}
                  </Button>
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {artistReleases.map((rel) => {
            let Comp = rel.priceCategory.value === 0 ? FreeRelease : PaidRelease;
            return <Comp key={rel.id} rel={rel} />;
          })}
        </GridContainer>
      </GridContainer>
    );
  }
}

const ArtistResults = compose(
  withTranslation(),
  EnhanceWithArtistControls,
  withStyles(userProfileStyles),
)(Artist);

const ArtistView = (props) => {
  const { loading, data } = useQuery(GET_ARTIST_VIEW, {
    variables: { gid: props.match.params.gid },
  });

  if (loading) return null;
  return <ArtistResults {...props} data={data} />;
};

export default ArtistView;
