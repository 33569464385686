import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  withStyles,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import {
  chartsTableStyle,
  useStyles as tableStyles,
} from '../../../assets/jss/material-dashboard-pro-react/views/chartsTableStyle';
import { flux } from '../../../util';
import { MOST_PLAYED_ARTISTS } from './graphql';
import WeekPostion from './WeekPosition';

const StyledTableCell = withStyles(chartsTableStyle)(TableCell);

const ArtistChartTable = (props) => {
  const { t } = props;
  const classes = tableStyles();

  const { loading, data } = useQuery(MOST_PLAYED_ARTISTS, {
    variables: {
      frequency: props.chartPeriod,
      limit: props.chartLimit,
      timePeriod: props.timePeriod,
    },
    onCompleted: (data) => {
      props.top({
        rank: data.mostPlayedArtists.edges[0].rank,
        highestChartRank: data.mostPlayedArtists.edges[0].highestChartRank,
        previousPeriodRank: data.mostPlayedArtists.edges[0].previousPeriodRank,
        consecutivePeriodsOnChart: data.mostPlayedArtists.edges[0].consecutivePeriodsOnChart,
        playCount: data.mostPlayedArtists.edges[0].playCount,
        ...data.mostPlayedArtists.edges[0].node,
      });
    },
  });

  if (loading) return null;

  const artistTable = data.mostPlayedArtists.edges.map((edge) => {
    return {
      rank: edge.rank,
      highestChartRank: edge.highestChartRank,
      previousPeriodRank: edge.previousPeriodRank,
      consecutivePeriodsOnChart: edge.consecutivePeriodsOnChart,
      playCount: edge.playCount,
      ...edge.node,
    };
  });

  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <StyledTableCell className={classes.tableBody}>Pos.</StyledTableCell>
            <StyledTableCell colSpan={5} className={classes.tableBody}>
              {t('chartsdetail.artistName')}
            </StyledTableCell>
            <StyledTableCell className={classes.tableBody}>
              {t('chartsdetail.statistics.count')}
            </StyledTableCell>
            <StyledTableCell className={classes.tableBody}>
              {t(`chartsdetail.statistics.${props.chartPeriod}.last`)}
            </StyledTableCell>
            <StyledTableCell className={classes.tableBody}>
              {t('chartsdetail.statistics.peakPosition')}
            </StyledTableCell>
            <StyledTableCell className={classes.tableBody}>
              {t(`chartsdetail.statistics.${props.chartPeriod}.periodOnCharts`)}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {artistTable.map((row, index) => (
            <TableRow key={index} to={`/gallery/artist/${row.gid}`} component={NavLink}>
              <StyledTableCell className={classes.tableBodyWeekPos}>
                <WeekPostion rank={row.rank} lastWeekRank={row.previousPeriodRank} />
              </StyledTableCell>
              <StyledTableCell className={classes.tableBodyimage}>
                <div className={classes.albumImageContainer}>
                  <img alt='' src={row.artwork} className={classes.albumImage} />
                  <div className={classes.albumImageTextContainer}>
                    <Typography className={classes.albumImageText}>
                      {t('chartsdetail.clickToView')}
                    </Typography>
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell colSpan={4}>
                <Typography>
                  <span className={classes.headerTitle}>{row.name}</span>
                </Typography>
              </StyledTableCell>
              <StyledTableCell className={classes.tableBodyBgWhite}>
                {flux(row.playCount)}
              </StyledTableCell>
              <StyledTableCell className={classes.tableBodyBgTransparent}>
                {row.previousPeriodRank || '-'}
              </StyledTableCell>
              <StyledTableCell className={classes.tableBodyBgWhite}>
                {row.highestChartRank}
              </StyledTableCell>
              <StyledTableCell className={classes.tableBodyBgTransparent}>
                {row.consecutivePeriodsOnChart}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ArtistChartTable;
