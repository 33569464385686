import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import '../../style/DownloadNavBar.scss';
import ListItemText from '@material-ui/core/ListItemText';
// @material-ui/icons
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import DownloadNavbarStyle from 'assets/jss/material-dashboard-pro-react/components/DownloadNavbar.jsx';
import { flowRight as compose } from 'lodash';
import { withTranslation } from 'react-i18next';
import Lang from '../lang';
class DownloadNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false });
    }
  }
  render() {
    const { classes, color, t } = this.props;
    const appBarClasses = cx({
      [' ' + classes[color]]: color,
    });

    var brand = (
      <div className={classes.logoMin}>
        <img
          src='https://imagecdn.ekoh.online/unsafe/trim/fit-in/37x30/smart/public-assets/ekoh.jpeg'
          alt='logo'
        />
        Ekoh
      </div>
    );

    return (
      <AppBar position='static' className={classes.appBar + appBarClasses}>
        <div className={classes.navT}>
          <NavLink to='/' className={cx(classes.navLogo)}>
            {brand}
          </NavLink>
          <NavLink
            style={{ fontSize: '14px' }}
            to='/download'
            className={cx(classes.navLink, classes.getPad, {
              [classes.navLinkActive]: this.activeRoute('/download'),
            })}
          >
            <PhoneIphoneIcon className={classes.listItemIcon} />
            <ListItemText
              primary={t('getApp.label')}
              disableTypography={true}
              className={classes.listItemText}
            />
          </NavLink>
          <Lang />
        </div>
      </AppBar>
    );
  }
}

DownloadNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  brandText: PropTypes.string,
};

export default compose(withTranslation(), withStyles(DownloadNavbarStyle))(DownloadNavbar);
