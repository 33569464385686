import React from 'react';

import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';

class RaveButton extends React.Component {
  render() {
    const {
      amount,
      currency,
      currencyCountryCode,
      onSuccess,
      onError,
      email,
      metadata,
      reference,
      title,
      firstName,
      lastName,
    } = this.props;

    const config = {
      public_key: this.props.ravePubKey,
      tx_ref: reference,
      amount: amount,
      currency: currency,
      country: currencyCountryCode,
      payment_options: 'card,mobilemoney,ussd',
      customer: {
        email,
        name: `${firstName} ${lastName}`,
      },
      meta: metadata,
      customizations: {
        title,
        logo: 'https://imagecdn.ekoh.online/unsafe/trim/fit-in/300x300/smart/public-assets/ekoh.jpeg',
      },
    };

    const fwConfig = {
      ...config,
      text: this.props.text,
      callback: (response) => {
        console.log(response);
        if (response.status === 'successful') {
          onSuccess();
        } else {
          onError(response);
        }
        closePaymentModal(); // this will close the modal programmatically
      },
      onClose: () => {},
    };

    return (
      <div>
        {/* <RavePaymentModal
          
        /> */}
        <FlutterWaveButton {...fwConfig} />
      </div>
    );
  }
}

export default RaveButton;
