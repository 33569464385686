import React from 'react';

import * as Sentry from '@sentry/browser';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('error', error, errorInfo);
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return <SnackbarContent color='danger' message={'Error occurred'} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
