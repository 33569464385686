import React from 'react';

import { ApolloClient, gql, from, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import CustomCircularProgress from 'components/CustomCircularProgress/CustomCircularProgress.jsx';
import _ from 'lodash';
import { createNetworkStatusNotifier } from 'react-apollo-network-status';
import { NotificationManager } from 'react-notifications';
import Store from 'store';

import { cache } from './cache';

const typeDefs = gql`
  extend type Query {
    isConnected: Boolean
    playlist: [PlaylistItem]
    notifications: [Notification]
  }

  extend type PlaylistItem {
    id: ID!
    gid: ID!
    name: String!
    singer: String!
    cover: String!
    releaseGid: ID!
  }
`;

const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

export function GlobalLoadingIndicator() {
  const status = useApolloNetworkStatus();

  if (status.numPendingQueries > 0 || status.numPendingMutations > 0) {
    return <CustomCircularProgress />;
  } else {
    return null;
  }
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const userToken = Store.get('userToken');
  if (userToken) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${userToken}`,
        'Apollo-Require-Preflight': true,
      },
    });
  }
  return forward(operation);
});

export const newApolloClient = ({ uri }) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((err) => {
        const { message } = err;
        console.log('err', message);
        if (!_.includes(message, 'Unauthorized')) {
          NotificationManager.error(message, 'Error Message!', 6000);
          Sentry.captureException(err);
        }
      });
    }
    if (networkError) {
      const { statusCode } = networkError;
      if (!statusCode === 401) {
        Sentry.captureException(networkError);
      }
    }
  });

  const httpLink = from([
    link,
    new SentryLink(),
    errorLink,
    authMiddleware,
    new createUploadLink({
      uri: `${uri}`,
    }),
  ]);

  return new ApolloClient({
    cache,
    link: httpLink,
    typeDefs,
  });
};
