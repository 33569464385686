import React from 'react';
import { useQuery } from '@apollo/client';
import Artist from 'components/Artist/Artist.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { flowRight as compose } from 'lodash';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import { GALLERY_ARTISTS } from './graphql.jsx';

class Artists extends React.Component {
  render() {
    const { data } = this.props;

    const { publicArtists, loggedInUser, isLoggedIn } = data;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <GridContainer>
            {_.sortBy(publicArtists, (a) => [a.name]).map((a) => (
              <Artist key={a.id} artist={a} loggedInUser={loggedInUser} isLoggedIn={isLoggedIn} />
            ))}
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}
const GalleryResults = compose(withTranslation())(Artists);

const GalleryView = (props) => {
  const { loading, data } = useQuery(GALLERY_ARTISTS);
  if (loading) return null;
  return <GalleryResults data={data} {...props} />;
};

export default GalleryView;
