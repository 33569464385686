import { gql } from '@apollo/client';

const CREATE_OR_UPDATE_RELEASE = gql`
  mutation CreateOrUpdateRelease(
    $email: String!
    $album: String!
    $albumArt: Upload!
    $albumArtist: String!
    $artistType: ArtistType!
    $gender: Gender!
    $country: String!
    $genre: String!
    $price: ReleaseTier!
    $tracks: [UploadTrack]
    $year: Int!
  ) {
    upsertRelease(
      email: $email
      album: $album
      albumArt: $albumArt
      albumArtist: $albumArtist
      artistType: $artistType
      gender: $gender
      country: $country
      genre: $genre
      price: $price
      tracks: $tracks
      year: $year
    ) {
      id
      gid
      genre
      priceCategory {
        id
        tier
        value
      }
      media {
        id
        artwork(height: 300, width: 300)
        trackCount
        tracks {
          id
          gid
          name
          artists
          duration
          position
        }
      }
    }
  }
`;

const GET_RELEASE_BY_GID = gql`
  query GetRelease($gid: ID!) {
    releasePrices {
      id
      tier
      value
    }
    localeCurrency {
      currency
    }
    isLoggedIn
    shoppingCart {
      id
      gid
      name
      artists {
        id
        name
      }
      artwork(height: 80, width: 80)
      priceCategory {
        id
        tier
        value
      }
    }
    releaseMetaImage: releaseByGid(gid: $gid) {
      id
      artwork(height: 314, width: 600)
    }
    releaseByGid(gid: $gid) {
      id
      gid
      name
      day
      month
      year
      genre
      playCount
      artwork(height: 300, width: 300)
      priceCategory {
        id
        tier
        value
      }
      artists {
        id
        name
        gid
      }
      media {
        id
        artwork(height: 300, width: 300)
        trackCount
        tracks {
          id
          gid
          name
          artwork(height: 300, width: 300)
          artists
          duration
          position
        }
      }
    }
  }
`;

const GET_PURCHASED_RELEASES = gql`
  {
    purchasedReleases {
      id
      gid
      name
      year
      genre
      playCount
      country {
        id
        code
      }
      priceCategory {
        id
        tier
        value
      }
      artists {
        id
        name
      }
      media {
        id
        artwork(height: 300, width: 300)
        trackCount
      }
    }
  }
`;

const MY_RELEASES = gql`
  query MyReleases {
    myReleases {
      id
      gid
      name
      day
      month
      year
      genre
      playCount
      artwork(height: 300, width: 300)
      priceCategory {
        id
        tier
        value
      }
      artists {
        id
        name
        gid
      }
      media {
        id
        artwork(height: 300, width: 300)
        trackCount
        tracks {
          id
          gid
          name
          artwork(height: 300, width: 300)
          artists
          duration
          position
        }
      }
    }
  }
`;

export { CREATE_OR_UPDATE_RELEASE, GET_RELEASE_BY_GID, GET_PURCHASED_RELEASES, MY_RELEASES };
