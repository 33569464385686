import { gql } from '@apollo/client';

const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $password: String!
    $confirmPassword: String!
    $termsAndConditionsAccepted: Boolean!
  ) {
    createUser(
      email: $email
      password: $password
      confirmPassword: $confirmPassword
      termsAndConditionsAccepted: $termsAndConditionsAccepted
    ) {
      id
    }
  }
`;

const LOGIN_USER = gql`
  mutation LoginUser($email: String!, $password: String!) {
    loginUser(email: $email, password: $password) {
      email
      gid
      accessToken
      firebaseToken
    }
  }
`;

const CONFIRM_VERIFICATION = gql`
  mutation ConfirmVerification($token: String!) {
    confirmVerification(token: $token) {
      id
      verified
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!, $confirmPassword: String!) {
    resetPassword(token: $token, password: $password, confirmPassword: $confirmPassword) {
      id
    }
  }
`;

const REQUEST_PASSWORD_CHANGE = gql`
  mutation RequestPasswordChange($email: String!) {
    requestPasswordChange(email: $email) {
      id
    }
  }
`;

export { CREATE_USER, LOGIN_USER, CONFIRM_VERIFICATION, REQUEST_PASSWORD_CHANGE, RESET_PASSWORD };
