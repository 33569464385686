import { alpha, makeStyles } from '@material-ui/core/styles';

export const searchBarStyle = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.65),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.75),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '75%',
      margin: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    width: '100%',
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1.5, 1.5, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '85%',
      margin: 'auto',
      '&:focus': {
        width: '90%',
      },
    },
  },
}));

export const optionStyle = makeStyles(() => ({
  root: {
    width: '-webkit-fill-available',
    zIndex: '333',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '98%',
    margin: 'auto',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ff9800',
    },
    padding: '.5em',
    marginTop: '.5em',
    zIndex: '36',
  },
  wrapper: {
    width: '-webkit-fill-available',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  artistImage: {
    zIndex: '36',
    borderRadius: '50%',
  },
  info: {
    marginLeft: '1em',
    marginTop: '.5em',
    zIndex: '36',
  },
  arrow: {
    display: 'grid',
    placeItems: 'center',
    zIndex: '36',
  },
  options: {
    position: 'absolute',
    backgroundColor: '#fff',
    top: '3.5em',
    left: '0',
    zIndex: '33366',
    width: '100%',
    boxShadow: '0px 0px 2px 0px #000',
    maxHeight: '400px',
    overflow: 'auto',
  },
}));
