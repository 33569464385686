import React, { useState, useRef } from 'react';

import Currency from 'react-currency-formatter';
import Moment from 'react-moment';
import { compose } from 'recompose';

import 'moment-timezone';
import 'moment/locale/fr';

import Table from 'components/Table/Table.jsx';
import Formsy from 'formsy-react';
import { NotificationManager } from 'react-notifications';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';

// material-ui-icons
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import Assignment from '@material-ui/icons/Assignment';
import Face from '@material-ui/icons/Face';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/FormsyInput/FormsyInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import FormsyPictureUpload from 'components/FormsyUpload/FormsyPictureUpload.jsx';
import CountryDropdown from 'components/FormsyDropdown/CountryDropdown.jsx';
import FormsyPhoneNumber from 'components/FormsyPhoneNumber/FormsyPhoneNumber.jsx';
import { RenderForError } from 'hoc/index.jsx';

import * as i18nIsoCountries from 'i18n-iso-countries';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { withTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_PROFILE, UPDATE_USER, VERIFY_EMAIL } from './graphql.jsx';

const UserProfile = (props) => {
  const { classes, data, t, i18n } = props;

  const { loggedInUser, payableTransactions } = data;

  const { languages } = i18n;

  const userForm = useRef(null);

  const [phoneValue, setPhoneValue] = useState(loggedInUser.phoneNumber);

  return (
    <div>
      <GridContainer justify='center' alignItems='center'>
        <GridItem xs={12} sm={6} md={6} lg={10}>
          <Card product>
            <CardBody>
              <Formsy
                onValidSubmit={(d) => {
                  const payload = {
                    ...d,
                    profilePic: d.profilePic ? d.profilePic.file : null,
                    country: d.country ? d.country.code : null,
                  };
                  props.updateUser({ variables: payload });
                }}
                ref={userForm}
              >
                <GridContainer>
                  <GridItem xs={12} md={6}>
                    <GridItem xs={12}>
                      <CustomInput
                        id='firstName'
                        labelText={t('common.form.firstname.text')}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position='start' className={classes.inputAdornment}>
                              <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: `${t('common.form.firstname.text')} ...`,
                        }}
                        value={loggedInUser ? loggedInUser.firstName : ''}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        id='lastName'
                        labelText={t('common.form.lastname.text')}
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position='start' className={classes.inputAdornment}>
                              <Face className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          ),
                          placeholder: `${t('common.form.lastname.text')} ...`,
                        }}
                        value={loggedInUser ? loggedInUser.lastName : ''}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        id='userName'
                        labelText={t('common.form.username.text')}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <AlternateEmail />
                            </InputAdornment>
                          ),
                          placeholder: `${t('common.form.username.text')} ...`,
                        }}
                        value={loggedInUser ? loggedInUser.userName : ''}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CountryDropdown
                        id='country'
                        labelText={t('common.form.country.text')}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={
                          loggedInUser && loggedInUser.country
                            ? {
                                code: loggedInUser.country.code,
                                label: i18nIsoCountries.getName(
                                  loggedInUser.country.code,
                                  languages[0],
                                ),
                              }
                            : { code: '', label: '' }
                        }
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormsyPhoneNumber
                        id='phoneNumber'
                        formControlProps={{
                          fullWidth: true,
                          className: classes.customFormControlClasses,
                        }}
                        inputProps={{
                          placeholder: `${t('common.form.phoneNumber.placeholder')}`,
                        }}
                        value={phoneValue}
                        onChange={setPhoneValue}
                        validations={{
                          matchRegexp: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                        }}
                        validationError={t('common.form.phoneNumber.validation')}
                      />
                    </GridItem>
                  </GridItem>
                  <GridItem xs={12} md={5}>
                    <FormsyPictureUpload
                      id='profilePic'
                      value={{
                        imagePreviewUrl: loggedInUser ? loggedInUser.profilePic : '',
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <Button color='warning' onClick={() => userForm.current.reset()}>
                  {t('common.form.reset.text')}
                </Button>
                <Button color='warning' href='/auth/requestPasswordChange'>
                  {t('common.form.changepassword.text')}
                </Button>
                {loggedInUser && !loggedInUser.verified && (
                  <Button color='danger' onClick={() => props.verifyUser()}>
                    {t('common.form.verifyemail.text')}
                  </Button>
                )}
                <Button type='submit' color='warning'>
                  {t('common.form.updateprofile.text')}
                </Button>
                <Clearfix />
              </Formsy>
            </CardBody>
          </Card>
        </GridItem>
        {payableTransactions && payableTransactions.length > 0 && (
          <GridItem xs={12}>
            <Card>
              <CardHeader color='warning' icon>
                <CardIcon color='warning'>
                  <Assignment />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>My Purchases</h4>
              </CardHeader>
              <CardBody className={classes.customCardContentClass}>
                <Table
                  tableShopping
                  hover
                  tableHead={[
                    t('common.form.id.text'),
                    t('common.form.purchasedDate.text'),
                    t('common.form.item.text'),
                    t('common.form.amount.text'),
                  ]}
                  tableData={payableTransactions.map((trans) => [
                    trans.id,
                    <Moment locale={languages[0]} format='ddd, D MMM YYYY, HH:mm:ss'>
                      {trans.transactionDate}
                    </Moment>,
                    trans.item.name,
                    <Currency quantity={trans.amount} currency={trans.currency} />,
                  ])}
                  coloredColls={[0, 4]}
                  colorsColls={['primary', 'success']}
                />
              </CardBody>
            </Card>
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
};

const UserProfileResults = compose(
  withStyles(dashboardStyle),
  RenderForError((props) => props.error),
)(UserProfile);

const UserProfileView = (props) => {
  const { loading, data } = useQuery(GET_USER_PROFILE);
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: ['UserProfile', 'SIDEBAR_VIEW', 'UserProfileStatus'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      NotificationManager.success('', props.t('common.form.notification.updateComplete'));
    },
  });

  const [verifyUser] = useMutation(VERIFY_EMAIL, {
    onCompleted: (r) => {
      NotificationManager.success(
        `${r.verifyEmail.email}`,
        props.t('common.form.notification.emailSent'),
        6000,
      );
    },
  });

  if (loading) return null;

  return (
    <UserProfileResults {...props} updateUser={updateUser} data={data} verifyUser={verifyUser} />
  );
};

export default compose(withTranslation())(UserProfileView);
