import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import defaultImage from 'assets/img/default-avatar.png';
import customInputStyle from 'assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx';
import classNames from 'classnames';
import { withFormsy } from 'formsy-react';
import { withTranslation } from 'react-i18next';
import { compose, withProps } from 'recompose';

class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.handleImageChange = this.handleImageChange.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    e.persist();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      e.target.validity.valid &&
        this.props.setValue({ file: file, imagePreviewUrl: reader.result });
    };
    reader.readAsDataURL(file);
  }

  render() {
    const { classes, t } = this.props;
    const value = this.props.value;

    const showLabelText = this.props.isFormSubmitted && !this.props.isValid;
    const showError = this.props.isFormSubmitted && !this.props.isValid;

    const labelClasses = classNames({
      [' ' + classes.labelRootError]: showError,
      [' ' + classes.labelRootSuccess]: !showError,
    });

    return (
      <div className='picture-container'>
        <span className={classes.labelRoot + ' ' + labelClasses}>
          {showLabelText ? `*${t('common.form.validation.required')}` : null}
        </span>
        <div className='picture'>
          <img
            src={value && value.imagePreviewUrl ? value.imagePreviewUrl : defaultImage}
            className='picture-src'
            alt='...'
          />
          <input type='file' accept='image/*' onChange={(e) => this.handleImageChange(e)} />
        </div>

        <h6 className='description'>{t('common.input.picture.text')}</h6>
      </div>
    );
  }
}

const enhance = compose(
  withTranslation(),
  withStyles(customInputStyle),
  withProps((props) => ({ name: props.id, innerRef: null })),
  withFormsy,
);

export default enhance(PictureUpload);
