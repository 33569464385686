import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

class BuyMusic extends Component {
  render() {
    return (
      <Card>
        <CardBody>
          <List>
            <ListItem>
              - Click on Add to cart on the track/compilation you wish to purchase
            </ListItem>
            <ListItem>
              - Go to cart by clicking on the cart icon on the top right side of the website
            </ListItem>
            <ListItem>
              - Confirm your purchase selection, choose payment method and click Checkout
            </ListItem>
            <ListItem>
              - You will be redirected to our secure payment portal to complete the transaction.
              Once complete, you will be redirected back to the Gallery
            </ListItem>
            <ListItem>
              - You can access this song anytime (incl. when you are offline) if you click on
              Purchased under menu
            </ListItem>
          </List>
        </CardBody>
      </Card>
    );
  }
}

export default BuyMusic;
