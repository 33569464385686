import React from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SalesStyle from 'assets/jss/material-dashboard-pro-react/components/salesTableStyle.jsx';
import Currency from 'react-currency-formatter';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import Status from '../Status/Status';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable({ items, loadMore }) {
  const { t, i18n } = useTranslation();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(t('sales.salesTable.amount'));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const classes = SalesStyle();
  const handleTableData = (column, item) => {
    if (column.id === 'id') {
      return <p className={classes.idClass}>{item[column.id]}</p>;
    } else if (column.id === 'status') {
      if (item.status === 'unpaid') {
        return <Status type='unpaid' />;
      } else if (item.status === 'error') {
        return <Status type='error' />;
      } else {
        return <Status />;
      }
    } else if (column.id === 'amount') {
      return <Currency quantity={item.amount} currency={item.currency} />;
    } else if (column.id === 'transactionDate') {
      return (
        <Moment
          date={item[column.id]}
          locale={i18n.languages[0]}
          format='ddd, D MMM YYYY, HH:mm:ss'
        />
      );
    } else {
      return item[column.id];
    }
  };
  const scrollEvent = (e) => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      loadMore();
    }
  };
  const columns = [
    {
      id: 'id',
      label: t('sales.salesTable.id'),
    },
    { id: 'item', label: t('sales.salesTable.item') },
    { id: 'status', label: t('sales.salesTable.status') },
    {
      id: 'transactionDate',

      label: t('sales.salesTable.date'),
    },
    { id: 'amount', label: t('sales.salesTable.amount') },
  ];
  const TableHeadColumns = (props) => {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const classes = SalesStyle();
    return (
      <TableHead>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return items === undefined || items.length === 0 ? (
    <div></div>
  ) : (
    <Paper style={{ borderRadius: '15px' }}>
      <div className={classes.tableHeader}>{t('sales.salesTable.title')}</div>

      <Paper className={classes.root}>
        <TableContainer id='table' className={classes.container} onScroll={(e) => scrollEvent(e)}>
          <Table aria-labelledby='tableTitle' stickyHeader aria-label='sticky table'>
            <TableHeadColumns
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={items.length}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy)).map((row) => {
                return (
                  <TableRow key={row.id}>
                    {columns.map((column) => {
                      const value = handleTableData(column, row);

                      return <TableCell key={column.id}>{value}</TableCell>;
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Paper>
  );
}
