import React from 'react';

import playSvg from 'assets/img/play-circle.svg';

import { overidesStyles } from '../../assets/jss/material-dashboard-pro-react/components/overides';
const PlayIcon = () => {
  const overide = overidesStyles();
  return (
    <div className={overide.containerIcon}>
      <div
        style={{
          position: 'absolute',
          width: 'fit-content',
          height: 'fit-content',
          left: '35%',
          bottom: '35%',
          zIndex: '3333333',
        }}
      >
        <img src={playSvg} alt='play' width='20' height='20' style={{ zIndex: '3333333' }} />
      </div>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '95%',
          top: '0',
          backgroundColor: '#00000091',
        }}
      ></div>
    </div>
  );
};

export default PlayIcon;
