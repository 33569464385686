import { primaryColor } from 'assets/jss/material-dashboard-pro-react.jsx';
const chartStyle = {
  "@import url('https://fonts.googleapis.com/css2?family=Enriqueta:wght@700&family=Montserrat:wght@900&display=swap')": true,
  chartContainer: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    alignItems: 'center',
    height: '100%',
  },
  chartTitle: {
    '& h1': {
      fontWeight: '800',
      alignItems: 'center',
      fontFamily: 'Montserrat',
      padding: '10px 30px  10px',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      flexDirection: 'row',
      flex: '0.5',
      width: '50%',
      margin: 'auto',
      fontSize: '5rem',
      textShadow:
        '0 2px 2px #fd9800,-2px 5px 1px #fd9800,-4px 8px 0px #fd9800,-6px 11px 0px #fd9800,-8px 14px 0px #fac77b,-10px 17px 0px #f8d39c,-12px 20px 0px #f5e2c6,-14px 23px 0px rgb(253, 246, 235)',
      color: '#000',
      backgroundColor: 'white',
      border: '20px solid rgba(0,0,0,0.9)',
      textAlign: 'center',
      '@media (max-width:1024px)': {
        width: '100%',
      },
      '@media (max-width:580px)': {
        fontSize: '3rem',
        padding: '1rem 0',
      },
      '@media (max-width:380px)': {
        fontSize: '1.5rem',
        padding: '1.4rem 0.5rem',
      },
    },
  },
  chartSlider: {
    width: '600px',
    height: '50vh',
    background: primaryColor[0],
    maxHeight: '350px',
    overflow: 'hidden',
    borderRadius: '10px',
    marginTop: '20px',
    position: 'relative',
    boxShadow:
      'rgba(240, 162, 46, 0.4) 5px 5px, rgba(240, 172, 46, 0.3) 10px 10px, rgba(240, 179, 46, 0.2) 15px 15px, rgba(240, 162, 46, 0.1) 20px 20px, rgba(240, 162, 46, 0.05) 25px 25px',
    '@media (max-width:580px)': {
      width: '70vw',
    },
  },
  chartShow: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  slide: {
    zIndex: '1',
    width: '100%',
    height: '100%',
  },
  slider: {
    position: 'absolute',
    top: '0px',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: 'inherit',
      height: 'inherit',
      objectFit: 'fill',
      '@media (max-width:580px)': {
        objectFit: 'cover',
      },
    },
  },
  content: {
    position: 'absolute',
    bottom: '-15px',
    left: '20px',
    zIndex: '10',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1600px',
    width: 'calc(100%-100px)',
    color: '#000',
    '@media (max-width:580px)': {
      left: '50%',
      bottom: '20px',
    },
    '& h2': {
      color: 'black',
      fontWeight: 'bold',
      padding: '5px 10px',
      boxShadow:
        'rgba(252, 167, 55, 0.5) 0px -23px 25px 0px inset, rgba(243, 102, 20, 0.5) 0px -36px 30px 0px inset, rgba(255, 137, 41, 0.1) 0px -79px 40px 0px inset, rgba(245, 165, 60, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(252, 138, 31, 0.09) 0px 16px 8px, rgba(243, 110, 21, 0.09) 0px 32px 16px',
      borderRadius: '20px',
      '@media (max-width:580px)': {
        fontSize: '20px',
      },
    },
  },
  slideButton: {
    position: 'absolute',
    bottom: '20px',
    right: '20px',
    display: 'flex',
    zIndex: '100',
    '@media (max-width:580px)': {
      bottom: '5px',
      right: '0',
    },
  },
  dButton: {
    width: '50px',
    height: '50px',
    color: '#fff',
    cursor: 'pointer',
    background: '#000d1a',
    borderRadius: '50px',
    padding: '10px',
    marginRight: '1rem',
    userSelect: 'none',
    transition: '0.3s',
    '@media (max-width:580px)': {
      width: '25px',
      height: '30px',
      padding: '0px',
    },
    '&:hover ,&:focus': {
      background: primaryColor[0],
      transform: 'scale(1.05)',
    },
  },
  chartFlowChart: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  chartTop10: {
    '& h3': {
      width: '250px',
      backgroundColor: 'black',
      color: 'white',
      border: '2px solid white',
      padding: '0.5rem 2rem',
      margin: 'auto',
      marginTop: '2rem',
      fontSize: '3rem',
      fontWeight: '800',
      textAlign: 'center',
      alignItems: 'center',
      '@media (max-width:380px)': {
        width: '200px',
        fontSize: '2.5rem',
        padding: '0.2rem 1rem',
      },
    },
  },
  chartNumber: {
    transform: 'rotate(45deg)',
    backgroundColor: 'black',
    width: '310px',
    opacity: '0.9',
    height: '200px',
    position: 'absolute',
    top: '0',
    right: '0',
    marginTop: '-70px',
    marginLeft: '-15px',
    marginRight: '-150px',
    '@media (max-width:500px)': {
      width: '290px',
    },
    '@media (max-width:380px)': {
      width: '290px',
    },
  },
  chartTop: {
    margin: 'auto',
    justifyContent: 'space-around',
    width: '80%',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    '& li': {
      backgroundColor: 'white',
      margin: '10px 0',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '20px',
      width: '300px',
      overflow: 'hidden',
      '@media (max-width:380px)': {
        width: '250px',
      },
      '@media (max-width:320px)': {
        width: '120px',
      },
      '@media (max-width:460px)': {
        width: '230px',
      },
      '&:hover': {
        transform: 'scale(1.01)',
        boxShadow: '1px 1px 4px 2px orange',
      },
    },
    '@media (max-width:380px)': {
      width: '100%',
    },
    '& h1': {
      fontSize: '150px',
      color: primaryColor[0],
      position: 'absolute',
      top: '0',
      right: '0',
      marginTop: '-20px',
      marginRight: '0px',
      fontWeight: '800',
      '@media (max-width:430px)': {
        fontSize: '130px',
        marginTop: '-10px',
        marginRight: '5px',
      },
      '@media (max-width:380px)': {
        fontSize: '110px',
        marginTop: '-10px',
        marginRight: '20px',
      },
      '@media (max-width:365px)': {
        fontSize: '110px',
        marginTop: '-10px',
        marginRight: '20px',
      },
    },
    '& h5': {
      margin: '20px',
      alignItems: 'center',
      position: 'relative',
      zIndex: '1',
      transition: 'color 600ms ease-in-out',
      overflow: 'hidden',
      appearance: 'none',
      backgroundColor: '#FFFFFF',
      borderRadius: '40em',
      borderStyle: 'none',
      boxShadow: '#fddc9d 0 -12px 6px inset,#fddc9d 0 0.5px 0px inset',
      boxSizing: 'border-box',
      color: '#000000',
      cursor: 'pointer',
      display: 'inline-block',
      fontSize: '3rem',
      width: '250px',
      fontWeight: '700',
      letterSpacing: '-.24px',
      outline: 'none',
      padding: '0.2rem 1rem',
      quotes: 'auto',
      textAlign: 'center',
      textDecoration: 'none',
      userSelect: 'none',
      webkitUserSelect: 'none',
      touchAction: 'manipulation',
      fontFamily: 'Enriqueta',
      '@media (max-width:480px)': {
        margin: '10px 0',
        fontSize: '2rem',
        padding: '1rem 0.5rem',
        width: '200px',
      },
      '@media (max-width:380px)': {
        width: '150px',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: primaryColor[0],
        zIndex: -1,
        transform: 'scaleX(0)',
        borderBottomRightRadius: '50px',
        borderTopRightRadius: '50px',
        transformOrigin: 'left',
        transition: 'transform 600ms ease-in-out',
      },
      '&:hover': {
        '&:before': {
          transform: 'scaleX(1.5)',
        },
      },
    },
    '& img': {
      width: '200px',
      objectFit: 'cover',
      height: '300px',
      boxShadow: '1px 1px 0 #666, 5px 5px 0 #444, 6px 6px 6px rgba(0,0,0,0.6)',
      '@media (max-width:380px)': {
        width: '150px',
        height: '150px',
        objectFit: 'fill',
      },
    },
  },
};
export default chartStyle;
