import { withApollo } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';

import { TOGGLE_CART_ITEM, CLEAR_CART } from './graphql.jsx';

const addToShoppingCart = async (client, rel) => {
  await client.mutate({
    mutation: TOGGLE_CART_ITEM,
    variables: { gid: rel.gid },
    awaitRefetchQueries: true,
    refetchQueries: ['NavbarView', 'PaidReleaseView', 'ShoppingCartView'],
  });
};

const clearCart = async (client) => {
  await client.mutate({
    mutation: CLEAR_CART,
    awaitRefetchQueries: true,
    refetchQueries: ['NavbarView', 'PaidReleaseView', 'ShoppingCartView'],
  });
};

const EnhanceWithCartControls = compose(
  withApollo,
  withProps((props) => ({
    toggleToShoppingCart: (rel) => addToShoppingCart(props.client, rel),
    clearCart: () => clearCart(props.client),
  })),
);

export { EnhanceWithCartControls };
