import { drawerWidth, drawerMiniWidth } from 'assets/jss/material-dashboard-pro-react.jsx';

const playerStyle = {
  playerPanel: {
    left: drawerWidth,
  },
  playerPanelMini: {
    left: drawerMiniWidth,
  },
};

export default playerStyle;
