import React, { useState, useRef, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';

import withStyles from '@material-ui/core/styles/withStyles';
import chartStyle from 'assets/jss/material-dashboard-pro-react/views/chartStyle';
import { compose } from 'recompose';

import FaArrowCircleLeft from 'react-icons/lib/fa/arrow-circle-left';
import FaArrowCircleRight from 'react-icons/lib/fa/arrow-circle-right';

import NoData from '../../../components/NoData/NoData';
import { MOST_PLAYED_TRACKS } from './graphql';
import { MOST_PLAYED_ARTISTS } from './graphql';
import { MOST_PLAYED_RELEASES } from './graphql';

const WeeklyCharts = (props) => {
  const { classes } = props;
  const [t] = useTranslation();
  const [current, setCurrent] = useState(0);
  const dataLimit = 3;
  const length = dataLimit * 3;

  const timeout = useRef(null);

  useEffect(() => {
    const next = () => {
      setCurrent((current) => (current === length - 1 ? 0 : current + 1));
    };
    timeout.current = setTimeout(next, 2000);
    return function () {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [current, length]);
  const next = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prev = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const { loading: tracksLoading, data: tracks } = useQuery(MOST_PLAYED_TRACKS, {
    variables: {
      frequency: 'weekly',
      limit: dataLimit,
    },
  });
  const { loading: artistsLoading, data: artists } = useQuery(MOST_PLAYED_ARTISTS, {
    variables: {
      frequency: 'weekly',
      limit: dataLimit,
    },
  });
  const { loading: albumsLoading, data: albums } = useQuery(MOST_PLAYED_RELEASES, {
    variables: {
      frequency: 'weekly',
      limit: dataLimit,
    },
  });
  if (tracksLoading || artistsLoading || albumsLoading) return null;
  const tracksInfo = tracks.mostPlayedTracks.edges.map((edge) => edge.node);
  const artistsInfo = artists.mostPlayedArtists.edges.map((edge) => edge.node);
  const albumsInfo = albums.mostPlayedReleases.edges.map((edge) => edge.node);

  const info = _.concat(tracksInfo, artistsInfo, albumsInfo);

  if (!Array.isArray(info) || info.length <= 0) {
    return (
      <NoData
        link1='/gallery/charts/weekly/tracks'
        link2='/gallery/charts/weekly/artist'
        link3='/gallery/charts/weekly/albums'
      />
    );
  }
  return (
    <div className={classes.chartContainer}>
      <div className={classes.chartSlider}>
        <div className={classes.chartShow}>
          {info.map((slide, index) => {
            return (
              <div className={classes.slide} key={index}>
                {index === current && (
                  <div className={classes.slider}>
                    <img src={slide.artwork} alt='' />
                    <div className={classes.content}>
                      <h2>{slide.name}</h2>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          <div className={classes.slideButton}>
            <FaArrowCircleLeft className={classes.dButton} onClick={next} />
            <FaArrowCircleRight className={classes.dButton} onClick={prev} />
          </div>
        </div>
      </div>

      <div className={classes.chartFlowChart}>
        <div className={classes.chartTop10}>
          <h3>Top 10</h3>
        </div>

        <List className={classes.chartTop}>
          <NavLink to='/gallery/charts/weekly/tracks'>
            <ListItem>
              <img src={tracksInfo[0].artwork} alt='' />
              <h5>{t('charts.track')}</h5>
              <div className={classes.chartNumber}></div>
              <h1>1</h1>
            </ListItem>
          </NavLink>
          <NavLink to='/gallery/charts/weekly/artist'>
            <ListItem>
              <img src={artistsInfo[0].artwork} alt='' />
              <h5>{t('charts.artist')}</h5>
              <div className={classes.chartNumber}></div>
              <h1>1</h1>
            </ListItem>
          </NavLink>
          <NavLink to='/gallery/charts/weekly/albums'>
            <ListItem>
              <img src={albumsInfo[0].artwork} alt='' />
              <h5>{t('charts.album')}</h5>
              <div className={classes.chartNumber}></div>
              <h1>1</h1>
            </ListItem>
          </NavLink>
        </List>
      </div>
    </div>
  );
};

export default compose(withStyles(chartStyle))(WeeklyCharts);
