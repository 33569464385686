import { EnhanceNotifier } from 'util/Notification/Notification.jsx';
import React from 'react';

import { compose } from 'recompose';

import { useQuery } from '@apollo/client';

import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Notifications from '@material-ui/icons/Notifications';

import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import Hidden from '@material-ui/core/Hidden';
import Popper from '@material-ui/core/Popper';

import Button from 'components/CustomButtons/Button.jsx';

import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx';

import { GET_NOTIFICATIONS } from './graphql.jsx';

class NotificationsLink extends React.Component {
  state = {
    notificationOpen: false,
  };

  handleNotificationClick = () => {
    this.setState({ notificationOpen: !this.state.notificationOpen });
  };
  handleNotificationClose = () => {
    this.setState({ notificationOpen: false });
  };

  handleNotificationLinkClick = (val) => {
    this.props.notificationRead(val.id);
    this.setState({ notificationOpen: false });
  };

  render() {
    const { classes } = this.props;

    const { notificationOpen } = this.state;

    const managerClasses = classNames({
      [classes.managerClasses]: true,
    });

    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);

    return (
      <div className={managerClasses}>
        <Button
          color='transparent'
          justIcon
          aria-label='Notifications'
          aria-owns={notificationOpen ? 'menu-list' : null}
          aria-haspopup='true'
          onClick={this.handleNotificationClick}
          className={classes.buttonLink}
          muiClasses={{
            label: '',
          }}
          ref={(node) => {
            this.anchorEl = node;
          }}
        >
          <Notifications className={classes.headerLinksSvg + ' ' + classes.links} />
          {this.props.data &&
            this.props.data.notifications &&
            this.props.data.notifications.length > 0 && (
              <span className={classes.notifications}>{this.props.data.notifications.length}</span>
            )}
          <Hidden mdUp implementation='css'>
            <span onClick={this.handleNotificationClick} className={classes.linkText}>
              {'Notification'}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={notificationOpen}
          anchorEl={this.anchorEl}
          transition
          disablePortal
          placement='bottom'
          className={classNames({
            [classes.popperClose]: !notificationOpen,
            [classes.pooperResponsive]: true,
            [classes.pooperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id='menu-list' style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={this.handleNotificationClose}>
                  <MenuList role='menu'>
                    {this.props.data &&
                      this.props.data.notifications &&
                      this.props.data.notifications.length > 0 &&
                      this.props.data.notifications.map((val, i) => (
                        <MenuItem
                          key={i}
                          onClick={() => this.handleNotificationLinkClick(val)}
                          className={dropdownItem}
                        >
                          <Link to={val.message}>{val.title}</Link>
                        </MenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

const NotificationsResults = compose(
  EnhanceNotifier,
  withStyles(adminNavbarLinksStyle),
)(NotificationsLink);

const NotificationsView = (props) => {
  const { loading, data } = useQuery(GET_NOTIFICATIONS);
  if (loading) return null;
  return <NotificationsResults {...props} data={data} />;
};

export default NotificationsView;
