import Auth from 'layouts/Auth.jsx';
import Download from 'layouts/Download.jsx';
import Gallery from 'layouts/Gallery.jsx';
import Help from 'layouts/Help.jsx';

var indexRoutes = [
  { path: '/auth', name: 'Auth', component: Auth },
  { path: '/gallery', name: 'Home', component: Gallery },
  { path: '/help', name: 'Help', component: Help },
  { path: '/download', name: 'Download', component: Download },
];

export default indexRoutes;
