import React, { Component } from 'react';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

class ContactUs extends Component {
  render() {
    return (
      <Card>
        <CardBody>Email: support@ekoh.online</CardBody>
      </Card>
    );
  }
}

export default ContactUs;
