import React from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import { GlobalLoadingIndicator, newApolloClient } from 'apollo/apolloConfig';
import theme from 'assets/jss/theme.jsx';
import GlobalErrorFallback from 'components/Error/GlobalErrorComponent';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { NotificationContainer } from 'react-notifications';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import '../src/style/DownloadNavBar.scss';
import 'assets/scss/material-dashboard-pro-react.scss?v=1.5.0';
import indexRoutes from 'routes/index.jsx';
import 'react-notifications/lib/notifications.css';

import TagManager from 'react-gtm-module';

import { ApolloProvider } from '@apollo/client';

import { FirebaseProviderView } from './firebase/provider';

import 'i18n/i18next';
import * as serviceWorker from './serviceWorker';
const hist = createBrowserHistory();

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
    auth: process.env.REACT_APP_GTM_ENV_AUTH,
    preview: process.env.REACT_APP_GTM_PREV,
  });
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
});

const client = newApolloClient({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT });

serviceWorker.register();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalErrorFallback>
      <ApolloProvider client={client}>
        <GlobalLoadingIndicator />
        <FirebaseProviderView>
          <Router history={hist}>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return <Route path={prop.path} component={prop.component} key={key} />;
              })}
              <Redirect from='/' to='/gallery/index' />
            </Switch>
          </Router>
          <NotificationContainer />
        </FirebaseProviderView>
      </ApolloProvider>
    </GlobalErrorFallback>
  </ThemeProvider>,
  document.getElementById('root'),
);

// ReactDOM.render(<div>Hello</div>, document.getElementById("root"));
