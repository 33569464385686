import React from 'react';

import CustomInput from 'components/CustomInput/CustomInput.jsx';
import { withFormsy } from 'formsy-react';
import { compose, withProps } from 'recompose';

class MyInput extends React.Component {
  render() {
    const inputProps = {
      required: this.props.isRequired,
      value: this.props.value || '',
      type: 'text',
      onChange: (e) => this.props.setValue(e.currentTarget.value),
      ...this.props.inputProps,
    };

    const labelProps = {
      required: this.props.isRequired,
      ...this.props.labelProps,
    };

    const errorMessage = this.props.errorMessage;
    const showError = this.props.isFormSubmitted && !this.props.isValid;

    return (
      <div>
        <CustomInput
          {...this.props}
          labelProps={labelProps}
          inputProps={inputProps}
          error={this.props.showError}
        />
        <span>{showError ? errorMessage : ''}</span>
      </div>
    );
  }
}

const enhance = compose(
  withProps((props) => ({ name: props.id, innerRef: null })),
  withFormsy,
);

export default enhance(MyInput);
