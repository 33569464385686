import React, { Component } from 'react';

import { useQuery } from '@apollo/client';
import GridContainer from 'components/Grid/GridContainer.jsx';
import FreeRelease from 'components/Release/FreeRelease.jsx';
import PaidRelease from 'components/Release/PaidRelease.jsx';

import { GET_COUNTRY_RELEASES } from './graphql.jsx';

class Country extends Component {
  render() {
    const {
      data: { countryReleases },
    } = this.props;

    return (
      <GridContainer>
        {countryReleases.map((rel) => {
          let Comp = rel.priceCategory.value === 0 ? FreeRelease : PaidRelease;
          return <Comp key={rel.id} rel={rel} />;
        })}
      </GridContainer>
    );
  }
}

const CountryView = (props) => {
  const { loading, data } = useQuery(GET_COUNTRY_RELEASES, {
    variables: { id: props.match.params.id },
  });

  if (loading) return null;
  return <Country data={data} {...props} />;
};

export default CountryView;
