import React from 'react';

// material-ui components
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function CustomInput({ ...props }) {
  const {
    classes,
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    dropdownList,
    error,
    success,
    helpText,
    onChange,
    value,
  } = props;

  var labelClasses = cx({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  });

  var formControlClasses = classes.selectFormControl;
  if (formControlProps !== undefined) {
    formControlClasses += ' ' + formControlProps.className;
  }

  if (inputProps !== undefined) {
    formControlClasses =
      formControlClasses +
      ' ' +
      cx({
        [classes.inputWithAdornment]:
          (inputProps.startAdornment !== undefined || inputProps.endAdornment !== undefined) &&
          labelText === undefined,
      });
  }
  if (inputProps !== undefined) {
    labelClasses =
      labelClasses +
      ' ' +
      cx({
        [classes.labelWithAdornment]: inputProps.endAdornment !== undefined,
      });
  }

  var helpTextClasses = cx({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });
  return (
    <FormControl
      {...formControlProps}
      className={formControlClasses}
      aria-describedby={id + '-text'}
    >
      {labelText !== undefined ? (
        <InputLabel className={classes.selectLabel + labelClasses} htmlFor={id} {...labelProps}>
          {labelText}
        </InputLabel>
      ) : null}
      <Select
        MenuProps={{
          className: classes.selectMenu,
        }}
        classes={{
          select: classes.select,
        }}
        id={id}
        value={value}
        onChange={onChange}
        {...inputProps}
      >
        {dropdownList.map((prop, key) => {
          if (prop.divider) {
            return <Divider key={key} className={classes.dropdownDividerItem} />;
          }

          if (prop.navButton) {
            return (
              <MenuItem
                key={key}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
              >
                <NavLink to={prop.to}>{prop.text}</NavLink>
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={key}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={prop.value}
            >
              {prop.name}
            </MenuItem>
          );
        })}
      </Select>
      {helpText !== undefined ? (
        <FormHelperText id={id + '-text'} className={helpTextClasses}>
          {helpText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool,
  helpText: PropTypes.string,
  rtlActive: PropTypes.bool,
};

export default withStyles(styles)(CustomInput);
