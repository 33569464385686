import React, { Component } from 'react';

import { useMutation } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { compose } from 'recompose';

import { CONFIRM_VERIFICATION } from './graphql.jsx';

class Confirm extends Component {
  render() {
    const { classes, token, confirmVerification } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={6} md={6}>
            <Card login className={classes.cardSignup}>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  color='warning'
                  size='lg'
                  round
                  block
                  onClick={() => confirmVerification({ variables: { token: token[1] } })}
                >
                  Confirm
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const ConfirmView = (props) => {
  const [confirmVerification] = useMutation(CONFIRM_VERIFICATION, {
    onCompleted: () => {
      NotificationManager.success('', props.t('common.form.notification.emailVerified'));
      props.history.push('/');
    },
    onError: () => {
      NotificationManager.error('', props.t('common.form.notification.invalidLink'), 5000);
      props.history.push('/profile');
    },
  });

  const re = /^\?token=(.*)$/gi;
  const str = props.location.search;
  let findToken = re.exec(str);

  if (!findToken) {
    props.history.push('/');
  }
  return <Confirm {...props} token={findToken} confirmVerification={confirmVerification} />;
};

export default compose(withTranslation(), withStyles(loginPageStyle), withApollo)(ConfirmView);
