import React, { Component } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import Button from 'components/CustomButtons/Button.jsx';
import CountryDropdown from 'components/FormsyDropdown/CountryDropdown.jsx';
import CustomInput from 'components/FormsyInput/FormsyInput.jsx';
import FormsyPictureUpload from 'components/FormsyUpload/FormsyPictureUpload.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Formsy from 'formsy-react';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { flowRight as compose } from 'lodash';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';

import { UPDATE_ARTIST, GET_EDIT_ARTIST_VIEW } from './graphql.jsx';

class Artist extends Component {
  render() {
    const {
      data: { artistByGid },
      t,
      i18n,
    } = this.props;

    const { gid, artwork, name, bio, country } = artistByGid;

    const { languages } = i18n;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={10}>
            <Formsy
              onValidSubmit={(d) => {
                const payload = {
                  ...d,
                  gid,
                  artwork: d.artwork ? d.artwork.file : null,
                  country: d.country ? d.country.code : null,
                };
                this.props.updateArtist({ variables: payload });
              }}
              ref='EditArtistform'
            >
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <FormsyPictureUpload id='artwork' value={{ imagePreviewUrl: artwork }} />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <CustomInput
                    labelText={t('common.form.name.text')}
                    id='name'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={name}
                    required
                  />
                  <CountryDropdown
                    id='country'
                    labelText={t('common.form.country.text')}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    value={
                      country
                        ? {
                            code: country.code,
                            label: i18nIsoCountries.getName(country.code, languages[0]),
                          }
                        : { code: '', label: '' }
                    }
                    required
                  />
                  <CustomInput
                    labelText={t('common.form.bio.text')}
                    id='bio'
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 5,
                    }}
                    value={bio}
                    required
                  />
                  <Button color='warning' onClick={() => this.refs.EditArtistform.reset()}>
                    {t('common.form.reset.text')}
                  </Button>
                  <Button type='submit' color='warning'>
                    {t('common.form.update.text')}
                  </Button>
                </GridItem>
              </GridContainer>
            </Formsy>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const ArtistView = (props) => {
  const { loading, data } = useQuery(GET_EDIT_ARTIST_VIEW, {
    variables: { gid: props.match.params.gid },
  });
  const [updateArtist] = useMutation(UPDATE_ARTIST, {
    refetchQueries: ['MyArtists', 'UploadView'],
    onCompleted: () => {
      NotificationManager.success('', props.t('common.form.notification.updateComplete'));
      props.history.goBack();
    },
  });

  if (loading) return null;
  return <Artist {...props} data={data} updateArtist={updateArtist} />;
};

export default compose(withTranslation())(ArtistView);
