import { makeStyles } from '@material-ui/core/styles';
import { whiteColor } from 'assets/jss/material-dashboard-pro-react.jsx';

const SalesChartStyles = makeStyles({
  chart: {
    borderRadius: '15px ',
    background: whiteColor,
  },
  series: {
    background: 'red',
  },
});

export default SalesChartStyles;
