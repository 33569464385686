const GENRE_LIST = [
  {
    name: 'Afrobeat',
    value: 'Afrobeat',
  },
  {
    name: 'Hip Hop',
    value: 'Hip Hop',
  },
  {
    name: 'Rap',
    value: 'Rap',
  },
  {
    name: 'Gospel',
    value: 'Gospel',
  },
  {
    name: "R n' B",
    value: "R n' B",
  },
  {
    name: 'Pop',
    value: 'Pop',
  },
  {
    name: 'Choral',
    value: 'Choral',
  },
  {
    name: 'Classical',
    value: 'Classical',
  },
  {
    name: 'Jazz',
    value: 'Jazz',
  },
  {
    name: 'Rock',
    value: 'Rock',
  },
  {
    name: 'Country',
    value: 'Country',
  },
  {
    name: 'Dancehall',
    value: 'Dancehall',
  },
  {
    name: 'Reggae',
    value: 'Reggae',
  },
  {
    name: 'Electronic',
    value: 'Electronic',
  },
  {
    name: 'House',
    value: 'House',
  },
  {
    name: 'Grime',
    value: 'Grime',
  },
  {
    name: 'Kwaito',
    value: 'Kwaito',
  },
  {
    name: 'Soul',
    value: 'Soul',
  },
  {
    name: 'Instrumental',
    value: 'Instrumental',
  },
  {
    name: 'Bikutsi',
    value: 'Bikutsi',
  },
  {
    name: 'Makossa',
    value: 'Makossa',
  },
  {
    name: 'Coupe Decale',
    value: 'Coupe Decale',
  },
];

export { GENRE_LIST };
