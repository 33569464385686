import React, { Component, createContext } from 'react';
import { compose } from 'recompose';
import { uniq, filter } from 'lodash';

import * as Sentry from '@sentry/browser';
import { useQuery } from '@apollo/client';
import { getFirestore, collection, getDoc, doc, setDoc } from 'firebase/firestore';
import { getMessaging, getToken, deleteToken, onMessage } from 'firebase/messaging';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import firebaseApp from '../firebase';
import { EnhanceNotifier } from '../util/Notification/Notification.jsx';

import { GET_USER_DATA } from './graphql.jsx';

const { Provider, Consumer: FirebaseConsumer } = createContext({
  stopFirebase: () => {},
});

export default class FirebaseContext extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  messaging = getMessaging(firebaseApp);
  firestore = getFirestore(firebaseApp);
  auth = getAuth(firebaseApp);

  async startFirebase() {
    try {
      const isLoggedIn = this.props.data.isLoggedIn;
      if (isLoggedIn) {
        const { gid, email, firebaseToken } = this.props.data.loggedInUser;
        await signInWithCustomToken(this.auth, firebaseToken);
        const token = await getToken(this.messaging);
        await this.saveTokenToFirestore(gid, token);
        onMessage(this.messaging, () => {
          this.props.newNotificationItem();
        });
        Sentry.setUser({ email, gid });
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  async stopFirebase(gid) {
    try {
      if (gid) {
        const token = await getToken(this.messaging);
        await deleteToken(this.messaging);
        await this.removeTokenFromFirestore(gid, token);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  async getUserDoc(gid) {
    try {
      const docRef = doc(this.firestore, process.env.REACT_APP_USER_TOKEN, gid);
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  async setUserDoc(gid, tokens) {
    try {
      const colRef = collection(this.firestore, process.env.REACT_APP_USER_TOKEN);
      await setDoc(doc(colRef, gid), { tokens });
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  async removeTokenFromFirestore(gid, token) {
    const userTokens = await this.getUserDoc(gid);
    const updatedTokens = filter(userTokens.tokens, (el) => el !== token);
    if (updatedTokens) {
      await this.setUserDoc(gid, updatedTokens);
    }
  }

  async saveTokenToFirestore(gid, token) {
    try {
      let tokens;
      const userTokens = await this.getUserDoc(gid);
      if (userTokens) {
        tokens = uniq([...userTokens.tokens, token]);
      } else {
        tokens = [token];
      }
      await this.setUserDoc(gid, tokens);
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  componentDidMount() {
    this.startFirebase();
  }

  componentDidUpdate() {
    this.startFirebase();
  }

  render() {
    const stopFirebase = this.stopFirebase.bind(this);
    return <Provider value={{ stopFirebase }}>{this.props.children}</Provider>;
  }
}

const EnhancedFirebaseProvider = compose(EnhanceNotifier)(FirebaseContext);

const FirebaseProviderView = (props) => {
  const { loading, data } = useQuery(GET_USER_DATA);
  if (loading) return null;
  return <EnhancedFirebaseProvider {...props} data={data} />;
};

const withFirebase = (Component) => (props) => {
  const Firebase = (
    <FirebaseConsumer>
      {(data) => <Component {...props} stopFirebase={data.stopFirebase} />}
    </FirebaseConsumer>
  );
  return Firebase;
};

export { FirebaseProviderView, FirebaseConsumer, withFirebase };
