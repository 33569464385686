import { gql } from '@apollo/client';

const GET_COUNTRY_RELEASES = gql`
  query GetCountryReleases($id: ID!) {
    countryReleases(id: $id) {
      id
      gid
      name
      year
      genre
      playCount
      country {
        id
        code
      }
      priceCategory {
        id
        tier
        value
      }
      artists {
        id
        gid
        name
      }
      media {
        id
        artwork(height: 300, width: 300)
        trackCount
      }
    }
  }
`;

export { GET_COUNTRY_RELEASES };
