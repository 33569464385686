import { withApollo } from '@apollo/client/react/hoc';
import * as Sentry from '@sentry/browser';
import { compose, withProps } from 'recompose';
import Store from 'store';
import { getAuth, signOut } from 'firebase/auth';

import { withFirebase } from '../../firebase/provider';
import { LOGOUT_USER } from './graphql.jsx';

const Logout = async (client, cent) => {
  await client.mutate({
    mutation: LOGOUT_USER,
    awaitRefetchQueries: true,
    refetchQueries: [],
  });
  Store.remove('userToken');
  await client.resetStore();
  const auth = getAuth();
  await signOut(auth);
  Sentry.configureScope((scope) => scope.clear());
  cent.disconnect();
};

const LogoutControls = compose(
  withApollo,
  withFirebase,
  withProps((props) => ({
    logout: () => Logout(props.client, props.cent),
  })),
);

export { LogoutControls };
