import React from 'react';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import NavPills from 'components/NavPills/NavPills.jsx';
import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import chartStyle from 'assets/jss/material-dashboard-pro-react/views/chartStyle';
import { compose } from 'recompose';

import WeeklyCharts from './WeeklyChart';
import MonthlyChart from './MonthlyChart';

const TopCharts = (props) => {
  const { classes } = props;
  const [t] = useTranslation();
  return (
    <div>
      <div className={classes.chartTitle}>
        <h1> {t('charts.title')}</h1>
      </div>
      <GridContainer>
        <GridItem xs={12}>
          <NavPills
            color='warning'
            alignCenter
            tabs={[
              {
                tabButton: t('charts.weekly'),
                tabContent: <WeeklyCharts />,
              },
              {
                tabButton: t('charts.monthly'),
                tabContent: <MonthlyChart />,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default compose(withStyles(chartStyle))(TopCharts);
