import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

class UpdateProfile extends Component {
  render() {
    return (
      <Card>
        <CardBody>
          <List>
            <ListItem>- Click on My Profile under menu</ListItem>
            <ListItem>
              - Update your profile by adding your name, choosing a username, adding a profile
              picture and selecting your country of origin
            </ListItem>
            <ListItem>- Click Update Profile</ListItem>
          </List>
        </CardBody>
      </Card>
    );
  }
}

export default UpdateProfile;
