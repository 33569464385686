import React from 'react';

import MetaTags from 'react-meta-tags';

export default (props) => {
  const { title, description, url, image } = props;
  const Meta = (
    <MetaTags>
      <title>{title}</title>
      <meta property='description' content={description} />
      {/* OpenGraph tags */}
      <meta property='og:url' content={url} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:site_name' content='ekoh.online' />
      <meta property='og:image' content={image} />
      <meta property='og:image:width' content='600' />
      <meta property='og:image:height' content='314' />

      <meta property='og:type' content='website' />
      <meta property='fb:app_id' content='1735933046581754' />
      {/* Twitter Card tags */}
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='twitter:image' content={image} />
      <meta property='twitter:card' content='summary' />
    </MetaTags>
  );
  return Meta;
};
