import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  headerTitle: {
    fontWeight: 'bold',
    color: theme.palette.grey[900],
  },
  weekItem: {
    marginLeft: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
