import React from 'react';

import { useQuery } from '@apollo/client';
import GridContainer from 'components/Grid/GridContainer.jsx';
import FreeRelease from 'components/Release/FreeRelease.jsx';
import PaidRelease from 'components/Release/PaidRelease.jsx';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { GET_PURCHASED_RELEASES } from './graphql.jsx';

class Purchased extends React.Component {
  render() {
    const {
      data: { purchasedReleases },
    } = this.props;

    return (
      <GridContainer>
        {purchasedReleases.map((rel) => {
          let Comp = rel.priceCategory.price === 0 ? FreeRelease : PaidRelease;
          return <Comp key={rel.id} rel={rel} />;
        })}
      </GridContainer>
    );
  }
}

const PurchasedResults = compose(withTranslation())(Purchased);

const PurchasedView = (props) => {
  const { loading, data } = useQuery(GET_PURCHASED_RELEASES);
  if (loading) return null;
  return <PurchasedResults data={data} {...props} />;
};

export default PurchasedView;
