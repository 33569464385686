import { gql } from '@apollo/client';

const GET_ARTIST_VIEW = gql`
  query GetArtist($gid: ID!) {
    loggedInUser {
      id
      email
      userName
      verified
    }
    isLoggedIn
    artistMetaImage: artistByGid(gid: $gid) {
      id
      artwork(height: 314, width: 600)
    }
    artistByGid(gid: $gid) {
      id
      gid
      name
      bio
      follows
      followerCount
      verified
      artwork(height: 300, width: 300)
      artistType {
        id
        ident
      }
      gender {
        id
        ident
      }
      country {
        id
        code
      }
    }

    artistReleases(gid: $gid) {
      id
      gid
      name
      playCount
      media {
        id
        gid
        trackCount
        artwork(height: 300, width: 300)
      }
      artists {
        id
        gid
        name
      }
      country {
        id
        code
      }
      priceCategory {
        id
        tier
        value
      }
    }
  }
`;

export { GET_ARTIST_VIEW };
