import React from 'react';
import { ArrowDropDown, ArrowDropUp, Remove } from '@material-ui/icons';
import bronze_medal from '../../../assets/img/bronze-medal.svg';
import gold_medal from '../../../assets/img/gold-medal.svg';
import silver_medal from '../../../assets/img/silver-medal.svg';
import { useStyles } from '../../../assets/jss/material-dashboard-pro-react/views/chartsTableStyle';

const WeekPosition = (props) => {
  const { rank, lastWeekRank } = props;
  const classes = useStyles();

  const medal = {
    1: gold_medal,
    2: silver_medal,
    3: bronze_medal,
  };

  let positionStatus;
  if (rank > lastWeekRank) {
    positionStatus = <ArrowDropDown className={classes.red} />;
  }
  if (!lastWeekRank || rank < lastWeekRank) {
    positionStatus = <ArrowDropUp className={classes.green} />;
  }
  if (rank === lastWeekRank) {
    positionStatus = <Remove className={classes.grey} />;
  }

  return (
    <span>
      {positionStatus}
      {rank <= 3 ? <img src={medal[rank]} alt='Medal' className={classes.posIcon} /> : rank}
    </span>
  );
};

export default WeekPosition;
