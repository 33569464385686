import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useQuery } from '@apollo/client';

import { NavLink } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
// import Grow from "@material-ui/core/Grow";

// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';
// import Search from "@material-ui/icons/Search";
// import Popper from "@material-ui/core/Popper";
// import Paper from "@material-ui/core/Paper";
// import GridItem from 'components/Grid/GridItem.jsx'

// core components
import Button from 'components/CustomButtons/Button.jsx';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';

import adminNavbarStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.jsx';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import SearchBarView from '../SearchBar';
import { GET_USER_PROFILE_STATUS } from './graphql.jsx';
import AdminNavbarLinks from './AdminNavbarLinks';

class AdminNavbar extends React.Component {
  state = {
    searchBarOpen: false,
  };

  handleSearchClick = () => {
    this.setState({ searchBarOpen: true });
  };
  handleSearchClose = () => {
    this.setState({ searchBarOpen: false });
  };

  render() {
    const { props } = this;

    const { classes, color, rtlActive, brandText, data, t } = props;

    if (!data) {
      return null;
    }

    const { isProfileComplete } = data;

    const appBarClasses = cx({
      [' ' + classes[color]]: color,
    });
    const sidebarMinimize =
      classes.sidebarMinimize +
      ' ' +
      cx({
        [classes.sidebarMinimizeRTL]: rtlActive,
      });

    return (
      <AppBar className={classes.appBar + appBarClasses}>
        <br />
        <br />
        <div className={cx(classes.app__header, classes.margin)}>
          <Toolbar
            className={classes.container}
            style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 1fr' }}
          >
            <div>
              <Hidden smDown implementation='css'>
                <div className={sidebarMinimize}>
                  {props.miniActive ? (
                    <Button justIcon round color='white' onClick={props.sidebarMinimize}>
                      <ViewList className={classes.sidebarMiniIcon} />
                    </Button>
                  ) : (
                    <Button justIcon round color='white' onClick={props.sidebarMinimize}>
                      <MoreVert className={classes.sidebarMiniIcon} />
                    </Button>
                  )}
                </div>
              </Hidden>
              <div className={classes.flex}>
                <Button href='#' className={classes.title} color='transparent'>
                  {brandText}
                </Button>
              </div>
            </div>
            <SearchBarView />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: '2em',
                alignItems: 'center',
              }}
            >
              <div className={classes.profileContainer}>
                <Hidden smDown implementation='css'>
                  {!isProfileComplete && (
                    <SnackbarContent
                      color='info'
                      classes={{
                        root: classes.banner,
                        message: classes.message,
                      }}
                      message={<NavLink to='/gallery/profile'>{t('profile.update')}</NavLink>}
                    />
                  )}
                </Hidden>
              </div>
              <Hidden smDown implementation='css'>
                <AdminNavbarLinks rtlActive={rtlActive} />
              </Hidden>
              <Hidden mdUp implementation='css'>
                <Button
                  className={classes.appResponsive}
                  color='transparent'
                  justIcon
                  aria-label='open drawer'
                  onClick={props.handleDrawerToggle}
                >
                  <Menu />
                </Button>
              </Hidden>
            </div>
          </Toolbar>
        </div>
      </AppBar>
    );
  }
}

AdminNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
};

const AdminNavbarView = (props) => {
  const { loading, data } = useQuery(GET_USER_PROFILE_STATUS);
  if (loading) return null;
  return <AdminNavbar {...props} data={data} />;
};

export default compose(withTranslation(), withStyles(adminNavbarStyle))(AdminNavbarView);
