import React from 'react';

// @material-ui/core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import withStyles from '@material-ui/core/styles/withStyles';
import footerStyle from 'assets/jss/material-dashboard-pro-react/components/footerStyle';
import cx from 'classnames';
import Player from 'components/Footer/Player.jsx';
import { flowRight as compose } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

function Footer({ ...props }) {
  const { classes, fluid, white, t } = props;
  const homeUrl = process.env.REACT_APP_SITE_URL;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  var anchor =
    classes.a +
    cx({
      [' ' + classes.whiteColor]: white,
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white,
  });
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href={homeUrl} className={block}>
                {t('home.text')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href='/help/contact-us' className={block}>
                {t('contactus.text')}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href='/help' className={block}>
                {t('helpPages.text')}
              </a>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          &copy; {1900 + new Date().getYear()}{' '}
          <a href={homeUrl} className={anchor}>
            Ekoh Media
          </a>
        </p>
      </div>
      <div id='player-container'>
        <Player miniActive={props.miniActive} />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};

export default compose(withTranslation(), withStyles(footerStyle))(Footer);
