import { withApollo } from '@apollo/client/react/hoc';
import {
  addReleaseToNewPlaylistMutation,
  appendReleaseToPlaylistMutation,
  addTrackToNewPlaylistMutation,
  appendTrackToPlaylistMutation,
} from 'apollo/cache.jsx';
import _ from 'lodash';
import { compose, withProps } from 'recompose';
import { v4 as uuidv4 } from 'uuid';

import { GET_RELEASE_BY_GID, TRACK_STREAMED } from './graphql.jsx';

const prepareReleaseData = async (client, rel) => {
  const { data } = await client.query({
    query: GET_RELEASE_BY_GID,
    variables: { gid: rel.gid },
  });

  return _.flatMap(data.releaseByGid.media, (m) => {
    return _.map(m.tracks, (track) => ({
      id: uuidv4(),
      gid: track.gid,
      name: track.name,
      singer: track.artists,
      cover: track.artwork,
      releaseGid: rel.gid,
    }));
  });
};

const prepareTrackData = (track, releaseGid) => {
  return {
    id: uuidv4(),
    gid: track.gid,
    name: track.name,
    singer: track.artists,
    cover: track.artwork,
    releaseGid,
  };
};

const addReleaseToNewPlaylist = async (client, rel) => {
  let playlistItems = await prepareReleaseData(client, rel);
  addReleaseToNewPlaylistMutation(playlistItems);
};

const appendReleaseToPlaylist = async (client, rel) => {
  let playlistItems = await prepareReleaseData(client, rel);
  appendReleaseToPlaylistMutation(playlistItems);
};

const addTrackToNewPlaylist = async (client, track, releaseGid) => {
  const playlistItem = prepareTrackData(track, releaseGid);
  addTrackToNewPlaylistMutation(playlistItem);
};
const appendTrackToPlaylist = async (client, track, releaseGid) => {
  const playlistItem = prepareTrackData(track, releaseGid);
  appendTrackToPlaylistMutation(playlistItem);
};

const trackStreamed = async (client, email, gid, releaseGid, playDuration) => {
  await client.mutate({
    mutation: TRACK_STREAMED,
    variables: { email, gid, releaseGid, playDuration },
  });
};

const EnhanceWithPlayerControls = compose(
  withApollo,
  withProps((props) => ({
    addReleaseToNewPlaylist: (rel) => addReleaseToNewPlaylist(props.client, rel),
    appendReleaseToPlaylist: (rel) => appendReleaseToPlaylist(props.client, rel),
    addTrackToNewPlaylist: (track, releaseGid) =>
      addTrackToNewPlaylist(props.client, track, releaseGid),
    appendTrackToPlaylist: (track, releaseGid) =>
      appendTrackToPlaylist(props.cleint, track, releaseGid),
  })),
);

const EnhancePlayer = compose(
  withApollo,
  withProps((props) => ({
    trackStreamed: (email, trackGid, releaseGid, playDuration) =>
      trackStreamed(props.client, email, trackGid, releaseGid, playDuration),
  })),
);

export { EnhanceWithPlayerControls, EnhancePlayer };
