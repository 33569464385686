import { makeStyles } from '@material-ui/core/styles';

export const overidesStyles = makeStyles((theme) => ({
  containerIcon: {
    visibility: 'hidden',
  },
  hoverShow: {
    '& $containerIcon': {
      visibility: 'visible !important',
    },
  },
  searchClass: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute !important',
      top: '1em !important',
      left: '2.5% !important',
      width: '95% !important',
      zIndex: '66 !important',
      background: 'white !important',
      boxShadow: '0px 0px 7px #ff9800ad !important',
    },
  },
  // expansionPanelSummaryExpaned: {
  //   color: primaryColor[0],
  //   '& $expansionPanelSummaryExpandIcon': {
  //     [theme.breakpoints.up('md')]: {
  //       top: 'auto !important',
  //     },
  //     transform: 'rotate(180deg)',
  //     [theme.breakpoints.down('sm')]: {
  //       top: '10px !important',
  //     },
  //   },
  // },
  // expansionPanelSummaryContent: {
  //   margin: '0 !important',
  // },
  // expansionPanelSummaryExpandIcon: {
  //   [theme.breakpoints.up('md')]: {
  //     top: 'auto !important',
  //   },
  //   transform: 'rotate(0deg)',
  //   color: 'inherit',
  //   [theme.breakpoints.down('sm')]: {
  //     top: '10px !important',
  //   },
  // },
  // expansionPanelSummaryExpandIconExpanded: {},
  // title: {
  //   fontSize: '15px',
  //   fontWeight: 'bolder',
  //   marginTop: '0',
  //   marginBottom: '0',
  //   color: 'inherit',
  // },
  // expansionPanelDetails: {
  //   padding: '15px 0px 5px',
  // },
}));
