import { gql } from '@apollo/client';

const GET_CHART_PERIODS = gql`
  query GetPeriods($frequency: ChartPeriodFrequency!) {
    getChartPeriods(frequency: $frequency) {
      name
      value
    }
  }
`;

const MOST_PLAYED_ARTISTS = gql`
  query MostPlayedArtist($frequency: ChartPeriodFrequency!, $timePeriod: String, $limit: Int) {
    mostPlayedArtists(frequency: $frequency, limit: $limit, timePeriod: $timePeriod) {
      edges {
        rank
        highestChartRank
        previousPeriodRank
        consecutivePeriodsOnChart
        playCount
        node {
          artwork
          name
          gid
        }
      }
    }
  }
`;
const MOST_PLAYED_TRACKS = gql`
  query MostPlayedTracks($frequency: ChartPeriodFrequency!, $timePeriod: String, $limit: Int) {
    mostPlayedTracks(frequency: $frequency, limit: $limit, timePeriod: $timePeriod) {
      edges {
        rank
        highestChartRank
        previousPeriodRank
        consecutivePeriodsOnChart
        playCount
        node {
          gid
          artists
          artwork
          name
        }
      }
    }
  }
`;
const MOST_PLAYED_RELEASES = gql`
  query MostPlayedReleases($frequency: ChartPeriodFrequency!, $timePeriod: String, $limit: Int) {
    mostPlayedReleases(frequency: $frequency, limit: $limit, timePeriod: $timePeriod) {
      edges {
        rank
        highestChartRank
        previousPeriodRank
        consecutivePeriodsOnChart
        playCount
        node {
          artwork
          name
          gid
          artists {
            id
            name
          }
          media {
            id
            artwork(height: 300, width: 300)
            trackCount
            tracks {
              id
              gid
              name
              artwork(height: 300, width: 300)
              artists
              duration
              position
            }
          }
        }
      }
    }
  }
`;

export { GET_CHART_PERIODS, MOST_PLAYED_ARTISTS, MOST_PLAYED_RELEASES, MOST_PLAYED_TRACKS };
