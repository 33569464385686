import { gql } from '@apollo/client';

const GET_TRACK_PUBLIC_URL = gql`
  query TrackURL($gid: ID!) {
    trackPublicUrl(gid: $gid) {
      id
      path
      creds
    }
  }
`;

const GET_PLAYLIST_DATA = gql`
  {
    playlist @client {
      id
      gid
      name
      singer
      cover
      releaseGid
    }
    clearPriorAudioLists @client
    isLoggedIn
    loggedInUser {
      id
      email
    }
  }
`;

export { GET_TRACK_PUBLIC_URL, GET_PLAYLIST_DATA };
