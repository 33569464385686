import React, { useState } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import i18next from 'i18next';

import styles from '../assets/jss/material-dashboard-pro-react/components/langStyle';

function languageSwitch({ ...props }) {
  const { classes } = props;
  const [lang, setLang] = useState(i18next.language.split('-')[0]);
  const changeLanguageValue = (_event, val) => {
    i18next.changeLanguage(val);
    setLang(val);
  };

  const btnStyle = {
    root: classes.root,
    selected: classes.selected,
  };

  return (
    <ToggleButtonGroup
      value={lang}
      onChange={changeLanguageValue}
      exclusive={true}
      size={'small'}
      orientation={'horizontal'}
    >
      <ToggleButton value='fr' classes={btnStyle}>
        Fr
      </ToggleButton>
      <ToggleButton value='en' classes={btnStyle}>
        En
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export default withStyles(styles)(languageSwitch);
