import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  winnerStatsText: {
    fontWeight: 'bold',
    color: theme.palette.grey[900],
    textTransform: 'uppercase',
  },
  top: {
    borderTopWidth: 2,
    borderTopColor: theme.palette.grey[900],
    borderBottomColor: theme.palette.grey[900],
    borderBottomWidth: 2,
    borderTopStyle: 'solid',
    borderBottomStyle: 'solid',
    padding: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  topItem2: {
    marginBottom: theme.spacing(1),
  },
  topItem1: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(1),
  },
  image: {
    width: '100%',
  },
  winnerStats: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    borderTopColor: theme.palette.grey[600],
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    marginTop: theme.spacing(2),
  },
  largeAvatar: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  cupIcon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
  topItemSm: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  topItemSmText: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
}));
