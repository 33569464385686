import React, { Component } from 'react';

export const mainPanelContext = React.createContext();

export default class Context extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainPanel: this.props.mainPanel,
    };
  }

  render() {
    return (
      <mainPanelContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </mainPanelContext.Provider>
    );
  }
}
