import React, { Component } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

class UploadMusic extends Component {
  render() {
    return (
      <Card>
        <CardBody>
          <p>
            NB. You can only upload music after creating an artist profile. Each artist gets an
            initial 120MB of storage space allocated, subject to review based on artist activity
          </p>
          <List>
            <ListItem>- Click on Upload under Menu</ListItem>
            <ListItem>
              - Click to browse or drag the file onto the upload page (you can upload more than one
              track at a time by clicking on Add more files)
            </ListItem>
            <ListItem>
              - Add a high quality cover art/display picture for your upload (this will be the image
              shown in the gallery)
            </ListItem>
            <ListItem>
              - Enter the Album name, Release date, Track number, Track Title, and Artists name
            </ListItem>
            <ListItem>- Select the Genre, Price and the Album Artist</ListItem>
            <ListItem>
              - If your upload is being sold, users can sample 20 seconds of your track so enter the
              time when you would want these 20 seconds to start e.g. 1 min 32 seconds into the
              track. The track will play the first 20 seconds of the track if this field is left
              empty
            </ListItem>
            <ListItem>- Double check your upload information and click Upload</ListItem>
          </List>
        </CardBody>
      </Card>
    );
  }
}

export default UploadMusic;
