import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import cx from 'classnames';
import '../views/Download/style.css';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { withTranslation } from 'react-i18next';

import { compose } from 'recompose';

// core components
import DownloadNavbar from 'components/Navbars/DownloadNavbar.jsx';

import pagesStyle from 'assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx';
import Download from '../views/Download';

class Pages extends React.Component {
  componentDidMount() {
    document.body.style.overflow = 'unset';
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/download') {
        return <Route path={prop.layout + prop.path} component={Download} key={key} />;
      } else {
        return null;
      }
    });
  };

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <DownloadNavbar {...rest} />
        <div className={classes.wrapper} ref='wrapper'>
          <div className={cx('page', classes.page)}>
            <Download />
          </div>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(pagesStyle))(Pages);
