import * as i18nIsoCountries from 'i18n-iso-countries';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as moment from 'moment';
import { initReactI18next } from 'react-i18next';

import { en, fr } from './locales';

const i18n = i18next.createInstance();

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      react: {
        wait: true,
      },

      debug: false,

      load: 'languageOnly',

      resources: {
        en: {
          translation: en,
        },
        fr: {
          translation: fr,
        },
      },

      keySeparator: '.',

      fallbackLng: {
        'en-US': ['en'],
        default: ['en'],
      },

      interpolation: {
        escapeValue: false,
      },
    },
    () => {
      i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
      i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/fr.json'));

      moment.locale(i18next.languages[0]);
    },
  );

export default i18n;
