import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import Share from '@material-ui/icons/Share';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import Button from 'components/CustomButtons/Button.jsx';
import { withTranslation } from 'react-i18next';
import { FacebookShareButton, TwitterShareButton, TwitterIcon, FacebookIcon } from 'react-share';
import { compose } from 'recompose';
import urljoin from 'url-join';
import { objectListToString } from 'util.js';

function ShareComponents(props) {
  const { rel, classes } = props;

  const siteUrl = process.env.REACT_APP_SITE_URL;

  const title = `${objectListToString(rel.artists)} - ${rel.name}`;

  const shareUrl = urljoin(siteUrl, 'gallery/release', rel.gid);

  return (
    <div className={classes.shareHoverUnder}>
      <Tooltip
        id='tooltip-top'
        title='Facebook'
        placement='bottom'
        arrow
        classes={{ tooltip: classes.tooltip }}
      >
        <FacebookShareButton
          url={shareUrl}
          className={classes.underChartIcons}
          hashtag='#EkohOnline'
          quote={title}
        >
          <FacebookIcon size={24} round={true} />
        </FacebookShareButton>
      </Tooltip>
      <Tooltip
        id='tooltip-top'
        title='Twitter'
        placement='bottom'
        arrow
        classes={{ tooltip: classes.tooltip }}
      >
        <TwitterShareButton
          url={shareUrl}
          className={classes.underChartIcons}
          hashtags={['Ekoh', 'EkohOnline']}
        >
          <TwitterIcon size={24} round={true} />
        </TwitterShareButton>
      </Tooltip>
    </div>
  );
}

function ShareView(props) {
  const { rel, classes, t } = props;

  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);

  return (
    <Tooltip
      id='tooltip-top'
      open={tooltipIsOpen}
      onOpen={() => setTooltipIsOpen(true)}
      onClose={() => setTooltipIsOpen(false)}
      title={<ShareComponents rel={rel} classes={classes} t={t} />}
      placement='bottom'
      interactive
      classes={{ tooltip: classes.shareTooltip }}
    >
      <Button color='warning' justIcon onClick={() => setTooltipIsOpen(!tooltipIsOpen)}>
        <Share className={classes.underChartIcons} />
      </Button>
    </Tooltip>
  );
}

export default compose(withTranslation(), withStyles(dashboardStyle))(ShareView);
