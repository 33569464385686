import { LogoutControls } from 'util/Auth/Logout.jsx';

import React, { Component } from 'react';

import { useMutation } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/FormsyInput/FormsyInput.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Formsy from 'formsy-react';
import { withTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { compose } from 'recompose';

import { RESET_PASSWORD } from './graphql.jsx';

class ResetPassword extends Component {
  render() {
    const { classes, token, resetPassword, t } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify='center'>
          <GridItem xs={12} sm={6} md={6}>
            <Card login className={classes.cardSignup}>
              <CardBody>
                <Formsy
                  className={classes.form}
                  onValidSubmit={(d) => {
                    const payload = { ...d, token };
                    resetPassword({ variables: payload });
                  }}
                  ref='resetPasswordForm'
                >
                  <CustomInput
                    id='password'
                    labelText={t('common.form.password.text')}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position='start' className={classes.inputAdornment}>
                          <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                        </InputAdornment>
                      ),
                      type: 'password',
                      placeholder: `${t('common.form.password.text')} ...`,
                    }}
                    validations='minLength:8'
                    validationError={t('common.form.password.validation')}
                    required
                  />
                  <CustomInput
                    id='confirmPassword'
                    labelText={t('common.form.confirmPassword.text')}
                    formControlProps={{
                      fullWidth: true,
                      className: classes.customFormControlClasses,
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position='start' className={classes.inputAdornment}>
                          <Icon className={classes.inputAdornmentIcon}>lock_outline</Icon>
                        </InputAdornment>
                      ),
                      type: 'password',
                      placeholder: 'Confirm Password...',
                    }}
                    validations='equalsField:password'
                    validationError={t('common.form.confirmPassword.validation')}
                    required
                  />
                  <Button type='submit' color='warning' round block>
                    {t('common.form.confirm.text')}
                  </Button>
                </Formsy>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const ResetPasswordView = (props) => {
  const [resetPassword] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      NotificationManager.success('', props.t('common.form.notification.passwordReset'));
      props.logout();
      props.history.push('/auth/login');
    },
  });

  const re = /^\?token=(.*)$/gi;
  const str = props.location.search;
  let findToken = re.exec(str);

  if (!findToken) {
    props.history.push('/');
  }
  return <ResetPassword {...props} token={findToken[1]} resetPassword={resetPassword} />;
};

export default compose(
  withTranslation(),
  withStyles(loginPageStyle),
  withApollo,
  LogoutControls,
)(ResetPasswordView);
