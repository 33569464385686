import BuyMusic from 'views/Help/BuyMusic';
import CollectFunds from 'views/Help/CollectFunds';
import ContactUs from 'views/Help/ContactUs';
import HelpRegister from 'views/Help/HelpRegister';
import PlayMusic from 'views/Help/PlayMusic';
import Terms from 'views/Help/Terms';
import UpdateProfile from 'views/Help/UpdateProfile';
import UploadMusic from 'views/Help/UploadMusic';

var authRoutes = [
  {
    path: '/how-to-register',
    name: 'How To Register',
    component: HelpRegister,
    layout: '/help',
  },
  {
    path: '/update-user-profile',
    name: 'How To Update User Profile',
    component: UpdateProfile,
    layout: '/help',
  },
  {
    path: '/play-music',
    name: 'How To Play Music',
    component: PlayMusic,
    layout: '/help',
  },
  {
    path: '/upload-music',
    name: 'How To Upload Music',
    component: UploadMusic,
    layout: '/help',
  },
  {
    path: '/buy-music',
    name: 'How To Buy Music',
    component: BuyMusic,
    layout: '/help',
  },
  {
    path: '/collect-funds',
    name: 'How To Collect Funds',
    component: CollectFunds,
    layout: '/help',
  },
  {
    path: '/terms-of-conditions-and-privacy-policy',
    name: 'Terms of Conditions and Privacy Policy',
    component: Terms,
    layout: '/help',
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: ContactUs,
    layout: '/help',
  },
];

export default authRoutes;
