import { EnhanceWithPlayerControls } from 'util/Player/Player.jsx';
import { EnhanceWithCartControls } from 'util/Cart/Cart.jsx';
import React from 'react';

import _ from 'lodash';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';
import { compose } from 'recompose';

import 'moment-timezone';
import 'moment/locale/fr';
import Currency from 'react-currency-formatter';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import PlayArrow from '@material-ui/icons/PlayArrow';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Lock from '@material-ui/icons/Lock';
import AddShoppingCart from '@material-ui/icons/AddShoppingCart';
import RemoveShoppingCart from '@material-ui/icons/RemoveShoppingCart';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardAvatar from 'components/Card/CardAvatar.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import MetaTags from 'components/MetaTags/MetaTags.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Table from 'components/Table/Table.jsx';
import NotFound from 'components/Pages/404.js';
import { objectListToString } from 'util.js';
import { formatDuration } from 'react-dropzone-uploader';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';

import { withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_RELEASE_BY_GID } from './graphql.jsx';

class Release extends React.Component {
  render() {
    const {
      classes,
      data: { releaseByGid, releaseMetaImage, isLoggedIn, shoppingCart, localeCurrency },

      i18n,
      t,
    } = this.props;

    if (releaseByGid.id === null) return <NotFound />;
    const { currency } = localeCurrency;
    const trackCount = releaseByGid.media.reduce((acc, m) => acc + m.trackCount, 0);
    const rLength = _.reduce(
      _.flatMap(releaseByGid.media, (m) => m.tracks),
      (acc, t) => acc + t.duration,
      0,
    );

    const free = releaseByGid.priceCategory.tier === 'tier0';
    const showControls = free || isLoggedIn;

    const cartContainsRelease = (cart, rel) => cart.find((r) => r.gid === rel.gid);

    const title = `${objectListToString(releaseByGid.artists)} - ${releaseByGid.name}`;
    const description = `${objectListToString(releaseByGid.artists)} - ${releaseByGid.name}`;
    const image = releaseMetaImage.artwork;
    const url = `${process.env.REACT_APP_SITE_URL}${window.location.pathname}`;

    return (
      <GridContainer>
        <MetaTags title={title} description={description} url={url} image={image} />
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardHeader image className={classes.cardHeaderHover}>
              <CardAvatar>
                <img src={releaseByGid.artwork} alt='...' />
              </CardAvatar>
            </CardHeader>
            <CardBody>
              <h3>{releaseByGid.name}</h3>
              <p className={classes.cardProductDesciprion}>
                <NavLink to={`/gallery/artist/${releaseByGid.artists[0].gid}`}>
                  {objectListToString(releaseByGid.artists)}
                </NavLink>
              </p>
              <div className={classes.price}>
                <Currency
                  quantity={releaseByGid.priceCategory.value}
                  currency={currency}
                  locale={i18n.languages[0]}
                />
              </div>
              <h4>
                {t('common.track.textWithCount', { count: trackCount })} - {formatDuration(rLength)}
              </h4>
              <Moment locale={i18n.languages[0]} format='D MMM YYYY'>
                {new Date(releaseByGid.year, releaseByGid.month, releaseByGid.day)}
              </Moment>
            </CardBody>
            <CardFooter>
              {!showControls && (
                <Tooltip
                  id='tooltip-top'
                  title={t('common.controls.loginOrBuy.text')}
                  placement='bottom'
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button href='/auth/login' color='warning' justIcon>
                    <Lock className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              )}
              <Tooltip
                id='tooltip-top'
                title={t('common.controls.play.text')}
                placement='bottom'
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  onClick={() => this.props.addReleaseToNewPlaylist(releaseByGid)}
                  color='warning'
                  justIcon
                >
                  <PlayArrow className={classes.underChartIcons} />
                </Button>
              </Tooltip>
              {showControls && (
                <Tooltip
                  id='tooltip-top'
                  title={t('common.controls.addToPlaylist.text')}
                  placement='bottom'
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color='warning'
                    onClick={() => this.props.appendReleaseToPlaylist(releaseByGid)}
                    justIcon
                  >
                    <PlaylistAdd className={classes.underChartIcons} />
                  </Button>
                </Tooltip>
              )}
              {!free && isLoggedIn && (
                <Tooltip
                  id='tooltip-top'
                  title={
                    cartContainsRelease(shoppingCart, releaseByGid)
                      ? t('common.controls.removeCart.text')
                      : t('common.controls.addCart.text')
                  }
                  placement='bottom'
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    onClick={() => this.props.toggleToShoppingCart(releaseByGid)}
                    color='warning'
                    justIcon
                  >
                    {cartContainsRelease(shoppingCart, releaseByGid) ? (
                      <RemoveShoppingCart className={classes.underChartIcons} />
                    ) : (
                      <AddShoppingCart className={classes.underChartIcons} />
                    )}
                  </Button>
                </Tooltip>
              )}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardBody>
              <Table
                tableHead={[
                  '',
                  '#',
                  t('common.form.title.text'),
                  t('common.form.artist.text'),
                  t('common.form.duration.text'),
                  '',
                ]}
                tableData={[
                  ..._.flatMap(releaseByGid.media, (m) =>
                    _.map(m.tracks, (t) => {
                      return [
                        <Button
                          key={1}
                          justIcon
                          color='warning'
                          className={classes.actionButton + ' ' + classes.actionButtonRound}
                          onClick={() => this.props.addTrackToNewPlaylist(t, releaseByGid.gid)}
                        >
                          <PlayArrow className={classes.icon} />
                        </Button>,
                        t.position,
                        t.name,
                        t.artists,
                        formatDuration(t.duration),
                        [
                          <Button
                            key={1}
                            justIcon
                            simple
                            color='warning'
                            className={classes.actionButton + ' ' + classes.actionButtonRound}
                          >
                            <MoreHoriz className={classes.icon} />
                          </Button>,
                        ],
                      ];
                    }),
                  ),
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const ReleaseViewResult = compose(
  withTranslation(),
  EnhanceWithPlayerControls,
  EnhanceWithCartControls,
  withStyles(extendedTablesStyle),
)(Release);

const ReleaseView = (props) => {
  const { loading, data } = useQuery(GET_RELEASE_BY_GID, {
    variables: { gid: props.match.params.gid },
  });

  if (loading) return null;
  return <ReleaseViewResult data={data} />;
};

export default ReleaseView;
