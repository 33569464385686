import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import RaveButton from './Rave';
import Paystack from './Paystack';

const getPaymentComponent = (paymentMethod, props) => {
  const { amount, currencyCountryCode, shoppingCart, locale } = props;

  const reference = uuidv4();

  const metadata = {
    cart: JSON.stringify({
      releases: shoppingCart.map((r) => r.gid).join(','),
    }),
    amount,
    currencyCountryCode,
    locale,
  };

  const title = 'Ekoh Online';

  if (paymentMethod === 'rave') {
    return (
      <RaveButton
        ravePubKey={process.env.REACT_APP_RAVE_PUB_KEY}
        currency={props.currency}
        amount={props.amount}
        text={props.label}
        onSuccess={props.onSuccess}
        onError={props.onError}
        email={props.customer.email}
        firstName={props.customer.firstName}
        lastName={props.customer.lastName}
        currencyCountryCode={props.currencyCountryCode}
        shoppingCart={props.shoppingCart}
        metadata={metadata}
        reference={reference}
        title={title}
      />
    );
  }

  if (paymentMethod === 'paystack') {
    return (
      <Paystack
        publicKey={process.env.REACT_APP_PAYSTACK_PUB_KEY}
        currency={props.currency}
        amount={props.amount * 100}
        text={props.label}
        title={title}
        onSuccess={props.onSuccess}
        onError={props.onError}
        email={props.customer.email}
        firstName={props.customer.firstName}
        lastName={props.customer.lastName}
        currencyCountryCode={props.currencyCountryCode}
        reference={reference}
        metadata={metadata}
      />
    );
  }
};

export { getPaymentComponent };
