import { withApollo } from '@apollo/client/react/hoc';
import { compose, withProps } from 'recompose';

import { TOGGLE_FOLLOW_ARTIST } from './graphql';

const toggleFollowArtist = async (client, email, gid) => {
  await client.mutate({
    mutation: TOGGLE_FOLLOW_ARTIST,
    variables: { email, artistGid: gid },
    awaitRefetchQueries: true,
    refetchQueries: ['GalleryArtists', 'GetArtist'],
  });
};

const EnhanceWithArtistControls = compose(
  withApollo,
  withProps((props) => ({
    toggleFollowArtist: (email, gid) => toggleFollowArtist(props.client, email, gid),
  })),
);

export { EnhanceWithArtistControls };
