import React from 'react';

import { useQuery } from '@apollo/client';
import withStyles from '@material-ui/core/styles/withStyles';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import defaultImage from 'assets/img/default-avatar.png';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { withTranslation } from 'react-i18next';
import { Switch, Route, NavLink } from 'react-router-dom';
import { compose } from 'recompose';
import { objectListToString } from 'util.js';
import EditRelease from 'views/Release/MyReleases/EditRelease.jsx';
import NewRelease from 'views/Release/MyReleases/Release.jsx';

import { MY_RELEASES } from './graphql.jsx';

const MyReleases = ({ ...props }) => {
  const {
    classes,
    data: { myReleases },
    t,
  } = props;

  return (
    <div>
      <GridContainer>
        <GridContainer justifyContent='flex-end' alignItems='center'>
          <div className={classes.somePadding}>
            {' '}
            <GridItem xs={2}>
              <NavLink to='/gallery/myreleases/new'>
                <Button color='warning' variant='contained'>
                  <Add />
                  {t('release.new')}
                </Button>
              </NavLink>
            </GridItem>
          </div>
        </GridContainer>{' '}
        {myReleases.map((rel, i) => (
          <GridItem key={i} xs={12} md={2}>
            <Card product className={classes.cardHover}>
              <NavLink to={`/gallery/release/${rel.gid}`}>
                <CardHeader image>
                  <img
                    className={classes.headerImage}
                    src={rel.artwork ? rel.artwork : defaultImage}
                    alt='...'
                  />
                </CardHeader>
              </NavLink>
              <CardBody>
                <h4 className={classes.cardProductTitle}>
                  <NavLink to={`/gallery/myreleases/${rel.gid}`}>{rel.name}</NavLink>
                </h4>
                <p className={classes.cardProductDesciprion}>{objectListToString(rel.artists)}</p>
              </CardBody>
              <CardFooter product>
                <div className={`${classes.stats} ${classes.productStats}`}>
                  <Edit />
                  <NavLink to={`/gallery/myreleases/${rel.gid}`}>Edit</NavLink>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

const MyReleaseViewResults = compose(withTranslation(), withStyles(dashboardStyle))(MyReleases);

const MyReleasesView = (props) => {
  const { loading, data } = useQuery(MY_RELEASES);
  if (loading) return null;
  return (
    <Switch>
      <Route
        exact
        path='/gallery/myreleases'
        render={(p) => <MyReleaseViewResults {...props} data={data} {...p} />}
      />
      <Route
        exact
        path='/gallery/myreleases/new'
        render={(p) => <NewRelease {...props} {...p} />}
      />
      <Route path='/gallery/myreleases/:gid' render={(p) => <EditRelease {...props} {...p} />} />
    </Switch>
  );
};

export default MyReleasesView;
