import { gql } from '@apollo/client';

const TOGGLE_FOLLOW_ARTIST = gql`
  mutation ToggleFollowArtist($email: String!, $artistGid: ID!) {
    toggleFollowArtist(email: $email, artistGid: $artistGid) {
      id
      follows
    }
  }
`;

export { TOGGLE_FOLLOW_ARTIST };
