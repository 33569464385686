import { gql } from '@apollo/client';

const NOTIFICATION_READ = gql`
  mutation NotificationRead($id: ID!) {
    notificationRead(id: $id) {
      id
      title
      message
      status
    }
  }
`;

const REFETCH_QUERIES_HACK = gql`
  mutation RefetchQueriesHack {
    refetchQueriesHack
  }
`;

export { NOTIFICATION_READ, REFETCH_QUERIES_HACK };
