import {
  grayColor,
  whiteColor,
  blackColor,
  hexToRgb,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const hoverCardStyle = {
  cardHover: {
    '&:hover': {
      '& $cardHeaderHover': {
        transform: 'translate3d(0, -50px, 0)',
      },
    },
  },
  cardHeaderHover: {
    transition: 'all 300ms cubic-bezier(0.34, 1.61, 0.7, 1)',
  },
  cardHoverUnder: {
    position: 'absolute',
    zIndex: '1',
    top: '-50px',
    width: 'calc(100% - 30px)',
    left: '17px',
    right: '17px',
    textAlign: 'center',
  },
  shareHoverUnder: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  shareTooltip: {
    padding: '10px 15px',
    minWidth: '80px',
    color: whiteColor,
    lineHeight: '1.7em',
    background: 'rgba(' + hexToRgb(grayColor[6]) + ',0.9)',
    border: 'none',
    borderRadius: '3px',
    opacity: '1!important',
    boxShadow:
      '0 8px 10px 1px rgba(' +
      hexToRgb(blackColor) +
      ', 0.14), 0 3px 14px 2px rgba(' +
      hexToRgb(blackColor) +
      ', 0.12), 0 5px 5px -3px rgba(' +
      hexToRgb(blackColor) +
      ', 0.2)',
    maxWidth: '200px',
    textAlign: 'center',
    fontFamily: '"Helvetica Neue",Helvetica,Arial,sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    textShadow: 'none',
    textTransform: 'none',
    letterSpacing: 'normal',
    wordBreak: 'normal',
    wordSpacing: 'normal',
    wordWrap: 'normal',
    whiteSpace: 'normal',
    lineBreak: 'auto',
  },
};

export default hoverCardStyle;
