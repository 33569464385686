import { createTheme } from '@material-ui/core/styles';

import { primaryColor, dangerColor, defaultFont } from './material-dashboard-pro-react';

const theme = createTheme({
  palette: {
    primary: { main: primaryColor[0] },
    error: { main: dangerColor[0] },
    action: { hover: primaryColor[1] },
    typography: defaultFont,
  },
});

export default theme;
